// @flow
import React, { type Node } from 'react';
import { Flex, Stack } from '@locus-taxy/neo-ui';
import {
    SubText,
    resourceIcons,
} from 'components/dashboard/tours/TourCard/TourDetailsCard';
import { getShortDateTime } from 'utils/timeUtils';
import { TourVisit } from 'models/internal/TourVisit';
import type { ResourcesType } from 'models/flow/TourSummary';
import { WithIcon } from 'components/common/WithIcon';

type Props = {
    visit: TourVisit,
};

const getFormattedResources = (
    resources: ResourcesType
): Array<{
    name: string,
    value: number | string,
    icon: string,
    unit: string,
}> => {
    return Object.keys(resources).map((resourceKey) => {
        const {
            name = '',
            unit = '',
            value = '',
        } = resources[resourceKey] || {};
        return {
            icon: resourceIcons[resourceKey],
            name,
            value,
            unit,
        };
    });
};

const CardBody = ({ visit }: Props): Node => {
    const { chosenSlot, visitLocation, resources } = visit;
    const formattedResources = getFormattedResources(resources);

    const address =
        visitLocation?.locationAddress.getFullAddress() || 'No address found';

    return (
        <Stack space="small">
            <Flex flexWrap="wrap">
                <WithIcon icon="stopwatch">
                    <SubText>Starts:</SubText>&nbsp;
                    <SubText>{getShortDateTime(chosenSlot.start)}</SubText>
                </WithIcon>
                <WithIcon icon="stopwatch">
                    <SubText>Ends:</SubText>&nbsp;
                    <SubText>{getShortDateTime(chosenSlot.end)}</SubText>
                </WithIcon>
                <WithIcon icon="operating_hours">
                    <SubText>Duration:</SubText>&nbsp;
                    <SubText>--</SubText>
                </WithIcon>
                <WithIcon icon="motiontrack_distance">
                    <SubText>Distance:</SubText>&nbsp;
                    <SubText>--</SubText>
                </WithIcon>
                {formattedResources.map(({ name, value, unit }) => (
                    <WithIcon icon={resourceIcons[name]} key={name}>
                        <SubText>{`${name}: `}</SubText>&nbsp;
                        <SubText>{`${value} ${unit}`}</SubText>
                    </WithIcon>
                ))}
                <WithIcon icon="location_on">
                    <SubText>{address}</SubText>
                </WithIcon>
            </Flex>
        </Stack>
    );
};

export default CardBody;
