// @flow
import * as React from 'react';
import { Flex } from '@locus-taxy/neo-ui';
import LeftPanel from './LeftPanel';
import { Outlet } from 'react-router-dom';
import ResponsiveBox from 'components/common/ResponsiveBox';

const Dashboard = (): React.Node => {
    return (
        <Flex overflow="hidden" height="100%">
            <ResponsiveBox isFlex flex="0 1" noMobile>
                <LeftPanel />
            </ResponsiveBox>
            <Outlet />
        </Flex>
    );
};

export default Dashboard;
