// @flow
import React, { type Node } from 'react';
import { Flex, Icon, Text } from '@locus-taxy/neo-ui';

const palettePropsMapping = Object.freeze({
    primary: {
        iconColor: 'blue.500',
        bgColor: 'blue.100',
    },
    neutral: {
        iconColor: 'grey.500',
        bgColor: 'grey.100',
    },
    warning: {
        iconColor: 'yellow.500',
        bgColor: 'yellow.100',
    },
});

type Props = {
    palette: 'primary' | 'neutral' | 'warning',
    text: string,
    thin?: boolean,
};

const Info = ({ palette = 'neutral', text, thin = false }: Props): Node => {
    const { iconColor, bgColor } = palettePropsMapping[palette];
    return (
        <Flex
            alignItems="center"
            minHeight={thin ? '28px' : '32px'}
            borderRadius="4px"
            backgroundColor={bgColor}
            px={4}
            py={2}
            width="100%"
        >
            <Icon icon="error_outline" color={iconColor} />
            <Text fontSize={1} ml={2}>
                {text}
            </Text>
        </Flex>
    );
};

export default Info;
