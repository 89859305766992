// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import { omsInstance } from 'middleware/api';

export type RequestBody = {
    requests: Array<{
        key: string,
        value: string | boolean,
    }>,
};

type Props = {
    clientId: string,
    orderId: string,
    body: RequestBody,
};

const useUpdateChecklist = (): MutationResult<any> => {
    return useMutation(({ clientId, orderId, body }: Props) => {
        return omsInstance.post(
            `/client/${clientId}/order/${orderId}/checklist`,
            body
        );
    });
};

export default useUpdateChecklist;
