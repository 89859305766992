//@flow
import * as Immutable from 'immutable';

import { Vehicle } from 'models/flow/Vehicle';
import type { VehicleModelType } from 'models/flow/Vehicle';
import { VehicleModelId } from './VehicleModelId';
import { VehicleModel } from './VehicleModel';
import { ResourceLimit } from './ResourceLimit';
import { Skill } from './Skill';

class InternalVehicle extends Vehicle {
    static fromJS(json: VehicleModelType): InternalVehicle {
        const state: Object = Object.assign({}, json);
        state.vehicleModelId = VehicleModelId.fromJS(state.vehicleModelId);
        state.vehicleModel = VehicleModel.fromJS(state.vehicleModel);
        state.resourceLimits = state.resourceLimits
            ? Immutable.List(state.resourceLimits).map((item) =>
                  ResourceLimit.fromJS(item)
              )
            : state.resourceLimits;
        state.additionalresourceLimits = Immutable.List(
            state.additionalresourceLimits
        ).map((item) => ResourceLimit.fromJS(item));
        state.additionalSkills = state.additionalSkills
            ? Immutable.List(state.additionalSkills).map((item) =>
                  Skill.fromJS(item)
              )
            : state.additionalSkills;
        return new this(Immutable.Map(state));
    }

    get vehicleModelId(): VehicleModelId {
        return this._state.get('vehicleModelId');
    }

    get vehicleModel(): VehicleModel {
        return this._state.get('vehicleModel');
    }

    get resourceLimits(): ?Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }

    get skills(): ?Immutable.List<Skill> {
        return this._state.get('skills');
    }

    get additionalresourceLimits(): Immutable.List<ResourceLimit> {
        return this._state.get('additionalresourceLimits');
    }

    get additionalSkills(): ?Immutable.List<Skill> {
        return this._state.get('additionalSkills');
    }
}

export { InternalVehicle as Vehicle };
