// @flow
import React, { type Node } from 'react';
import { Select, Box } from '@locus-taxy/neo-ui';
import { useGetVehicles, useDebouncedQueryString } from 'hooks';
import { useClientId } from 'modules/clientId';
import { STATUSES } from 'constants';
import { getBulkGetRequest } from './helper';

type Values = ?string | Array<string> | { value: string, label: string };
type Props = {
    label: string,
    value: Values,
    onSelect: (value: Values) => void,
    vehicleModelId?: string,
    disabled?: boolean,
    width?: string,
};

const VehicleSelect = ({
    label,
    value,
    onSelect,
    vehicleModelId,
    disabled,
    width = '220px',
}: Props): Node => {
    const clientId = useClientId();

    const { queryString, setQueryString, debouncedSetQueryString } =
        useDebouncedQueryString({});

    const { data: vehicles, isFetching } = useGetVehicles({
        clientId,
        requestBody: getBulkGetRequest({
            queryString,
            vehicleModelId,
            includeActiveFilter: true,
        }),
    });

    const handleMenuClose = () => setQueryString('');

    const vehicleOptions = vehicles
        ? vehicles
              .filter((vehicle) => vehicle.status === STATUSES.ACTIVE)
              .map((vehicle) => ({
                  ...vehicle.toJS(),
                  value: vehicle.id,
                  label: vehicle.name,
                  meta: vehicle.id,
                  id: vehicle.id,
              }))
        : [];

    return (
        <Box width={width} mb={3}>
            <Select
                label={label}
                size="small"
                fullWidth
                options={vehicleOptions}
                value={value}
                onChange={onSelect}
                onInputChange={debouncedSetQueryString}
                onMenuClose={handleMenuClose}
                isLoading={isFetching}
                disabled={disabled}
            />
        </Box>
    );
};

export default VehicleSelect;
