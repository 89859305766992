// @flow
import { tdmsInstance } from 'middleware/api';
import { BulkGetRequest } from 'models/internal/BulkGetRequest';
import { OperationsTour } from 'models/internal/OperationsTour';
import { useQuery, type QueryResult } from '@tanstack/react-query';
import { PaginationInfo } from 'models/internal/PaginationInfo';

type Props = {
    clientId: string,
    requestBody: BulkGetRequest,
};

type Response = {
    tours: Array<OperationsTour>,
    paginationInfo: PaginationInfo,
};

const useGetTours = ({
    clientId,
    requestBody,
}: Props): QueryResult<Response> => {
    return useQuery({
        queryKey: ['tour-search', clientId, requestBody],
        queryFn: async () => {
            const { data } = await tdmsInstance.post(
                `/client/${clientId}/tour-search`,
                {
                    ...requestBody.toJS(),
                }
            );
            const { operationsTours, paginationInfo } = data;

            return {
                paginationInfo: PaginationInfo.fromJS(paginationInfo),
                tours: operationsTours.map((tour) =>
                    OperationsTour.fromJS(tour)
                ),
            };
        },
        refetchOnWindowFocus: false,
    });
};

export default useGetTours;
