//@flow
import * as Immutable from 'immutable';

import { BulkGetRequest } from 'models/flow/BulkGetRequest';
import type { BulkGetRequestModelType } from 'models/flow/BulkGetRequest';
import { SortingInfo } from './SortingInfo';
import { Filter } from './Filter';

class InternalBulkGetRequest extends BulkGetRequest {
    static fromJS(json: BulkGetRequestModelType): InternalBulkGetRequest {
        const state: Object = Object.assign({}, json);
        state.filters = state.filters
            ? Immutable.List(state.filters).map((item) => Filter.fromJS(item))
            : state.filters;
        state.sortingInfo = state.sortingInfo
            ? Immutable.List(state.sortingInfo).map((item) =>
                  SortingInfo.fromJS(item)
              )
            : state.sortingInfo;
        return new this(Immutable.Map(state));
    }

    get filters(): ?Immutable.List<Filter> {
        return this._state.get('filters');
    }

    get sortingInfo(): ?Immutable.List<SortingInfo> {
        return this._state.get('sortingInfo');
    }
}

export { InternalBulkGetRequest as BulkGetRequest };
