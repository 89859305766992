//@flow
import * as Immutable from 'immutable';

import { Limits } from 'models/flow/Limits';
import type { LimitsModelType } from 'models/flow/Limits';
import { ResourceLimit } from './ResourceLimit';
import { VolumeLimit } from './VolumeLimit';

class InternalLimits extends Limits {
    static fromJS(json: LimitsModelType): InternalLimits {
        const state: Object = Object.assign({}, json);
        state.resourceLimits = state.resourceLimits
            ? Immutable.List(state.resourceLimits).map((item) =>
                  ResourceLimit.fromJS(item)
              )
            : state.resourceLimits;
        state.volumeLimit = state.volumeLimit
            ? VolumeLimit.fromJS(state.volumeLimit)
            : state.volumeLimit;
        return new this(Immutable.Map(state));
    }

    get resourceLimits(): ?Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }

    get volumeLimit(): ?VolumeLimit {
        return this._state.get('volumeLimit');
    }
}

export { InternalLimits as Limits };
