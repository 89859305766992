// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

import { Limits } from './Limits';
import { VolumeLimit } from './VolumeLimit';
import { Skill } from './Skill';

import type { VolumeLimitModelType } from './VolumeLimit';
import type { LimitsModelType } from './Limits';
import type { SkillModelType } from './Skill';

export type StatusType = 'ACTIVE' | 'INACTIVE';

export type VehicleModelId = {
    cleintId: string,
    modelId: string,
    version: ?number,
};

export type TourVehicleModelModelType = {
    clientId: string,
    id: VehicleModelId,
    name: string,
    limits: LimitsModelType,
    volume: VolumeLimitModelType,
    skills: ?{ tags: Array<SkillModelType> },
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TourVehicleModel extends ImmutableModel {
    static fromJS(json: TourVehicleModelModelType): TourVehicleModel {
        const state: Object = Object.assign({}, json);
        state.limits = Limits.fromJS(state.limits);
        state.volume = VolumeLimit.fromJS(state.volume);
        return new this(Immutable.Map(state));
    }

    toJS(): TourVehicleModelModelType {
        return {
            clientId: this.clientId,
            id: this.id,
            name: this.name,
            limits: this.limits.toJS(),
            volume: this.volume.toJS(),
            skills: this.skills,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): VehicleModelId {
        return this._state.get('id');
    }

    setId(id: VehicleModelId): this {
        return this.clone(this._state.set('id', id));
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get limits(): Limits {
        return this._state.get('limits');
    }

    setLimits(limits: Limits): this {
        return this.clone(this._state.set('limits', limits));
    }

    get volume(): VolumeLimit {
        return this._state.get('volume');
    }

    setVolume(volume: VolumeLimit): this {
        return this.clone(this._state.set('volume', volume));
    }

    get skills(): ?{ tags: Array<SkillModelType> } {
        return this._state.get('skills');
    }

    setSkills(skills: ?{ tags: Array<SkillModelType> }): this {
        return this.clone(this._state.set('skills', skills));
    }
}
