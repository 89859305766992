//@flow

import { MinimalPersonnel } from 'models/internal/MinimalPersonnel';
import * as React from 'react';
import checkPasswordStrength from './checkPasswordStrength';
import { type PasswordStrengthResult } from './types';

type Params = {
    password: string,
    minimalPersonnel: MinimalPersonnel,
};

export const validatePasswordStrength = ({
    password,
    minimalPersonnel,
}: Params): PasswordStrengthResult => {
    return checkPasswordStrength(password, {
        policy: minimalPersonnel.getPasswordPolicy(),
        minimumLength:
            minimalPersonnel.passwordAuthDetails.minimumPasswordLength,
    });
};

const usePasswordStrength = ({
    password,
    minimalPersonnel,
}: Params): PasswordStrengthResult => {
    const strength = React.useMemo(() => {
        return validatePasswordStrength({ password, minimalPersonnel });
    }, [password, minimalPersonnel]);
    return strength;
};

export default usePasswordStrength;
