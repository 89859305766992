// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type VolumeLimitUnitEnum =
    | 'ITEM_COUNT'
    | 'TASK_COUNT'
    | 'METERS_CUBIC'
    | 'LBH_METERS';

export type VolumeLimitModelType = {
    unit: VolumeLimitUnitEnum,
    higherThresh: string,
    lowerThresh: ?string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class VolumeLimit extends ImmutableModel {
    static fromJS(json: VolumeLimitModelType): VolumeLimit {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): VolumeLimitModelType {
        return {
            unit: this.unit,
            higherThresh: this.higherThresh,
            lowerThresh: this.lowerThresh,
        };
    }

    get unit(): VolumeLimitUnitEnum {
        return this._state.get('unit');
    }

    setUnit(unit: VolumeLimitUnitEnum): this {
        return this.clone(this._state.set('unit', unit));
    }

    get higherThresh(): string {
        return this._state.get('higherThresh');
    }

    setHigherThresh(higherThresh: string): this {
        return this.clone(this._state.set('higherThresh', higherThresh));
    }

    get lowerThresh(): ?string {
        return this._state.get('lowerThresh');
    }

    setLowerThresh(lowerThresh: ?string): this {
        return this.clone(this._state.set('lowerThresh', lowerThresh));
    }
}
