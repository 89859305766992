// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';
import type { TourSummaryModelType } from './TourSummary';
import type { TourVehicleModelType } from './TourVehicle';
import type { TourVisitModelType } from './TourVisit';

import { TourVehicle } from './TourVehicle';
import { TourSummary } from './TourSummary';
import { TourVisit } from './TourVisit';

type TourId = {
    clientId: string,
    tourId: string,
};

type AssignedUser = {
    carrierClientId: string,
    userId: string,
};

export type VehicleTourModelType = {
    tourId: TourId,
    hasDirections: boolean,
    tags: Array<string>,
    sourceTourId: TourId,
    vehicle: TourVehicleModelType,
    assignedUser: AssignedUser,
    isAssignmentCompleted: boolean,
    tourName: string,
    originalPlanId: string,
    status: string,
    isEdited: boolean,
    areResourcesBreached: boolean,
    summary: TourSummaryModelType,
    visits: Array<TourVisitModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class VehicleTour extends ImmutableModel {
    static fromJS(json: VehicleTourModelType): VehicleTour {
        const state: Object = Object.assign({}, json);
        state.tags = Immutable.List(state.tags);
        state.vehicle = TourVehicle.fromJS(state.vehicle);
        state.summary = TourSummary.fromJS(state.summary);
        state.visits = Immutable.List(state.visits).map((item) =>
            TourVisit.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): VehicleTourModelType {
        return {
            tourId: this.tourId,
            hasDirections: this.hasDirections,
            tags: this.tags.toArray(),
            sourceTourId: this.sourceTourId,
            vehicle: this.vehicle.toJS(),
            assignedUser: this.assignedUser,
            isAssignmentCompleted: this.isAssignmentCompleted,
            tourName: this.tourName,
            originalPlanId: this.originalPlanId,
            status: this.status,
            isEdited: this.isEdited,
            areResourcesBreached: this.areResourcesBreached,
            summary: this.summary.toJS(),
            visits: this.visits.toArray().map((item) => item.toJS()),
        };
    }

    get tourId(): TourId {
        return this._state.get('tourId');
    }

    setTourId(tourId: TourId): this {
        return this.clone(this._state.set('tourId', tourId));
    }

    get hasDirections(): boolean {
        return this._state.get('hasDirections');
    }

    setHasDirections(hasDirections: boolean): this {
        return this.clone(this._state.set('hasDirections', hasDirections));
    }

    get tags(): Immutable.List<string> {
        return this._state.get('tags');
    }

    setTags(tags: Immutable.List<string>): this {
        return this.clone(this._state.set('tags', tags));
    }

    get sourceTourId(): TourId {
        return this._state.get('sourceTourId');
    }

    setSourceTourId(sourceTourId: TourId): this {
        return this.clone(this._state.set('sourceTourId', sourceTourId));
    }

    get vehicle(): TourVehicle {
        return this._state.get('vehicle');
    }

    setVehicle(vehicle: TourVehicle): this {
        return this.clone(this._state.set('vehicle', vehicle));
    }

    get assignedUser(): AssignedUser {
        return this._state.get('assignedUser');
    }

    setAssignedUser(assignedUser: AssignedUser): this {
        return this.clone(this._state.set('assignedUser', assignedUser));
    }

    get isAssignmentCompleted(): boolean {
        return this._state.get('isAssignmentCompleted');
    }

    setIsAssignmentCompleted(isAssignmentCompleted: boolean): this {
        return this.clone(
            this._state.set('isAssignmentCompleted', isAssignmentCompleted)
        );
    }

    get tourName(): string {
        return this._state.get('tourName');
    }

    setTourName(tourName: string): this {
        return this.clone(this._state.set('tourName', tourName));
    }

    get originalPlanId(): string {
        return this._state.get('originalPlanId');
    }

    setOriginalPlanId(originalPlanId: string): this {
        return this.clone(this._state.set('originalPlanId', originalPlanId));
    }

    get status(): string {
        return this._state.get('status');
    }

    setStatus(status: string): this {
        return this.clone(this._state.set('status', status));
    }

    get isEdited(): boolean {
        return this._state.get('isEdited');
    }

    setIsEdited(isEdited: boolean): this {
        return this.clone(this._state.set('isEdited', isEdited));
    }

    get areResourcesBreached(): boolean {
        return this._state.get('areResourcesBreached');
    }

    setAreResourcesBreached(areResourcesBreached: boolean): this {
        return this.clone(
            this._state.set('areResourcesBreached', areResourcesBreached)
        );
    }

    get summary(): TourSummary {
        return this._state.get('summary');
    }

    setSummary(summary: TourSummary): this {
        return this.clone(this._state.set('summary', summary));
    }

    get visits(): Immutable.List<TourVisit> {
        return this._state.get('visits');
    }

    setVisits(visits: Immutable.List<TourVisit>): this {
        return this.clone(this._state.set('visits', visits));
    }
}
