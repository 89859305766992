// @flow
export const PasswordPolicyEnum = Object.freeze({
    None: 'NONE',
    Low: 'LOW',
    Fair: 'FAIR',
    Good: 'GOOD',
    Excellent: 'EXCELLENT',
    Locus: 'LOCUS',
});
export type PasswordPolicyEnumType = $Values<typeof PasswordPolicyEnum>;

export const PasswordAuthProviderEnum = Object.freeze({
    iam: 'IAM',
    auth0: 'AUTH0',
});
export type PasswordAuthProviderEnumType = $Values<
    typeof PasswordAuthProviderEnum
>;
