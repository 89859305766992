//@flow
import * as Immutable from 'immutable';

import { Skill } from 'models/flow/Skill';
import type { SkillModelType } from 'models/flow/Skill';
import { TeamId } from './TeamId';

class InternalSkill extends Skill {
    static fromJS(json: SkillModelType): InternalSkill {
        const state: Object = Object.assign({}, json);
        state.teams = state.teams
            ? Immutable.List(state.teams).map((item) => TeamId.fromJS(item))
            : state.teams;
        return new this(Immutable.Map(state));
    }

    get teams(): ?Immutable.List<TeamId> {
        return this._state.get('teams');
    }
}

export { InternalSkill as Skill };
