// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

import { TeamId } from './TeamId';

import type { TeamIdModelType } from './TeamId';

export type SkillModelType = {
    clientId: string,
    id: string,
    name: string,
    teams: ?Array<TeamIdModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Skill extends ImmutableModel {
    static fromJS(json: SkillModelType): Skill {
        const state: Object = Object.assign({}, json);
        state.teams = state.teams
            ? Immutable.List(state.teams).map((item) => TeamId.fromJS(item))
            : state.teams;
        return new this(Immutable.Map(state));
    }

    toJS(): SkillModelType {
        return {
            clientId: this.clientId,
            id: this.id,
            name: this.name,
            teams: this.teams
                ? this.teams.toArray().map((item) => item.toJS())
                : this.teams,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get teams(): ?Immutable.List<TeamId> {
        return this._state.get('teams');
    }

    setTeams(teams: ?Immutable.List<TeamId>): this {
        return this.clone(this._state.set('teams', teams));
    }
}
