// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

export type SkillIdModelType = {
    clientId: string,
    id: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class SkillId extends ImmutableModel {
    static fromJS(json: SkillIdModelType): SkillId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): SkillIdModelType {
        return {
            clientId: this.clientId,
            id: this.id,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }
}
