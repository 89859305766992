// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

import { MinimalVisitLocation } from './MinimalVisitLocation';
import type { MinimalVisitLocationModelType } from './MinimalVisitLocation';
import type { ResourcesType } from './TourSummary';

export type VisitTypes =
    | 'SHIFT_START'
    | 'BREAK'
    | 'HOMEBASE'
    | 'CUSTOMER'
    | 'SERVICE'
    | 'SHIFT_END';

type VolumenUnits = 'ITEM_COUNT' | 'METERS_CUBIC' | 'TASK_COUNT';
type ExchangeTypes = 'GIVE' | 'COLLECT';
type VisitStatuses = 'RECEIVED' | 'COMPLETED';

export type TourVisitModelType = {
    id: string,
    clientId: string,
    sourceId: string,
    chosenSlot: {
        start: string,
        end: string,
    },
    eta: string,
    etd: string,
    tat: number,
    volume: {
        unit: VolumenUnits,
        value: number,
    },
    resources: ResourcesType,
    exchangeType: ExchangeTypes,
    visitLocation: MinimalVisitLocationModelType,
    customerVisitSequence: ?number,
    visitType: VisitTypes,
    visitStatus: VisitStatuses,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TourVisit extends ImmutableModel {
    static fromJS(json: TourVisitModelType): TourVisit {
        const state: Object = Object.assign({}, json);
        state.visitLocation = MinimalVisitLocation.fromJS(state.visitLocation);
        return new this(Immutable.Map(state));
    }

    toJS(): TourVisitModelType {
        return {
            id: this.id,
            clientId: this.clientId,
            sourceId: this.sourceId,
            chosenSlot: this.chosenSlot,
            eta: this.eta,
            etd: this.etd,
            tat: this.tat,
            volume: this.volume,
            resources: this.resources,
            exchangeType: this.exchangeType,
            visitLocation: this.visitLocation.toJS(),
            customerVisitSequence: this.customerVisitSequence,
            visitType: this.visitType,
            visitStatus: this.visitStatus,
        };
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get sourceId(): string {
        return this._state.get('sourceId');
    }

    setSourceId(sourceId: string): this {
        return this.clone(this._state.set('sourceId', sourceId));
    }

    get chosenSlot(): {
        start: string,
        end: string,
    } {
        return this._state.get('chosenSlot');
    }

    setChosenSlot(chosenSlot: { start: string, end: string }): this {
        return this.clone(this._state.set('chosenSlot', chosenSlot));
    }

    get eta(): string {
        return this._state.get('eta');
    }

    setEta(eta: string): this {
        return this.clone(this._state.set('eta', eta));
    }

    get etd(): string {
        return this._state.get('etd');
    }

    setEtd(etd: string): this {
        return this.clone(this._state.set('etd', etd));
    }

    get tat(): number {
        return this._state.get('tat');
    }

    setTat(tat: number): this {
        return this.clone(this._state.set('tat', tat));
    }

    get volume(): {
        unit: VolumenUnits,
        value: number,
    } {
        return this._state.get('volume');
    }

    setVolume(volume: { unit: VolumenUnits, value: number }): this {
        return this.clone(this._state.set('volume', volume));
    }

    get resources(): ResourcesType {
        return this._state.get('resources');
    }

    setResources(resources: ResourcesType): this {
        return this.clone(this._state.set('resources', resources));
    }

    get exchangeType(): ExchangeTypes {
        return this._state.get('exchangeType');
    }

    setExchangeType(exchangeType: ExchangeTypes): this {
        return this.clone(this._state.set('exchangeType', exchangeType));
    }

    get visitLocation(): MinimalVisitLocation {
        return this._state.get('visitLocation');
    }

    setVisitLocation(visitLocation: MinimalVisitLocation): this {
        return this.clone(this._state.set('visitLocation', visitLocation));
    }

    get customerVisitSequence(): ?number {
        return this._state.get('customerVisitSequence');
    }

    setCustomerVisitSequence(customerVisitSequence: ?number): this {
        return this.clone(
            this._state.set('customerVisitSequence', customerVisitSequence)
        );
    }

    get visitType(): VisitTypes {
        return this._state.get('visitType');
    }

    setVisitType(visitType: VisitTypes): this {
        return this.clone(this._state.set('visitType', visitType));
    }

    get visitStatus(): VisitStatuses {
        return this._state.get('visitStatus');
    }

    setVisitStatus(visitStatus: VisitStatuses): this {
        return this.clone(this._state.set('visitStatus', visitStatus));
    }
}
