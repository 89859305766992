// @flow
import React, { type Node } from 'react';
import { Columns, Flex } from '@locus-taxy/neo-ui';
import { TourStatusDetails, TourStatuses } from 'constants';
import TourStatusFilterChip from './TourStatusFilterChip';

type Props = {
    selectedTourStatus: $Values<typeof TourStatuses> | 'all',
    onStatusFilterChange: (
        status: $Values<typeof TourStatuses> | 'all'
    ) => void,
};

const TourStatusFilterContainer = ({
    selectedTourStatus,
    onStatusFilterChange,
}: Props): Node => {
    return (
        <Columns space="small">
            {TourStatusDetails.map(({ label, statusKey }) => {
                const isActive = selectedTourStatus === statusKey;
                return (
                    <Flex key={statusKey} flexShrink={0}>
                        <TourStatusFilterChip
                            isActive={isActive}
                            label={label}
                            statusKey={statusKey}
                            onClick={() => onStatusFilterChange(statusKey)}
                        />
                    </Flex>
                );
            })}
        </Columns>
    );
};

export default TourStatusFilterContainer;
