//@flow
import * as Immutable from 'immutable';

import { SkillId } from 'models/flow/SkillId';
import type { SkillIdModelType } from 'models/flow/SkillId';

class InternalSkillId extends SkillId {
    static fromJS(json: SkillIdModelType): InternalSkillId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalSkillId as SkillId };
