// @flow
import React, { type Node } from 'react';
import { Flex, Text, Stack } from '@locus-taxy/neo-ui';
import { VehicleTour } from 'models/internal/VehicleTour';
import {
    getShortDateTime,
    convertMeters,
    convertSeconds,
} from 'utils/timeUtils';
import { roundUp } from 'utils/numberUtils';
import { getProcessedResources } from 'components/dashboard/tours/helper';
import { getLocationAndTimeFromVisits } from './helper';
import WithIcon from 'components/common/WithIcon';

export const resourceIcons = Object.freeze({
    task: 'dispatcher_tasks',
    weight: 'dispatcher_weight',
    volume: 'dispatcher_volume',
});

export const SubText = ({ children, bold, ...rest }: any): Node => {
    return (
        <Text
            {...rest}
            color={bold ? 'grey.800' : 'grey.600'}
            fontSize={1}
            fontWeight={bold ? '600' : '400'}
        >
            {children}
        </Text>
    );
};

type Props = {
    tour: VehicleTour,
};

const TourDetailsCard = ({ tour }: Props): Node => {
    const { summary, visits } = tour;
    const { resources, volume, totalDuration, totalTravelDistance } = summary;

    const processedResources = getProcessedResources(resources);
    const { startTime, endTime } = getLocationAndTimeFromVisits(visits);

    return (
        <Stack space="small">
            <Flex flexWrap="wrap">
                <WithIcon icon="stopwatch">
                    <SubText>Starts</SubText>&nbsp;
                    <SubText bold>
                        {startTime ? getShortDateTime(startTime) : '--'}
                    </SubText>
                </WithIcon>
                <WithIcon icon="stopwatch">
                    <SubText>Ends</SubText>&nbsp;
                    <SubText bold>
                        {endTime ? getShortDateTime(endTime) : '--'}
                    </SubText>
                </WithIcon>
                <WithIcon icon="operating_hours">
                    <SubText>Duration</SubText>&nbsp;
                    <SubText bold>
                        {totalDuration
                            ? convertSeconds(totalDuration, true)
                            : '--'}
                    </SubText>
                </WithIcon>
                <WithIcon icon="motiontrack_distance">
                    <SubText>Distance</SubText>&nbsp;
                    <SubText bold>
                        {totalTravelDistance
                            ? convertMeters(totalTravelDistance)
                            : '--'}
                    </SubText>
                </WithIcon>
                <WithIcon icon={resourceIcons.volume}>
                    <SubText bold>{roundUp(volume.used)}</SubText>&nbsp;
                    <SubText>{`/ ${roundUp(volume.available)} ${
                        volume.definition?.unit || 'COUNT'
                    }`}</SubText>
                </WithIcon>
                {processedResources.map(
                    ({ name, resource: { used, available, definition } }) => (
                        <WithIcon icon={resourceIcons[name]} key={name}>
                            <SubText bold>{roundUp(used)}</SubText>&nbsp;
                            <SubText>{`/ ${roundUp(available)} ${
                                definition?.unit || 'COUNT'
                            }`}</SubText>
                        </WithIcon>
                    )
                )}
            </Flex>
        </Stack>
    );
};

export default TourDetailsCard;
