// @flow
import * as React from 'react';
import { Navigate, useLocation, useHref } from 'react-router-dom';

import { useAuth } from '@locus-taxy/auth-react';

type Props = {
    children: React.Node,
};

const AuthBoundary = ({ children }: Props): React.Node => {
    const auth = useAuth();
    const location = useLocation();

    // using useHref to get the url with the query params
    const currentUrl = useHref(location);

    if (!auth.isAuthenticated) {
        return <Navigate to={`/login?returnTo=${currentUrl}`} />;
    }

    return children;
};

export default AuthBoundary;
