// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

export type RiderPersonaIdModelType = {
    clientId: string,
    riderPersonaId: string,
    version: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class RiderPersonaId extends ImmutableModel {
    static fromJS(json: RiderPersonaIdModelType): RiderPersonaId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): RiderPersonaIdModelType {
        return {
            clientId: this.clientId,
            riderPersonaId: this.riderPersonaId,
            version: this.version,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get riderPersonaId(): string {
        return this._state.get('riderPersonaId');
    }

    setRiderPersonaId(riderPersonaId: string): this {
        return this.clone(this._state.set('riderPersonaId', riderPersonaId));
    }

    get version(): number {
        return this._state.get('version');
    }

    setVersion(version: number): this {
        return this.clone(this._state.set('version', version));
    }
}
