// @flow
import * as React from 'react';

import { useSearchParams, Navigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuth } from '@locus-taxy/auth-react';

import useUpdatePassword from 'hooks/api/useUpdatePassword';

import {
    Card,
    Stack,
    Columns,
    Flex,
    Text,
    Box,
    PasswordField,
    Loader,
    Button,
    Alert,
} from '@locus-taxy/neo-ui';
import Background from 'components/common/Background';

import PasswordStrength from './PasswordStrength/PasswordStrength';
import { validatePasswordStrength } from './PasswordStrength/usePasswordStrength';

import useGetMinimalPersonnel from 'hooks/api/useGetMinimalPersonnel';
import { MinimalPersonnel } from 'models/internal/MinimalPersonnel';

type Props = { minimalPersonnel: MinimalPersonnel };

const createSchema = (minimalPersonnel: MinimalPersonnel) => {
    const reqMsg = 'This field is required';

    return yup.object({
        currentPassword: yup.string().required(reqMsg),
        newPassword: yup
            .string()
            .required(reqMsg)
            .test('strength', 'Password not matching the criteria', (value) => {
                const result = validatePasswordStrength({
                    password: value,
                    minimalPersonnel,
                });
                return result.verified;
            }),
        confirmPassword: yup
            .string()
            .required(reqMsg)
            .oneOf([yup.ref('newPassword'), null], "Passwords don't match"),
    });
};

type FormData = {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
};

const AppChangePassword = ({ minimalPersonnel }: Props): React.Node => {
    const auth = useAuth();
    const [showPasswordStrength, setShowPasswordStrength] =
        React.useState(false);

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState(null);

    const schema = React.useMemo(
        () => createSchema(minimalPersonnel),
        [minimalPersonnel]
    );

    const useUpdatePasswordMutation = useUpdatePassword();

    // use react-hook-form to validate the form
    const form = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = form;

    const logout = () => {
        auth.logout({ returnTo: window.location.origin });
    };

    const onSubmit = async (data: FormData) => {
        try {
            setIsSubmitting(true);
            await useUpdatePasswordMutation.mutateAsync({
                personnelClientId: minimalPersonnel.clientId,
                personnelId: minimalPersonnel.personnelId,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
            });
            // handle success
            logout();
        } catch (error) {
            setSubmitError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
        >
            <Background />
            <Box padding="medium" maxWidth="440px" width="100%">
                <Card>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack space="medium">
                            <Stack space="small">
                                <Text fontSize={2} fontWeight="600">
                                    Change Password
                                </Text>
                                <Controller
                                    name="currentPassword"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <PasswordField
                                            label="Current Password"
                                            fullWidth
                                            required
                                            value={field.value}
                                            autoFocus
                                            error={Boolean(
                                                errors.currentPassword
                                            )}
                                            helperText={
                                                errors.currentPassword?.message
                                            }
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                        />
                                    )}
                                />
                                <Stack space="small">
                                    <Controller
                                        name="newPassword"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <>
                                                <PasswordField
                                                    label="New Password"
                                                    required
                                                    fullWidth
                                                    value={field.value}
                                                    onFocus={field.onFocus}
                                                    onBlur={field.onBlur}
                                                    error={Boolean(
                                                        errors.newPassword
                                                    )}
                                                    helperText={
                                                        errors.newPassword
                                                            ?.message
                                                    }
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                    }}
                                                    onFocus={() => {
                                                        setShowPasswordStrength(
                                                            true
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        setShowPasswordStrength(
                                                            false
                                                        );
                                                    }}
                                                />
                                                {showPasswordStrength && (
                                                    <PasswordStrength
                                                        password={field.value}
                                                        minimalPersonnel={
                                                            minimalPersonnel
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                </Stack>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <PasswordField
                                            label="Confirm New Password"
                                            required
                                            fullWidth
                                            value={field.value}
                                            error={Boolean(
                                                errors.confirmPassword
                                            )}
                                            helperText={
                                                errors.confirmPassword?.message
                                            }
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack space="small">
                                {submitError && (
                                    <Alert
                                        text={submitError.message}
                                        palette="danger"
                                    />
                                )}
                                <Columns space="small">
                                    <Button
                                        type="button"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            logout();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        appearance="solid"
                                        palette="primary"
                                        isLoading={isSubmitting}
                                    >
                                        Change Password
                                    </Button>
                                </Columns>
                            </Stack>
                        </Stack>
                    </form>
                </Card>
            </Box>
        </Flex>
    );
};

const AppChangePasswordContainer = ({
    userId,
}: {
    userId: string,
}): React.Node => {
    const minimalPersonnelQuery = useGetMinimalPersonnel({
        username: userId,
    });

    if (minimalPersonnelQuery.status === 'loading') {
        return <Loader width="100%" height="100%" />;
    }

    if (minimalPersonnelQuery.status === 'error') {
        return <div>Error: {minimalPersonnelQuery.error.message}</div>;
    }

    return <AppChangePassword minimalPersonnel={minimalPersonnelQuery.data} />;
};

const AppChangePasswordContainerPage = (): React.Node => {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');

    if (!userId) {
        return <Navigate to="/login" />;
    }

    return <AppChangePasswordContainer userId={userId} />;
};

export default AppChangePasswordContainerPage;
