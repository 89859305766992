// @flow
import React, { type Node } from 'react';
import { Image, Position } from '@locus-taxy/neo-ui';
import background from 'img/background.svg';

const Background = (): Node => {
    return (
        <Position
            position="absolute"
            top="0"
            left="0"
            zIndex={-1}
            height="100%"
            width="100%"
        >
            <Image
                src={background}
                height="100%"
                width="100%"
                sx={{ objectFit: 'cover' }}
            />
        </Position>
    );
};

export default Background;
