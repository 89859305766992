// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import useFileUpload from './useFileUpload';
import type { FileMap } from 'components/dashboard/tours/ChecklistItems/helper';

type Props = {
    clientId: string,
    orderId: string,
    filesMap: FileMap,
};

export type UrlMap = {
    [key: string]: string,
};

type ResponseType = {
    status: string,
    url: string,
};

const useMultiFileUpload = (): MutationResult<UrlMap> => {
    const { mutateAsync } = useFileUpload();
    return useMutation(async ({ clientId, orderId, filesMap }: Props) => {
        const promises = Object.keys(filesMap).map(async (key: string) => {
            const file = filesMap[key];

            try {
                const result = await mutateAsync({
                    clientId,
                    orderId,
                    file,
                });
                return { key, data: result.data };
            } catch (err) {
                return err;
            }
        });

        try {
            const result = await Promise.all(promises);
            return result.reduce(
                (
                    map: UrlMap,
                    response: Error | { key: string, data: ResponseType }
                ) => {
                    if (response instanceof Error) {
                        return map;
                    }
                    return {
                        ...map,
                        [response.key]: response.data.url,
                    };
                },
                {}
            );
        } catch (err) {
            return err;
        }
    });
};

export default useMultiFileUpload;
