// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo
import { ChecklistItemTypes } from 'constants/checklist';

export type FormatEnums = $Values<typeof ChecklistItemTypes>;

export type ChecklistItemModelType = {
    key: string,
    item: string,
    format: FormatEnums,
    possibleValues: Array<string>,
    allowedValues: Array<string>,
    optional: boolean,
    lineItemLevel: boolean,
    additionalOptions: Object,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class ChecklistItem extends ImmutableModel {
    static fromJS(json: ChecklistItemModelType): ChecklistItem {
        const state: Object = Object.assign({}, json);
        state.possibleValues = Immutable.List(state.possibleValues);
        state.allowedValues = Immutable.List(state.allowedValues);
        return new this(Immutable.Map(state));
    }

    toJS(): ChecklistItemModelType {
        return {
            key: this.key,
            item: this.item,
            format: this.format,
            possibleValues: this.possibleValues.toArray(),
            allowedValues: this.allowedValues.toArray(),
            optional: this.optional,
            lineItemLevel: this.lineItemLevel,
            additionalOptions: this.additionalOptions,
        };
    }

    get key(): string {
        return this._state.get('key');
    }

    setKey(key: string): this {
        return this.clone(this._state.set('key', key));
    }

    get item(): string {
        return this._state.get('item');
    }

    setItem(item: string): this {
        return this.clone(this._state.set('item', item));
    }

    get format(): FormatEnums {
        return this._state.get('format');
    }

    setFormat(format: FormatEnums): this {
        return this.clone(this._state.set('format', format));
    }

    get possibleValues(): Immutable.List<string> {
        return this._state.get('possibleValues');
    }

    setPossibleValues(possibleValues: Immutable.List<string>): this {
        return this.clone(this._state.set('possibleValues', possibleValues));
    }

    get allowedValues(): Immutable.List<string> {
        return this._state.get('allowedValues');
    }

    setAllowedValues(allowedValues: Immutable.List<string>): this {
        return this.clone(this._state.set('allowedValues', allowedValues));
    }

    get optional(): boolean {
        return this._state.get('optional');
    }

    setOptional(optional: boolean): this {
        return this.clone(this._state.set('optional', optional));
    }

    get lineItemLevel(): boolean {
        return this._state.get('lineItemLevel');
    }

    setLineItemLevel(lineItemLevel: boolean): this {
        return this.clone(this._state.set('lineItemLevel', lineItemLevel));
    }

    get additionalOptions(): Object {
        return this._state.get('additionalOptions');
    }

    setAdditionalOptions(additionalOptions: Object): this {
        return this.clone(
            this._state.set('additionalOptions', additionalOptions)
        );
    }
}
