// @flow
import React, { type Node } from 'react';
import { Stack, Box, Columns, Icon } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import { range as _range } from 'lodash';
import ChecklistHeader from './ChecklistHeader';
import type { ExtraProps } from './helper';

type Props = {
    checklistConfig: ChecklistItem,
    onChange: (value: string) => void,
    value: boolean,
    extraProps?: ExtraProps,
};

const InputSelector = ({ checklistConfig, onChange, value }: Props): Node => {
    const { item, optional } = checklistConfig;

    return (
        <Stack space="small">
            <ChecklistHeader optional={optional}>{item}</ChecklistHeader>
            <Columns>
                {_range(1, 6).map((rating) => (
                    <Box
                        key={rating}
                        onClick={() => onChange(rating)}
                        sx={{ cursor: 'pointer' }}
                    >
                        <Icon
                            size="xLarge"
                            icon="star_filled"
                            color={rating <= value ? 'yellow.500' : 'grey.300'}
                        />
                    </Box>
                ))}
            </Columns>
        </Stack>
    );
};

export default InputSelector;
