//@flow
import * as Immutable from 'immutable';

import { RiderPersonaLimits } from 'models/flow/RiderPersonaLimits';
import type { RiderPersonaLimitsModelType } from 'models/flow/RiderPersonaLimits';
import { VolumeLimit } from './VolumeLimit';
import { RiderPersonaResourceLimit } from './RiderPersonaResourceLimit';
import { ResourceLimit } from './ResourceLimit';

class InternalRiderPersonaLimits extends RiderPersonaLimits {
    static fromJS(
        json: RiderPersonaLimitsModelType
    ): InternalRiderPersonaLimits {
        const state: Object = Object.assign({}, json);
        state.volume = state.volume
            ? VolumeLimit.fromJS(state.volume)
            : state.volume;
        state.distance = state.distance
            ? RiderPersonaResourceLimit.fromJS(state.distance)
            : state.distance;
        state.weight = state.weight
            ? RiderPersonaResourceLimit.fromJS(state.weight)
            : state.weight;
        state.travelTime = state.travelTime
            ? RiderPersonaResourceLimit.fromJS(state.travelTime)
            : state.travelTime;
        state.totalTime = state.totalTime
            ? RiderPersonaResourceLimit.fromJS(state.totalTime)
            : state.totalTime;
        state.location = state.location
            ? RiderPersonaResourceLimit.fromJS(state.location)
            : state.location;
        state.task = state.task
            ? RiderPersonaResourceLimit.fromJS(state.task)
            : state.task;
        state.tour = state.tour
            ? RiderPersonaResourceLimit.fromJS(state.tour)
            : state.tour;
        state.resourceLimits = Immutable.List(state.resourceLimits).map(
            (item) => ResourceLimit.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get volume(): ?VolumeLimit {
        return this._state.get('volume');
    }

    get distance(): ?RiderPersonaResourceLimit {
        return this._state.get('distance');
    }

    get weight(): ?RiderPersonaResourceLimit {
        return this._state.get('weight');
    }

    get travelTime(): ?RiderPersonaResourceLimit {
        return this._state.get('travelTime');
    }

    get totalTime(): ?RiderPersonaResourceLimit {
        return this._state.get('totalTime');
    }

    get location(): ?RiderPersonaResourceLimit {
        return this._state.get('location');
    }

    get task(): ?RiderPersonaResourceLimit {
        return this._state.get('task');
    }

    get tour(): ?RiderPersonaResourceLimit {
        return this._state.get('tour');
    }

    get resourceLimits(): Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }
}

export { InternalRiderPersonaLimits as RiderPersonaLimits };
