// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import { platformInstance } from 'middleware/api';

type Params = {
    // NOTE: in case of mara, clientId and personnelClientId are different
    personnelClientId: string,
    personnelId: string,
    currentPassword: string,
    newPassword: string,
};

const useUpdatePassword = (): MutationResult<void> => {
    return useMutation(
        ({
            personnelClientId,
            personnelId,
            currentPassword,
            newPassword,
        }: Params) => {
            return platformInstance.post(
                `/client/${personnelClientId}/personnel-master/${personnelId}/password`,
                {
                    existingPassword: currentPassword,
                    newPassword,
                }
            );
        }
    );
};

export default useUpdatePassword;
