// @flow
import React, { type Node } from 'react';
import { Input } from '@locus-taxy/neo-ui';
import ResponsiveBox from 'components/common/ResponsiveBox';

type Props = {
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
    value: string,
};

const OrderSearchInput = ({ onChange, value }: Props): Node => {
    return (
        <>
            <ResponsiveBox noMobile mb={4}>
                <Input
                    width="300px"
                    value={value}
                    onChange={onChange}
                    placeholder="Search for order id.."
                />
            </ResponsiveBox>
            <ResponsiveBox noDesktop>
                <Input
                    value={value}
                    onChange={onChange}
                    placeholder="Search for order id.."
                />
            </ResponsiveBox>
        </>
    );
};

export default OrderSearchInput;
