// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import { tdmsInstance } from 'middleware/api';

export type AssignmentBody = {
    riderId: string,
    vehicleModelId: string,
    transporterId: ?string,
    vehicleId: string,
};

type Props = {
    clientId: string,
    tourId: string,
    body: AssignmentBody,
};

const useUpdateAssignment = (): MutationResult<any> => {
    return useMutation(({ clientId, tourId, body }: Props) => {
        return tdmsInstance.post(
            `/client/${clientId}/tour/${tourId}/assign`,
            body
        );
    });
};

export default useUpdateAssignment;
