// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

export type VehicleModelIdModelType = {
    clientId: string,
    modelId: string,
    version: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class VehicleModelId extends ImmutableModel {
    static fromJS(json: VehicleModelIdModelType): VehicleModelId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): VehicleModelIdModelType {
        return {
            clientId: this.clientId,
            modelId: this.modelId,
            version: this.version,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get modelId(): string {
        return this._state.get('modelId');
    }

    setModelId(modelId: string): this {
        return this.clone(this._state.set('modelId', modelId));
    }

    get version(): number {
        return this._state.get('version');
    }

    setVersion(version: number): this {
        return this.clone(this._state.set('version', version));
    }
}
