// @flow
import { TourVisit } from 'models/internal/TourVisit';
import { type List } from 'immutable';

export const getLocationAndTimeFromVisits = (
    visits: List<TourVisit>
): {
    startLocation: string,
    endLocation: string,
    startTime: string,
    endTime: string,
} => {
    const shiftStartVisit = visits.find(
        (visit) => visit.visitType === 'SHIFT_START'
    );
    const shiftEndVisit = visits.find(
        (visit) => visit.visitType === 'SHIFT_END'
    );

    return {
        startLocation:
            shiftStartVisit?.visitLocation?.locationAddress.getFullAddress() ||
            '--',
        endLocation:
            shiftEndVisit?.visitLocation?.locationAddress.getFullAddress() ||
            '--',
        startTime: shiftStartVisit?.eta || '',
        endTime: shiftEndVisit?.etd || '',
    };
};
