// @flow
import React, { type Node, useState, useMemo } from 'react';
import { Flex, Text, Divider, Box } from '@locus-taxy/neo-ui';
import OrderCard from './OrderCard';
import { useDebouncedQueryString } from 'hooks';
import { TourVisit } from 'models/internal/TourVisit';
import { type List } from 'immutable';
import VirtualList from 'components/common/VirtualList';
import VirtualInfiniteVariableList from 'components/common/VirtualInfiniteVariableList';
import ResponsiveBox from 'components/common/ResponsiveBox';
import OrderSearchInput from './OrderSearchInput';
import { OrderDetails } from 'models/internal/OrderDetails';
import { noop as _noop } from 'lodash';

type Props = {
    visits: List<TourVisit>,
    orderDetails: List<OrderDetails>,
    refetch: () => void,
    isTourClosed: boolean,
};

const DEFAULT_CARD_HEIGHT_DESKTOP = 135;
const DEFAULT_CARD_HEIGHT_MOBILE = 190;
const DEFAULT_CARD_HEIGHT_MOBILE_LONG = 250;

const Row = ({
    index,
    style,
    data,
}: {
    index: number,
    style: Object,
    data: {
        visits: Array<TourVisit>,
        orderDetails: List<OrderDetails>,
        refetch: () => void,
        isTourClosed: boolean,
    },
}): Node => {
    const { visits, orderDetails, refetch, isTourClosed } = data;

    if (orderDetails.isEmpty()) {
        return null;
    }
    const visit = visits[index];
    const { sourceId } = visit;
    const { checklist, checklistValues, orderStatus } = orderDetails.find(
        ({ orderId }) => orderId === sourceId
    );

    return (
        <div style={style}>
            <OrderCard
                orderStatus={orderStatus}
                refetch={refetch}
                visit={visit}
                checkList={checklist.customerCompleted}
                checklistValues={checklistValues}
                isTourClosed={isTourClosed}
            />
        </div>
    );
};

const OrderList = ({
    visits,
    orderDetails,
    refetch,
    isTourClosed,
}: Props): Node => {
    const customerVisits = visits.filter(
        (visit) => visit.visitType === 'CUSTOMER'
    );
    const { queryString, debouncedSetQueryString } = useDebouncedQueryString(
        {}
    );
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        debouncedSetQueryString(query);
        setSearchQuery(query);
    };

    const filteredVisits = useMemo(() => {
        if (queryString) {
            return customerVisits.filter((visit) =>
                visit.sourceId.includes(queryString)
            );
        }
        return customerVisits;
    }, [queryString, customerVisits]);

    const itemData = {
        visits: filteredVisits.toArray(),
        orderDetails,
        refetch,
        isTourClosed,
    };

    const commonProps = {
        itemCount: filteredVisits.size,
        itemData,
    };

    const itemSizes = filteredVisits
        .map(({ sourceId }) => {
            const { orderStatus } =
                orderDetails.find(({ orderId }) => orderId === sourceId) || {};
            return ['EXECUTING', 'COMPLETED'].includes(orderStatus)
                ? DEFAULT_CARD_HEIGHT_MOBILE_LONG
                : DEFAULT_CARD_HEIGHT_MOBILE;
        })
        .toArray();

    return (
        <Flex
            flexDirection="column"
            mt={4}
            height="100%"
            overflow="hidden"
            width="100%"
        >
            <Text
                fontSize={1}
                color="grey.700"
                fontWeight="700"
                textTransform="uppercase"
                mb={2}
                letterSpacing={1.5}
            >
                orders in tour
            </Text>
            <OrderSearchInput
                onChange={handleSearchQueryChange}
                value={searchQuery}
            />
            <Divider color="grey.200" />
            <ResponsiveBox noMobile height="100%">
                <Box pt={4} pr={4} overflow="auto" height="100%">
                    <VirtualList
                        {...commonProps}
                        itemSize={DEFAULT_CARD_HEIGHT_DESKTOP}
                    >
                        {Row}
                    </VirtualList>
                </Box>
            </ResponsiveBox>
            <ResponsiveBox noDesktop height="100%">
                <Box pt={4} pr={4} overflow="auto" height="100%">
                    <VirtualInfiniteVariableList
                        {...commonProps}
                        isItemLoaded={() => true}
                        loadMoreItems={_noop}
                        itemSize={(index: number) => itemSizes[index]}
                    >
                        {Row}
                    </VirtualInfiniteVariableList>
                </Box>
            </ResponsiveBox>
        </Flex>
    );
};

export default OrderList;
