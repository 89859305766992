// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { TransporterId } from './TransporterId';
import { SkillId } from './SkillId';
import { RiderPersonaLimits } from './RiderPersonaLimits';

import type { TransporterIdModelType } from './TransporterId';
import type { SkillIdModelType } from './SkillId';
import type { RiderPersonaLimitsModelType } from './RiderPersonaLimits';

type VolumeUnitEnumType = 'METERS_CUBIC' | 'ITEM_COUNT' | 'TASK_COUNT';

export type StatusEnum = 'ACTIVE' | 'INACTIVE';
export type RiderPersonaTypeEnum = 'PJP' | 'DELIVERY' | 'SERVICE' | 'NONE';
export type FleetTypeEnum = 'CAPTIVE' | 'THIRD_PARTY' | 'ADHOC';

export type RiderPersonaModelType = {
    clientId: string,
    id: string,
    code: string,
    name: string,
    status: StatusEnum,
    type: RiderPersonaTypeEnum,
    fleetType: FleetTypeEnum,
    transporterId: ?TransporterIdModelType,
    skills: Array<SkillIdModelType>,
    allowMultiTrip: boolean,
    limits: ?RiderPersonaLimitsModelType,
    customProperties: { [key: string]: string },
    enableLotr: boolean,
    enableAuth: boolean,
    enablePjp: boolean,
    volumeUnit: VolumeUnitEnumType, // This field is for FE only
    version: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class RiderPersona extends ImmutableModel {
    static fromJS(json: RiderPersonaModelType): RiderPersona {
        const state: Object = Object.assign({}, json);
        state.transporterId = state.transporterId
            ? TransporterId.fromJS(state.transporterId)
            : state.transporterId;
        state.skills = Immutable.List(state.skills).map((item) =>
            SkillId.fromJS(item)
        );
        state.limits = state.limits
            ? RiderPersonaLimits.fromJS(state.limits)
            : state.limits;
        state.customProperties = Immutable.Map(state.customProperties);
        return new this(Immutable.Map(state));
    }

    toJS(): RiderPersonaModelType {
        return {
            clientId: this.clientId,
            id: this.id,
            code: this.code,
            name: this.name,
            status: this.status,
            type: this.type,
            fleetType: this.fleetType,
            transporterId: this.transporterId
                ? this.transporterId.toJS()
                : this.transporterId,
            skills: this.skills.toArray().map((item) => item.toJS()),
            allowMultiTrip: this.allowMultiTrip,
            limits: this.limits ? this.limits.toJS() : this.limits,
            customProperties: this.customProperties.toObject(),
            enableLotr: this.enableLotr,
            enableAuth: this.enableAuth,
            enablePjp: this.enablePjp,
            volumeUnit: this.volumeUnit,
            version: this.version,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get code(): string {
        return this._state.get('code');
    }

    setCode(code: string): this {
        return this.clone(this._state.set('code', code));
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get status(): StatusEnum {
        return this._state.get('status');
    }

    setStatus(status: StatusEnum): this {
        return this.clone(this._state.set('status', status));
    }

    get type(): RiderPersonaTypeEnum {
        return this._state.get('type');
    }

    setType(type: RiderPersonaTypeEnum): this {
        return this.clone(this._state.set('type', type));
    }

    get fleetType(): FleetTypeEnum {
        return this._state.get('fleetType');
    }

    setFleetType(fleetType: FleetTypeEnum): this {
        return this.clone(this._state.set('fleetType', fleetType));
    }

    get transporterId(): ?TransporterId {
        return this._state.get('transporterId');
    }

    setTransporterId(transporterId: ?TransporterId): this {
        return this.clone(this._state.set('transporterId', transporterId));
    }

    get skills(): Immutable.List<SkillId> {
        return this._state.get('skills');
    }

    setSkills(skills: Immutable.List<SkillId>): this {
        return this.clone(this._state.set('skills', skills));
    }

    get allowMultiTrip(): boolean {
        return this._state.get('allowMultiTrip');
    }

    setAllowMultiTrip(allowMultiTrip: boolean): this {
        return this.clone(this._state.set('allowMultiTrip', allowMultiTrip));
    }

    get limits(): ?RiderPersonaLimits {
        return this._state.get('limits');
    }

    setLimits(limits: ?RiderPersonaLimits): this {
        return this.clone(this._state.set('limits', limits));
    }

    get customProperties(): Immutable.Map<string, string> {
        return this._state.get('customProperties');
    }

    setCustomProperties(customProperties: Immutable.Map<string, string>): this {
        return this.clone(
            this._state.set('customProperties', customProperties)
        );
    }

    get enableLotr(): boolean {
        return this._state.get('enableLotr');
    }

    setEnableLotr(enableLotr: boolean): this {
        return this.clone(this._state.set('enableLotr', enableLotr));
    }

    get enableAuth(): boolean {
        return this._state.get('enableAuth');
    }

    setEnableAuth(enableAuth: boolean): this {
        return this.clone(this._state.set('enableAuth', enableAuth));
    }

    get enablePjp(): boolean {
        return this._state.get('enablePjp');
    }

    setEnablePjp(enablePjp: boolean): this {
        return this.clone(this._state.set('enablePjp', enablePjp));
    }

    get volumeUnit(): VolumeUnitEnumType {
        return this._state.get('volumeUnit');
    }

    setVolumeUnit(volumeUnit: VolumeUnitEnumType): this {
        return this.clone(this._state.set('volumeUnit', volumeUnit));
    }

    get version(): number {
        return this._state.get('version');
    }

    setVersion(version: number): this {
        return this.clone(this._state.set('version', version));
    }
}
