//@flow
import * as Immutable from 'immutable';

import { PhoneNumber } from 'models/flow/PhoneNumber';
import type { PhoneNumberModelType } from 'models/flow/PhoneNumber';

class InternalPhoneNumber extends PhoneNumber {
    static fromJS(json: PhoneNumberModelType): InternalPhoneNumber {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalPhoneNumber as PhoneNumber };
