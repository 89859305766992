// @flow
import React, { type Node } from 'react';
import { Box, Flex } from '@locus-taxy/neo-ui';

type StyleType = {
    [key: string]: string | number | Object,
};

type Props = {
    isFlex?: boolean,
    children: Node,
    mobileSx?: StyleType,
    tabletSx?: StyleType,
    noDesktop?: boolean,
    noMobile?: boolean,
};

const SCREENS = Object.freeze({
    MOBILE: '420px',
    TABLET_START: '421px',
    TABLET_END: '768px',
    DESKTOP_START: '769px',
});

const ResponsiveBox = ({
    isFlex,
    mobileSx = {},
    tabletSx = {},
    children,
    noDesktop,
    noMobile,
    ...props
}: Props): Node => {
    const Component = isFlex ? Flex : Box;
    const desktopSx = noDesktop ? { display: 'none' } : {};

    const mediaQueries = {
        [`@media screen and (max-width: ${SCREENS.MOBILE})`]: noMobile
            ? { display: 'none' }
            : mobileSx,
        [`@media screen and (min-width: ${SCREENS.TABLET_START}) and (max-width: ${SCREENS.TABLET_END})`]:
            tabletSx,
        [`@media screen and (min-width: ${SCREENS.DESKTOP_START})`]: desktopSx,
    };

    return (
        <Component {...props} sx={mediaQueries}>
            {children}
        </Component>
    );
};

export default ResponsiveBox;
