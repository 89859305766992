// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type StructuredAddressModelType = {
    id: string,
    placeName: string,
    localityName: string,
    formattedAddress: string,
    subLocalityName: string,
    pincode: string,
    city: string,
    state: string,
    countryCode: string,
    locationType: string,
    placeHash: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class StructuredAddress extends ImmutableModel {
    static fromJS(json: StructuredAddressModelType): StructuredAddress {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): StructuredAddressModelType {
        return {
            id: this.id,
            placeName: this.placeName,
            localityName: this.localityName,
            formattedAddress: this.formattedAddress,
            subLocalityName: this.subLocalityName,
            pincode: this.pincode,
            city: this.city,
            state: this.state,
            countryCode: this.countryCode,
            locationType: this.locationType,
            placeHash: this.placeHash,
        };
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get placeName(): string {
        return this._state.get('placeName');
    }

    setPlaceName(placeName: string): this {
        return this.clone(this._state.set('placeName', placeName));
    }

    get localityName(): string {
        return this._state.get('localityName');
    }

    setLocalityName(localityName: string): this {
        return this.clone(this._state.set('localityName', localityName));
    }

    get formattedAddress(): string {
        return this._state.get('formattedAddress');
    }

    setFormattedAddress(formattedAddress: string): this {
        return this.clone(
            this._state.set('formattedAddress', formattedAddress)
        );
    }

    get subLocalityName(): string {
        return this._state.get('subLocalityName');
    }

    setSubLocalityName(subLocalityName: string): this {
        return this.clone(this._state.set('subLocalityName', subLocalityName));
    }

    get pincode(): string {
        return this._state.get('pincode');
    }

    setPincode(pincode: string): this {
        return this.clone(this._state.set('pincode', pincode));
    }

    get city(): string {
        return this._state.get('city');
    }

    setCity(city: string): this {
        return this.clone(this._state.set('city', city));
    }

    get state(): string {
        return this._state.get('state');
    }

    setState(state: string): this {
        return this.clone(this._state.set('state', state));
    }

    get countryCode(): string {
        return this._state.get('countryCode');
    }

    setCountryCode(countryCode: string): this {
        return this.clone(this._state.set('countryCode', countryCode));
    }

    get locationType(): string {
        return this._state.get('locationType');
    }

    setLocationType(locationType: string): this {
        return this.clone(this._state.set('locationType', locationType));
    }

    get placeHash(): string {
        return this._state.get('placeHash');
    }

    setPlaceHash(placeHash: string): this {
        return this.clone(this._state.set('placeHash', placeHash));
    }
}
