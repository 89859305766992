// @flow
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '@locus-taxy/auth-react';
import { clearClientId } from 'modules/clientId';
import APIErrorScreen from 'components/APIErrorScreen';

const parseError = (
    error: $FixMe
): { code?: string, message: string, params: any } => {
    try {
        const { error: code, message, params } = JSON.parse(error);
        return {
            code,
            message,
            params,
        };
    } catch (err) {
        return {
            message: error,
            params: {},
        };
    }
};

type Props = {
    error: { message: string, error_description: string },
};

const AuthError = ({ error }: Props): React.Node => {
    const navigate = useNavigate();
    const handleLogout = () => {
        clearClientId();
        AuthService.logout({
            returnTo: window.location.origin,
        });
    };

    const goHome = () => {
        navigate('/');
    };

    const parsedErrorDesc = parseError(error.error_description);

    if (parsedErrorDesc.code === 'credentials_expired') {
        // NOTE: not using router history, instead href
        // to also reload the page to reset the auth error state
        window.location.href = `/change-password?userId=${parsedErrorDesc.params.userId}`;
        return null;
    }

    return (
        <APIErrorScreen
            onActionClick={goHome}
            actionLabel="Try Again"
            onSecondaryActionClick={handleLogout}
            secondaryActionLabel="Sign Out"
            message={error.message}
            subtitle={error.error_description}
        />
    );
};

export default AuthError;
