// @flow
import React, { type Node, useEffect } from 'react';
import TourCard from './TourCard';
import { OperationsTour } from 'models/internal/OperationsTour';
import PageLoader from 'components/common/PageLoader';
import VirtualInfiniteList from 'components/common/VirtualInfiniteList';
import VirtualInfiniteVariableList from 'components/common/VirtualInfiniteVariableList';
import { PaginationInfo } from 'models/internal/PaginationInfo';
import { range as _range } from 'lodash';
import ResponsiveBox from 'components/common/ResponsiveBox';
import { TourStatuses } from 'constants/TourStatusEnums';

export const PageOffsetNumber = 10;

type Props = {
    tours: Array<OperationsTour>,
    isLoading: boolean,
    refetchTours: () => void,
    paginationInfo: PaginationInfo,
    onPageChange: (currentPage: number) => void,
};

const DEFAULT_CARD_HEIGHT_DESKTOP = 200;
const DEFAULT_CARD_HEIGHT_MOBILE = 320;
const DEFAULT_CARD_HEIGHT_MOBILE_LONG = 440;

const LOADING = 'LOADING';
const LOADED = 'LOADED';

type ItemStatusMap = { [key: number]: 'LOADING' | 'LOADED' };

let itemStatusMap: ItemStatusMap = {};

const Row = ({
    index,
    style,
    data,
}: {
    index: number,
    style: Object,
    data: {
        tours: Array<OperationsTour>,
        refetchTours: () => void,
        itemStatusMap: ItemStatusMap,
        isMobile?: boolean,
    },
}) => {
    const { tours, refetchTours, itemStatusMap, isMobile } = data;
    const tour = tours[index];
    if (itemStatusMap[index] === LOADED && tour) {
        return (
            <div style={style}>
                <ResponsiveBox
                    width="99%"
                    pb={4}
                    backgroundColor="white"
                    mobileSx={{
                        padding: '16px',
                        width: '100%',
                    }}
                >
                    <TourCard
                        hideToggle={isMobile}
                        tour={tour}
                        key={tour.id}
                        refetchTours={refetchTours}
                    />
                </ResponsiveBox>
            </div>
        );
    }

    return <div style={style}>Loading ...{index}</div>;
};

const TourList = ({
    isLoading,
    tours,
    refetchTours,
    paginationInfo,
    onPageChange,
}: Props): Node => {
    useEffect(() => {
        if (tours?.length) {
            tours.forEach((_, index) => {
                itemStatusMap[index] = LOADED;
            });
        }
    }, [tours]);

    useEffect(() => {
        return () => {
            // reset map
            itemStatusMap = {};
        };
    }, []);

    if (isLoading) {
        return <PageLoader />;
    }

    const { currentPage, total } = paginationInfo;
    const isItemLoaded = (index: number) => {
        return itemStatusMap[index] === LOADED;
    };
    const loadMoreItems = (startIndex: number, endIndex: number) => {
        _range(0, endIndex).forEach((index: number) => {
            if (!itemStatusMap[index]) {
                itemStatusMap[index] = LOADING;
            }
        });

        const currentDataLength = currentPage * PageOffsetNumber;
        if (endIndex >= currentDataLength) {
            onPageChange(currentPage + 1);
        }
    };

    const commonProps = {
        itemData: {
            tours,
            refetchTours,
            itemStatusMap,
        },
        itemCount: total,
        isItemLoaded,
        loadMoreItems,
        minimumBatchSize: PageOffsetNumber,
        threshold: 7,
    };

    const itemSizes = tours.map(({ transporterTourStatus }) => {
        return transporterTourStatus === TourStatuses.READY_TO_DISPATCH
            ? DEFAULT_CARD_HEIGHT_MOBILE_LONG
            : DEFAULT_CARD_HEIGHT_MOBILE;
    });

    return (
        <>
            <ResponsiveBox noMobile width="100%">
                <VirtualInfiniteList
                    {...commonProps}
                    itemSize={DEFAULT_CARD_HEIGHT_DESKTOP}
                >
                    {Row}
                </VirtualInfiniteList>
            </ResponsiveBox>
            <ResponsiveBox noDesktop width="100%">
                <VirtualInfiniteVariableList
                    {...commonProps}
                    itemData={{ ...commonProps.itemData, isMobile: true }}
                    itemSize={(index: number) => itemSizes[index]}
                >
                    {Row}
                </VirtualInfiniteVariableList>
            </ResponsiveBox>
        </>
    );
};

export default TourList;
