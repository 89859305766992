// @flow
import React, { type Node } from 'react';
import { Stack, RadioButtons, Box } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import ChecklistHeader from './ChecklistHeader';
import type { ExtraProps } from './helper';

type Props = {
    checklistConfig: ChecklistItem,
    onChange: (value: boolean) => void,
    value: boolean,
    extraProps?: ExtraProps,
};

const options = [
    {
        value: 'Yes',
        key: 'yes',
    },
    {
        value: 'No',
        key: 'no',
    },
];

const BooleanSelector = ({ checklistConfig, onChange, value }: Props): Node => {
    const { item, optional } = checklistConfig;

    const handleChange = ({ key }: { key: string, value: string }) => {
        onChange(key === 'yes');
    };

    return (
        <Stack space="small">
            <ChecklistHeader optional={optional}>{item} </ChecklistHeader>
            <Box p={4} pt={0}>
                <RadioButtons
                    value={value ? 'yes' : 'no'}
                    label=""
                    options={options}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    );
};

export default BooleanSelector;
