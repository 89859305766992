//@flow
import * as Immutable from 'immutable';

import { TransporterId } from 'models/flow/TransporterId';
import type { TransporterIdModelType } from 'models/flow/TransporterId';

class InternalTransporterId extends TransporterId {
    static fromJS(json: TransporterIdModelType): InternalTransporterId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalTransporterId as TransporterId };
