//@flow
import * as Immutable from 'immutable';

import { ChecklistItem } from 'models/flow/ChecklistItem';
import type { ChecklistItemModelType } from 'models/flow/ChecklistItem';

import { ChecklistItemTypes } from 'constants/checklist';

class InternalChecklistItem extends ChecklistItem {
    static fromJS(json: ChecklistItemModelType): InternalChecklistItem {
        const state: Object = Object.assign({}, json);
        state.possibleValues = Immutable.List(state.possibleValues);
        state.allowedValues = Immutable.List(state.allowedValues);
        return new this(Immutable.Map(state));
    }
}

export { InternalChecklistItem as ChecklistItem };
