// @flow
import { useState, useMemo } from 'react';
import { debounce as _debounce } from 'lodash';

export const DEFAULT_DEBOUNCE_TIME = 400;

type PropTypes = {
    defaultValue?: string,
    debounceTime?: number,
};

type ReturnType = {
    queryString: string,
    setQueryString: (queryString: string) => void,
    debouncedSetQueryString: (queryString: string) => void,
};

const useDebouncedQueryString = ({
    defaultValue = '',
    debounceTime = DEFAULT_DEBOUNCE_TIME,
}: PropTypes): ReturnType => {
    const [queryString, setQueryString] = useState(defaultValue);

    const debouncedSetQueryString = useMemo(
        () => _debounce(setQueryString, debounceTime),
        [debounceTime]
    );

    return { queryString, setQueryString, debouncedSetQueryString };
};

export default useDebouncedQueryString;
