// @flow
import React, { type Node, type AbstractComponent } from 'react';
import { Flex } from '@locus-taxy/neo-ui';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

const MINIMUM_BATCH_SIZE = 10;
const THRESHOLD = 8;

type Props = {
    itemData: Object,
    itemCount: number,
    itemSize: (index: number) => number,
    isItemLoaded?: (index: number) => boolean,
    loadMoreItems?: (startIndex: number, endIndex: number) => void,
    minimumBatchSize?: number,
    threshold?: number,
    children: AbstractComponent<any>,
};

const VirtualInfiniteVariableList = ({
    itemData,
    itemCount,
    itemSize,
    minimumBatchSize = MINIMUM_BATCH_SIZE,
    threshold = THRESHOLD,
    isItemLoaded,
    loadMoreItems,
    children,
}: Props): Node => {
    return (
        <Flex height="100%" width="100%">
            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={itemCount}
                            loadMoreItems={loadMoreItems}
                            minimumBatchSize={minimumBatchSize}
                            threshold={threshold}
                        >
                            {({ onItemsRendered, ref }) => (
                                <List
                                    itemData={itemData}
                                    height={height}
                                    width={width}
                                    itemCount={itemCount}
                                    itemSize={itemSize}
                                    ref={ref}
                                    onItemsRendered={onItemsRendered}
                                >
                                    {children}
                                </List>
                            )}
                        </InfiniteLoader>
                    );
                }}
            </AutoSizer>
        </Flex>
    );
};

export default VirtualInfiniteVariableList;
