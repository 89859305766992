// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type TransporterIdModelType = {
    clientId: string,
    transporterId: string,
    version: ?number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TransporterId extends ImmutableModel {
    static fromJS(json: TransporterIdModelType): TransporterId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): TransporterIdModelType {
        return {
            clientId: this.clientId,
            transporterId: this.transporterId,
            version: this.version,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get transporterId(): string {
        return this._state.get('transporterId');
    }

    setTransporterId(transporterId: string): this {
        return this.clone(this._state.set('transporterId', transporterId));
    }

    get version(): ?number {
        return this._state.get('version');
    }

    setVersion(version: ?number): this {
        return this.clone(this._state.set('version', version));
    }
}
