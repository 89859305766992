// @flow
import React, { type Node } from 'react';
import { Flex, Icon } from '@locus-taxy/neo-ui';

export const WithIcon = ({
    icon,
    children,
}: {
    icon: string,
    children: Node,
}): Node => {
    return (
        <Flex alignItems="center" minWidth="150px" mr={2}>
            <Flex alignItems="center" mr={1}>
                <Icon icon={icon} color="grey.600" />
            </Flex>
            {children}
        </Flex>
    );
};

export default WithIcon;
