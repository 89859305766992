// @flow
import React, { type Node } from 'react';
import { Select, Box } from '@locus-taxy/neo-ui';
import { useGetRiders, useDebouncedQueryString } from 'hooks';
import { useClientId } from 'modules/clientId';
import { STATUSES } from 'constants';
import { getBulkGetRequest } from './helper';

type Values = ?string | Array<string> | { value: string, label: string };
type Props = {
    label: string,
    value: Values,
    onSelect: (value: Values) => void,
    vehicleModelId?: string,
    disabled?: boolean,
    width?: string,
};

const RiderSelect = ({
    label,
    value,
    onSelect,
    disabled = false,
    width = '220px',
}: Props): Node => {
    const { queryString, setQueryString, debouncedSetQueryString } =
        useDebouncedQueryString({});

    const clientId = useClientId();

    const { data: riderList, isFetching } = useGetRiders({
        clientId,
        requestBody: getBulkGetRequest({
            queryString,
        }),
    });

    const riderOptions = riderList
        ? riderList
              .filter((rider) => rider.status === STATUSES.ACTIVE)
              .map((rider) => ({
                  ...rider.toJS(),
                  value: rider.id,
                  label: rider.name,
                  meta: rider.id,
              }))
        : [];

    const handleMenuClose = () => setQueryString('');

    return (
        <Box width={width} mb={3}>
            <Select
                label={label}
                size="small"
                fullWidth
                options={riderOptions}
                value={value}
                onChange={onSelect}
                onInputChange={debouncedSetQueryString}
                onMenuClose={handleMenuClose}
                isLoading={isFetching}
                disabled={disabled}
            />
        </Box>
    );
};

export default RiderSelect;
