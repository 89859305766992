//@flow
import * as Immutable from 'immutable';

import { TourVehicle } from 'models/flow/TourVehicle';
import type { TourVehicleModelType } from 'models/flow/TourVehicle';
import { VehicleModel } from './VehicleModel';
import { ResourceLimit } from './ResourceLimit';
import { TourVehicleModel } from './TourVehicleModel';

class InternalTourVehicle extends TourVehicle {
    static fromJS(json: TourVehicleModelType): InternalTourVehicle {
        const state: Object = Object.assign({}, json);
        state.vehicleModel = TourVehicleModel.fromJS(state.vehicleModel);
        state.resourceLimits = Immutable.List(state.resourceLimits).map(
            (item) => ResourceLimit.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get vehicleModel(): TourVehicleModel {
        return this._state.get('vehicleModel');
    }

    get resourceLimits(): Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }
}

export { InternalTourVehicle as TourVehicle };
