// @flow
import axios from 'axios';
import EnvConfig from 'config';
import { ErrorUtils } from 'utils';
import { AuthService } from 'modules/auth';
import { clearClientId } from 'modules/clientId';

export const dashInstance: any = axios.create({
    baseURL: EnvConfig.dashEndPoint,
});

export const apiInstance: any = axios.create({
    baseURL: EnvConfig.apiEndPoint,
});

export const platformInstance: any = axios.create({
    baseURL: EnvConfig.platformEndPoint,
});

export const tdmsInstance: any = axios.create({
    baseURL: EnvConfig.tdmsEndpoint,
});

export const omsInstance: any = axios.create({
    baseURL: EnvConfig.omsEndpoint,
});

const allInstances = [
    dashInstance,
    apiInstance,
    tdmsInstance,
    platformInstance,
    omsInstance,
];

allInstances.map((instance) =>
    instance.interceptors.request.use(
        (config) => {
            const authHeader = AuthService.getAuthHeader();
            if (authHeader) {
                config.headers[authHeader.key] = authHeader.value;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    )
);

allInstances.map((instance) =>
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 401) {
                clearClientId();
                AuthService.logout({ returnTo: window.location.origin });
            }
            const errorMessage = ErrorUtils.extractErrorMessage(error);
            throw new Error(errorMessage);
        }
    )
);
