//@flow
import * as Immutable from 'immutable';

import { TourVisit } from 'models/flow/TourVisit';
import type { TourVisitModelType } from 'models/flow/TourVisit';
import { MinimalVisitLocation } from './MinimalVisitLocation';

class InternalTourVisit extends TourVisit {
    static fromJS(json: TourVisitModelType): InternalTourVisit {
        const state: Object = Object.assign({}, json);
        state.visitLocation = MinimalVisitLocation.fromJS(state.visitLocation);
        return new this(Immutable.Map(state));
    }

    get visitLocation(): MinimalVisitLocation {
        return this._state.get('visitLocation');
    }
}

export { InternalTourVisit as TourVisit };
