// @flow
import * as React from 'react';
import { Button } from '@locus-taxy/neo-ui';
import { useAuth } from '@locus-taxy/auth-react';
import { clearClientId } from 'modules/clientId';
import ResponsiveBox from 'components/common/ResponsiveBox';

const LogoutButton = (): React.Node => {
    const auth = useAuth();

    const handleLogout = () => {
        // TODO: Remove this once we have a better way to clear the client id
        // Need to add some event in the auth sdk to trigger logout event
        clearClientId();
        auth.logout({ returnTo: window.location.origin });
    };

    return (
        <>
            <ResponsiveBox noMobile>
                <Button
                    appearance="solid"
                    palette="primary"
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            </ResponsiveBox>
            <ResponsiveBox noDesktop>
                <Button
                    palette="primary"
                    shape="round"
                    onClick={handleLogout}
                    icon="logout"
                    color="white"
                />
            </ResponsiveBox>
        </>
    );
};

export default LogoutButton;
