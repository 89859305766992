// @flow
import React, { type Node, useEffect, useState } from 'react';
import { Columns, Button, Flex, Stack } from '@locus-taxy/neo-ui';
import VehicleSelect from 'components/common/VehicleSelect';
import RiderSelect from 'components/common/RiderSelect';
import { useNavigate } from 'react-router-dom';
import { TourStatuses } from 'constants';
import { OperationsTour } from 'models/internal/OperationsTour';
import { Rider } from 'models/internal/Rider';
import { Vehicle } from 'models/internal/Vehicle';
import { useUpdateAssignment, type AssignmentBody } from 'hooks/api';
import useClientId from 'modules/clientId/useClientId';
import { useError } from 'hooks';
import ResponsiveBox from 'components/common/ResponsiveBox';

type Props = {
    tour: OperationsTour,
    refetchTours: () => void,
};

const ASSIGNMENT_STATUSES = [TourStatuses.READY_TO_DISPATCH];

const TourCardBottomActions = ({ tour, refetchTours }: Props): Node => {
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedRider, setSelectedRider] = useState('');
    const [selectedVehicleModelId, setSelectedVehicleModelId] = useState('');

    const clientId = useClientId();
    const navigate = useNavigate();
    const { onError } = useError();

    const { id: operationTourId, status: tourStatus, assignmentDetails } = tour;
    const { vehicleId, riderId, vehicleModelId, transporterId } =
        assignmentDetails;

    const getAssignmentbody = (): AssignmentBody => {
        return {
            riderId: selectedRider,
            vehicleModelId: selectedVehicleModelId,
            transporterId: transporterId || null,
            vehicleId: selectedVehicle,
        };
    };
    const assignmentMutate = useUpdateAssignment();

    const shouldShowAssignments = ASSIGNMENT_STATUSES.includes(tourStatus);

    const handleRiderSelection = (rider: Rider) =>
        setSelectedRider(rider?.id || '');
    const handleVehicleSelection = (vehicle: Vehicle) => {
        setSelectedVehicle(vehicle?.id || '');
        setSelectedVehicleModelId(vehicle?.vehicleModelId.modelId || '');
    };

    const handleTourSelect = () => {
        navigate(`tour-details/${operationTourId}`);
    };

    // auto-populate rider, vehicle and vehicleModel
    useEffect(() => {
        if (!selectedRider && riderId) {
            setSelectedRider(riderId);
        }
    }, [riderId]);

    useEffect(() => {
        if (!selectedVehicle && vehicleId) {
            setSelectedVehicle(vehicleId);
        }
    }, [vehicleId]);

    useEffect(() => {
        if (!selectedVehicleModelId && vehicleModelId) {
            setSelectedVehicleModelId(vehicleModelId);
        }
    }, [vehicleModelId]);

    const handleAssignment = () => {
        const assignmentBody = getAssignmentbody();
        assignmentMutate.mutate(
            {
                clientId,
                tourId: operationTourId,
                body: assignmentBody,
            },
            {
                onSuccess: () => {
                    refetchTours();
                },
                onError,
            }
        );
    };

    const isAssignmentDisabled =
        vehicleId === selectedVehicle && riderId === selectedRider;

    const getDesktopComponent = () => {
        return (
            <Flex ml={4}>
                <Columns align="center" space="small" alignY="center">
                    {shouldShowAssignments && (
                        <>
                            <RiderSelect
                                label="Select Driver"
                                value={selectedRider}
                                onSelect={handleRiderSelection}
                            />
                            <VehicleSelect
                                label="Select Vehicle"
                                value={selectedVehicle}
                                vehicleModelId={vehicleModelId || ''}
                                onSelect={handleVehicleSelection}
                            />
                            <Button
                                appearance="outline"
                                palette="primary"
                                onClick={handleAssignment}
                                isLoading={assignmentMutate.isLoading}
                                disabled={isAssignmentDisabled}
                            >
                                Assign
                            </Button>
                        </>
                    )}

                    <Flex flexShrink="0">
                        <Button
                            appearance="outline"
                            palette="primary"
                            onClick={handleTourSelect}
                        >
                            View Tour
                        </Button>
                    </Flex>
                </Columns>
            </Flex>
        );
    };
    const getMobileComponent = () => {
        return (
            <Stack align="center" space="small" alignY="center">
                {shouldShowAssignments && (
                    <>
                        <RiderSelect
                            label="Select Driver"
                            value={selectedRider}
                            onSelect={handleRiderSelection}
                            width="100%"
                        />
                        <VehicleSelect
                            label="Select Vehicle"
                            value={selectedVehicle}
                            vehicleModelId={vehicleModelId || ''}
                            onSelect={handleVehicleSelection}
                            width="100%"
                        />
                    </>
                )}

                <Flex width="100%" sx={{ gap: '10px' }}>
                    {shouldShowAssignments && (
                        <Button
                            appearance="outline"
                            palette="primary"
                            onClick={handleAssignment}
                            isLoading={assignmentMutate.isLoading}
                            disabled={isAssignmentDisabled}
                            width="100%"
                        >
                            Assign
                        </Button>
                    )}
                    <Button
                        appearance="outline"
                        palette="primary"
                        onClick={handleTourSelect}
                        width="100%"
                    >
                        View Tour
                    </Button>
                </Flex>
            </Stack>
        );
    };

    return (
        <>
            <ResponsiveBox noMobile>{getDesktopComponent()}</ResponsiveBox>
            <ResponsiveBox noDesktop width="100%">
                {getMobileComponent()}
            </ResponsiveBox>
        </>
    );
};

export default TourCardBottomActions;
