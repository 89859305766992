//@flow
import * as Immutable from 'immutable';

import { RiderPersonaResourceLimit } from 'models/flow/RiderPersonaResourceLimit';
import type { RiderPersonaResourceLimitModelType } from 'models/flow/RiderPersonaResourceLimit';

class InternalRiderPersonaResourceLimit extends RiderPersonaResourceLimit {
    static fromJS(
        json: RiderPersonaResourceLimitModelType
    ): InternalRiderPersonaResourceLimit {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalRiderPersonaResourceLimit as RiderPersonaResourceLimit };
