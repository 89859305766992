//@flow
import * as Immutable from 'immutable';

import { TourVehicleModel } from 'models/flow/TourVehicleModel';
import type { TourVehicleModelModelType } from 'models/flow/TourVehicleModel';
import { Limits } from './Limits';
import { VolumeLimit } from './VolumeLimit';
import { Skill } from './Skill';

class InternalTourVehicleModel extends TourVehicleModel {
    static fromJS(json: TourVehicleModelModelType): InternalTourVehicleModel {
        const state: Object = Object.assign({}, json);
        state.limits = Limits.fromJS(state.limits);
        state.volume = VolumeLimit.fromJS(state.volume);
        return new this(Immutable.Map(state));
    }

    get limits(): Limits {
        return this._state.get('limits');
    }

    get volume(): VolumeLimit {
        return this._state.get('volume');
    }
}

export { InternalTourVehicleModel as TourVehicleModel };
