// @flow
import React, { type Node } from 'react';
import { Stack, Box } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import ChecklistHeader from 'components/dashboard/tours/ChecklistItems/ChecklistHeader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import type { ExtraProps } from 'components/dashboard/tours/ChecklistItems/helper';
import { getAPIDate } from 'utils/timeUtils';

import './styles.css';

type Props = {
    checklistConfig: ChecklistItem,
    onChange: (value: string) => void,
    value: string,
    extraProps?: ExtraProps,
};

const DateSelector = ({ checklistConfig, onChange, value }: Props): Node => {
    const { item, optional } = checklistConfig;

    return (
        <Stack space="small">
            <ChecklistHeader optional={optional}>{item} </ChecklistHeader>
            <Box>
                <DatePicker
                    label=""
                    value={value}
                    onChange={(date: Date) => onChange(getAPIDate(date))}
                    fullWidth
                    format="DD/MM/YYYY"
                    sx={{
                        width: '100%',
                        background: 'white',
                        '& input': {
                            padding: '6px 12px',
                        },
                        '& .MuiButtonBase-root': {
                            padding: '4px',
                        },
                    }}
                />
            </Box>
        </Stack>
    );
};

export default DateSelector;
