// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import { tdmsInstance } from 'middleware/api';

type Props = {
    clientId: string,
    tourId: string,
};

const useTourCompletion = (): MutationResult<any> => {
    return useMutation(({ clientId, tourId }: Props) => {
        return tdmsInstance.post(
            `/client/${clientId}/tour/${tourId}/complete`,
            {}
        );
    });
};

export default useTourCompletion;
