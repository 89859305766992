//@flow
import * as Immutable from 'immutable';

import { Rider } from 'models/flow/Rider';
import type { RiderModelType } from 'models/flow/Rider';
import { TeamId } from './TeamId';
import { PhoneNumber } from './PhoneNumber';
import { RiderPersona } from './MasterRiderPersona';
import { RiderPersonaId } from './RiderPersonaId';
import { Limits } from './Limits';
import { SkillId } from './SkillId';

class InternalRider extends Rider {
    static fromJS(json: RiderModelType): InternalRider {
        const state: Object = Object.assign({}, json);
        state.customProperties = Immutable.Map(state.customProperties);
        state.phoneNumber = PhoneNumber.fromJS(state.phoneNumber);
        state.teams = Immutable.List(state.teams).map((item) =>
            TeamId.fromJS(item)
        );
        state.riderPersona = state.riderPersona
            ? RiderPersona.fromJS(state.riderPersona)
            : state.riderPersona;
        state.riderPersonaId = state.riderPersonaId
            ? RiderPersonaId.fromJS(state.riderPersonaId)
            : state.riderPersonaId;
        state.skills = Immutable.List(state.skills).map((item) =>
            SkillId.fromJS(item)
        );
        state.limits = state.limits
            ? Limits.fromJS(state.limits)
            : state.limits;
        return new this(Immutable.Map(state));
    }

    get phoneNumber(): PhoneNumber {
        return this._state.get('phoneNumber');
    }

    get teams(): Immutable.List<TeamId> {
        return this._state.get('teams');
    }

    get riderPersona(): ?RiderPersona {
        return this._state.get('riderPersona');
    }

    get riderPersonaId(): ?RiderPersonaId {
        return this._state.get('riderPersonaId');
    }

    get skills(): Immutable.List<SkillId> {
        return this._state.get('skills');
    }

    get limits(): ?Limits {
        return this._state.get('limits');
    }
}

export { InternalRider as Rider };
