// @flow
import React, { type Node } from 'react';
import { Box, Flex } from '@locus-taxy/neo-ui';

type Props = {
    width?: string,
    onClick: () => void,
    color?: 'white' | 'black',
};

const Hamburger = ({
    width = '18px',
    onClick,
    color = 'white',
}: Props): Node => {
    return (
        <Flex
            width={width}
            onClick={onClick}
            flexDirection="column"
            sx={{ gap: '3px' }}
        >
            {[1, 2, 3].map((_, index) => (
                <Box
                    width="100%"
                    height="2px"
                    backgroundColor={color}
                    key={index}
                />
            ))}
        </Flex>
    );
};

export default Hamburger;
