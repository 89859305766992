// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

import type { ChecklistItemModelType } from './ChecklistItem';
import { ChecklistItem } from './ChecklistItem';

export type ChecklistModelType = {
    customerCompleted: Array<ChecklistItemModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Checklist extends ImmutableModel {
    static fromJS(json: ChecklistModelType): Checklist {
        const state: Object = Object.assign({}, json);
        state.customerCompleted = Immutable.List(state.customerCompleted).map(
            (item) => ChecklistItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): ChecklistModelType {
        return {
            customerCompleted: this.customerCompleted
                .toArray()
                .map((item) => item.toJS()),
        };
    }

    get customerCompleted(): Immutable.List<ChecklistItem> {
        return this._state.get('customerCompleted');
    }

    setCustomerCompleted(
        customerCompleted: Immutable.List<ChecklistItem>
    ): this {
        return this.clone(
            this._state.set('customerCompleted', customerCompleted)
        );
    }
}
