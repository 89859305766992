// @flow
import { isValidAPIDate } from 'utils/timeUtils';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDashStore } from 'store';

const useSelectedDate = (): ({
    selectedDate: string,
    setSelectedDate: (date: string) => void,
}) => {
    const [params, setParams] = useSearchParams();
    const { selectedDate, setSelectedDate } = useDashStore();

    // set date from url
    useEffect(() => {
        const queryDate = params.get('date');
        const sanitizedDate = isValidAPIDate(queryDate) ? queryDate : null;
        if (sanitizedDate) {
            setSelectedDate(sanitizedDate);
        }
    }, []);

    // set url on date change
    useEffect(() => {
        if (selectedDate) {
            params.set('date', selectedDate);
            setParams(params);
        }
    }, [selectedDate, params, setParams]);

    return { selectedDate, setSelectedDate };
};

export default useSelectedDate;
