// @flow
import EnvConfig from 'env-config';

const getConfig = (): any => {
    if (EnvConfig.env !== 'devo') {
        return EnvConfig;
    }

    const { devo } = EnvConfig;

    // NOTE: in devo.json endPoint is template with {devo} as placeholder
    const dashEndPoint = EnvConfig.dashEndPoint.replace('{devo}', devo);
    const apiEndPoint = EnvConfig.apiEndPoint.replace('{devo}', devo);
    const tdmsEndpoint = EnvConfig.tdmsEndpoint.replace('{devo}', devo);
    const omsEndpoint = EnvConfig.omsEndpoint.replace('{devo}', devo);

    const getPlatformDevo = (devo) => {
        switch (devo) {
            case 'devo':
                return 'devo-1';
            default:
                return devo;
        }
    };
    const platformEndPoint = EnvConfig.platformEndPoint.replace(
        '{devo}',
        getPlatformDevo(devo)
    );

    return {
        ...EnvConfig,
        devo,
        dashEndPoint,
        apiEndPoint,
        platformEndPoint,
        tdmsEndpoint,
        omsEndpoint,
    };
};

export default (getConfig(): typeof EnvConfig);
