// @flow
import { TourStatuses } from 'constants';
import { createFilter } from 'components/common/helper';
import { BulkGetRequest } from 'models/internal/BulkGetRequest';
import { FilterOperation } from 'models/internal/Filter';

type Props = {
    date: string,
    status: ?$Values<typeof TourStatuses> | Array<string>,
    currentPage?: number,
    pageOffset?: number,
};

export const getBulkGetRequest = ({
    date,
    status,
    currentPage,
    pageOffset,
}: Props): BulkGetRequest => {
    const filters = [];
    const dateFilter = createFilter({
        name: 'tourDate',
        operation: FilterOperation.Equals,
        values: [],
        value: date,
        allowEmptyOrNull: false,
    }).toJS();

    filters.push(dateFilter);

    if (status) {
        filters.push(
            createFilter({
                name: 'transporterTourStatus',
                operation: FilterOperation.Equals,
                values: Array.isArray(status) ? status : [],
                value: !Array.isArray(status) ? status : null,
                allowEmptyOrNull: false,
            }).toJS()
        );
    }

    return BulkGetRequest.fromJS({
        page: currentPage || 1,
        size: pageOffset || 10,
        filters,
        sortingInfo: [],
    });
};
