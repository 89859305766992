// @flow
import React, { type Node } from 'react';
import { Flex, Text, Icon, Image, Heading, Columns } from '@locus-taxy/neo-ui';
import Logo from 'img/logo.svg';

const PanelTabs = [
    {
        label: 'Tours',
        icon: 'dispatcher_truck_enroute',
    },
];

const LeftPanel = (): Node => {
    return (
        <Flex
            width={300}
            backgroundColor="white"
            height="100%"
            p={4}
            flexDirection="column"
        >
            <Columns space="small" alignY="center">
                <Image src={Logo} />
                <Heading level="2" color="grey.800">
                    Transporter
                </Heading>
            </Columns>
            {PanelTabs.map(({ label, icon }) => (
                <Flex
                    key={label}
                    alignItems="center"
                    height="56px"
                    borderRadius="8px"
                    backgroundColor="grey.100"
                    px={4}
                    mt={4}
                >
                    <Flex mr={4} alignItems="center">
                        <Icon icon={icon} size="xxLarge" color="blue.600" />
                    </Flex>
                    <Text fontSize={3} fontWeight="700" color="blue.600">
                        {label}
                    </Text>
                </Flex>
            ))}
        </Flex>
    );
};

export default LeftPanel;
