// @flow
import React, { type Node } from 'react';
import { Text, Position, Icon, Flex } from '@locus-taxy/neo-ui';

type Props = {
    single?: boolean,
    isError?: boolean,
};

const NoTourFound = ({ single, isError }: Props): Node => {
    const getErrorMessage = () => {
        if (isError) {
            return 'Oops! Error Occured';
        }

        return single ? 'Selected Tour Not Found!' : 'No Tour Found!';
    };

    return (
        <Position
            top="50%"
            left="50%"
            position="absolute"
            sx={{ transform: 'translateX(-50%)' }}
        >
            <Flex alignItems="center" flexDirection="column">
                <Icon
                    icon="roster_vehicle_warning"
                    size="xxLarge"
                    color="grey.600"
                />
                <Text fontWeight="600" color="grey.600" fontSize={4} mt={2}>
                    {getErrorMessage()}
                </Text>
            </Flex>
        </Position>
    );
};

export default NoTourFound;
