// @flow
import React, { type Node } from 'react';
import { Flex, Loader, Position } from '@locus-taxy/neo-ui';

const PageLoader = (): Node => {
    return (
        <Position
            position="absolute"
            top="50%"
            left="50%"
            sx={{
                transform: 'translateX(-50%)',
            }}
        >
            <Flex
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
            >
                <Loader size="large" color="blue.500" />
            </Flex>
        </Position>
    );
};

export default PageLoader;
