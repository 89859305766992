// @flow
import React, { type Node, useState } from 'react';
import ResponsiveBox from 'components/common/ResponsiveBox';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const ToursHeader = (): Node => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);

    return (
        <>
            <ResponsiveBox noMobile>
                <DesktopHeader />
            </ResponsiveBox>
            <ResponsiveBox noDesktop>
                <MobileHeader
                    openDrawer={openDialog}
                    closeDrawer={closeDialog}
                    isDrawerOpen={isDialogOpen}
                />
            </ResponsiveBox>
        </>
    );
};

export default ToursHeader;
