// @flow
import React, { type Node } from 'react';

type Props = {
    message: string,
};

const APIErrorScreen = ({ message }: Props): Node => {
    return <div>Uh, oh! Some Error occurred - {message}</div>;
};

export default APIErrorScreen;
