// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo
import type { VisitResourceModelType } from './VisitResource';

import { VisitResource } from './VisitResource';

export type ResourcesType = {
    task?: VisitResourceModelType,
};

export type TourSummaryModelType = {
    volume: VisitResourceModelType,
    time: VisitResourceModelType,
    resources: ResourcesType,
    totalTravelDistance: number,
    totalTravelDuration: number,
    totalTardiness: number,
    totalIdleDuration: number,
    totalTransactionDuration: number,
    totalDuration: number,
    totalBreaksDuration: number,
    vehiclesOperationSlot: {
        start: string,
        end: string,
    },
    totalOnDutyDuration: number,
    startTime: string,
    endTime: string,
    taskCount: number,
    visitCount: number,
    tourCarbonEmission: number,
    freightCost: null,
    uniqueAddressCount: number,
    totalSlaBreachedTasks: number,
    usedSkills: {
        tags: ?Array<string>,
    },
    violationTypes: ?Array<string>,
    lineItemQuantity: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TourSummary extends ImmutableModel {
    static fromJS(json: TourSummaryModelType): TourSummary {
        const state: Object = Object.assign({}, json);
        state.volume = VisitResource.fromJS(state.volume);
        state.time = VisitResource.fromJS(state.time);
        state.violationTypes = state.violationTypes
            ? Immutable.List(state.violationTypes)
            : state.violationTypes;
        return new this(Immutable.Map(state));
    }

    toJS(): TourSummaryModelType {
        return {
            volume: this.volume.toJS(),
            time: this.time.toJS(),
            resources: this.resources,
            totalTravelDistance: this.totalTravelDistance,
            totalTravelDuration: this.totalTravelDuration,
            totalTardiness: this.totalTardiness,
            totalIdleDuration: this.totalIdleDuration,
            totalTransactionDuration: this.totalTransactionDuration,
            totalDuration: this.totalDuration,
            totalBreaksDuration: this.totalBreaksDuration,
            vehiclesOperationSlot: this.vehiclesOperationSlot,
            totalOnDutyDuration: this.totalOnDutyDuration,
            startTime: this.startTime,
            endTime: this.endTime,
            taskCount: this.taskCount,
            visitCount: this.visitCount,
            tourCarbonEmission: this.tourCarbonEmission,
            freightCost: this.freightCost,
            uniqueAddressCount: this.uniqueAddressCount,
            totalSlaBreachedTasks: this.totalSlaBreachedTasks,
            usedSkills: this.usedSkills,
            violationTypes: this.violationTypes
                ? this.violationTypes.toArray()
                : this.violationTypes,
            lineItemQuantity: this.lineItemQuantity,
        };
    }

    get volume(): VisitResource {
        return this._state.get('volume');
    }

    setVolume(volume: VisitResource): this {
        return this.clone(this._state.set('volume', volume));
    }

    get time(): VisitResource {
        return this._state.get('time');
    }

    setTime(time: VisitResource): this {
        return this.clone(this._state.set('time', time));
    }

    get resources(): ResourcesType {
        return this._state.get('resources');
    }

    setResources(resources: ResourcesType): this {
        return this.clone(this._state.set('resources', resources));
    }

    get totalTravelDistance(): number {
        return this._state.get('totalTravelDistance');
    }

    setTotalTravelDistance(totalTravelDistance: number): this {
        return this.clone(
            this._state.set('totalTravelDistance', totalTravelDistance)
        );
    }

    get totalTravelDuration(): number {
        return this._state.get('totalTravelDuration');
    }

    setTotalTravelDuration(totalTravelDuration: number): this {
        return this.clone(
            this._state.set('totalTravelDuration', totalTravelDuration)
        );
    }

    get totalTardiness(): number {
        return this._state.get('totalTardiness');
    }

    setTotalTardiness(totalTardiness: number): this {
        return this.clone(this._state.set('totalTardiness', totalTardiness));
    }

    get totalIdleDuration(): number {
        return this._state.get('totalIdleDuration');
    }

    setTotalIdleDuration(totalIdleDuration: number): this {
        return this.clone(
            this._state.set('totalIdleDuration', totalIdleDuration)
        );
    }

    get totalTransactionDuration(): number {
        return this._state.get('totalTransactionDuration');
    }

    setTotalTransactionDuration(totalTransactionDuration: number): this {
        return this.clone(
            this._state.set(
                'totalTransactionDuration',
                totalTransactionDuration
            )
        );
    }

    get totalDuration(): number {
        return this._state.get('totalDuration');
    }

    setTotalDuration(totalDuration: number): this {
        return this.clone(this._state.set('totalDuration', totalDuration));
    }

    get totalBreaksDuration(): number {
        return this._state.get('totalBreaksDuration');
    }

    setTotalBreaksDuration(totalBreaksDuration: number): this {
        return this.clone(
            this._state.set('totalBreaksDuration', totalBreaksDuration)
        );
    }

    get vehiclesOperationSlot(): {
        start: string,
        end: string,
    } {
        return this._state.get('vehiclesOperationSlot');
    }

    setVehiclesOperationSlot(vehiclesOperationSlot: {
        start: string,
        end: string,
    }): this {
        return this.clone(
            this._state.set('vehiclesOperationSlot', vehiclesOperationSlot)
        );
    }

    get totalOnDutyDuration(): number {
        return this._state.get('totalOnDutyDuration');
    }

    setTotalOnDutyDuration(totalOnDutyDuration: number): this {
        return this.clone(
            this._state.set('totalOnDutyDuration', totalOnDutyDuration)
        );
    }

    get startTime(): string {
        return this._state.get('startTime');
    }

    setStartTime(startTime: string): this {
        return this.clone(this._state.set('startTime', startTime));
    }

    get endTime(): string {
        return this._state.get('endTime');
    }

    setEndTime(endTime: string): this {
        return this.clone(this._state.set('endTime', endTime));
    }

    get taskCount(): number {
        return this._state.get('taskCount');
    }

    setTaskCount(taskCount: number): this {
        return this.clone(this._state.set('taskCount', taskCount));
    }

    get visitCount(): number {
        return this._state.get('visitCount');
    }

    setVisitCount(visitCount: number): this {
        return this.clone(this._state.set('visitCount', visitCount));
    }

    get tourCarbonEmission(): number {
        return this._state.get('tourCarbonEmission');
    }

    setTourCarbonEmission(tourCarbonEmission: number): this {
        return this.clone(
            this._state.set('tourCarbonEmission', tourCarbonEmission)
        );
    }

    get freightCost(): null {
        return this._state.get('freightCost');
    }

    setFreightCost(freightCost: null): this {
        return this.clone(this._state.set('freightCost', freightCost));
    }

    get uniqueAddressCount(): number {
        return this._state.get('uniqueAddressCount');
    }

    setUniqueAddressCount(uniqueAddressCount: number): this {
        return this.clone(
            this._state.set('uniqueAddressCount', uniqueAddressCount)
        );
    }

    get totalSlaBreachedTasks(): number {
        return this._state.get('totalSlaBreachedTasks');
    }

    setTotalSlaBreachedTasks(totalSlaBreachedTasks: number): this {
        return this.clone(
            this._state.set('totalSlaBreachedTasks', totalSlaBreachedTasks)
        );
    }

    get usedSkills(): { tags: ?Array<string> } {
        return this._state.get('usedSkills');
    }

    setUsedSkills(usedSkills: { tags: ?Array<string> }): this {
        return this.clone(this._state.set('usedSkills', usedSkills));
    }

    get violationTypes(): ?Immutable.List<string> {
        return this._state.get('violationTypes');
    }

    setViolationTypes(violationTypes: ?Immutable.List<string>): this {
        return this.clone(this._state.set('violationTypes', violationTypes));
    }

    get lineItemQuantity(): number {
        return this._state.get('lineItemQuantity');
    }

    setLineItemQuantity(lineItemQuantity: number): this {
        return this.clone(
            this._state.set('lineItemQuantity', lineItemQuantity)
        );
    }
}
