// @flow
import { tdmsInstance } from 'middleware/api';
import { OperationsTour } from 'models/internal/OperationsTour';
import { useQuery, type QueryResult } from '@tanstack/react-query';

type Props = {
    clientId: string,
    tourId: string,
    onError: (err: string) => void,
};

const useTourSearch = ({
    clientId,
    tourId,
    onError,
}: Props): QueryResult<OperationsTour> => {
    return useQuery({
        queryKey: ['tour-search', clientId, tourId],
        queryFn: async () => {
            const { data } = await tdmsInstance.get(
                `/client/${clientId}/tour/${tourId}?include=DISPATCHED_TOUR`
            );

            return OperationsTour.fromJS(data);
        },
        refetchOnWindowFocus: false,
        onError,
    });
};

export default useTourSearch;
