// @flow
import React, { type Node } from 'react';
import { Flex } from '@locus-taxy/neo-ui';

import AppHeader from 'components/common/AppHeader';
import DateMenu from 'components/common/DateMenu';

const DesktopHeader = (): Node => {
    return (
        <AppHeader>
            <Flex justifyContent="flex-end" flexGrow={1}>
                <DateMenu />
            </Flex>
        </AppHeader>
    );
};

export default DesktopHeader;
