// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type AssignmentDetailsModelType = {
    transporterId: ?string,
    vehicleModelId: ?string,
    vehicleModelName: ?string,
    vehicleId: ?string,
    vehicleName: ?string,
    riderId: ?string,
    riderName: ?string,
    riderContact: ?string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class AssignmentDetails extends ImmutableModel {
    static fromJS(json: AssignmentDetailsModelType): AssignmentDetails {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): AssignmentDetailsModelType {
        return {
            transporterId: this.transporterId,
            vehicleModelId: this.vehicleModelId,
            vehicleModelName: this.vehicleModelName,
            vehicleId: this.vehicleId,
            vehicleName: this.vehicleName,
            riderId: this.riderId,
            riderName: this.riderName,
            riderContact: this.riderContact,
        };
    }

    get transporterId(): ?string {
        return this._state.get('transporterId');
    }

    setTransporterId(transporterId: ?string): this {
        return this.clone(this._state.set('transporterId', transporterId));
    }

    get vehicleModelId(): ?string {
        return this._state.get('vehicleModelId');
    }

    setVehicleModelId(vehicleModelId: ?string): this {
        return this.clone(this._state.set('vehicleModelId', vehicleModelId));
    }

    get vehicleModelName(): ?string {
        return this._state.get('vehicleModelName');
    }

    setVehicleModelName(vehicleModelName: ?string): this {
        return this.clone(
            this._state.set('vehicleModelName', vehicleModelName)
        );
    }

    get vehicleId(): ?string {
        return this._state.get('vehicleId');
    }

    setVehicleId(vehicleId: ?string): this {
        return this.clone(this._state.set('vehicleId', vehicleId));
    }

    get vehicleName(): ?string {
        return this._state.get('vehicleName');
    }

    setVehicleName(vehicleName: ?string): this {
        return this.clone(this._state.set('vehicleName', vehicleName));
    }

    get riderId(): ?string {
        return this._state.get('riderId');
    }

    setRiderId(riderId: ?string): this {
        return this.clone(this._state.set('riderId', riderId));
    }

    get riderName(): ?string {
        return this._state.get('riderName');
    }

    setRiderName(riderName: ?string): this {
        return this.clone(this._state.set('riderName', riderName));
    }

    get riderContact(): ?string {
        return this._state.get('riderContact');
    }

    setRiderContact(riderContact: ?string): this {
        return this.clone(this._state.set('riderContact', riderContact));
    }
}
