// @flow
import React, { type Node } from 'react';
import { Columns, Image, Text, Flex } from '@locus-taxy/neo-ui';
import { Link } from 'react-router-dom';
import ResponsiveBox from 'components/common/ResponsiveBox';

type Props = {
    url: string,
    name: string,
    onFileReupload: (e: SyntheticEvent<HTMLInputElement>) => void,
};

const FileDetails = ({ url, name, onFileReupload }: Props): Node => {
    return (
        <ResponsiveBox
            isFlex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            mobileSx={{
                flexDirection: 'column',
                gap: '10px',
            }}
        >
            <Columns space="small" alignY="center">
                <Flex height="80px" maxWidth="120px">
                    <Image
                        src={url}
                        height="100%"
                        sx={{ objectFit: 'cover' }}
                    />
                </Flex>
                <Link target="__blank" to={url} rel="noreferrer noopener">
                    <Text fontSize={1} color="blue.500">
                        {name}
                    </Text>
                </Link>
            </Columns>

            <Flex>
                <label id="re-upload-btn-label">
                    Re-upload
                    <input
                        type="file"
                        hidden
                        onChange={onFileReupload}
                        accept="image/png,image/jpg,image/jpeg"
                    />
                </label>
            </Flex>
        </ResponsiveBox>
    );
};

export default FileDetails;
