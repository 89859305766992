// @flow
import { useQuery, type QueryResult } from '@tanstack/react-query';
import { dashInstance } from 'middleware/api';
import { MinimalPersonnel } from 'models/internal/MinimalPersonnel';

type Props = {
    username: string,
};

const useGetMinimalPersonnel = ({
    username,
}: Props): QueryResult<MinimalPersonnel, Error> => {
    return useQuery({
        queryKey: ['minimal-personnel', { username }],
        queryFn: async () => {
            const { data } = await dashInstance.get(`/minimal-personnel`, {
                params: { username },
            });
            return MinimalPersonnel.fromJS(data);
        },
        refetchOnWindowFocus: false,
    });
};

export default useGetMinimalPersonnel;
