//@flow
import * as Immutable from 'immutable';

import { VehicleTour } from 'models/flow/VehicleTour';
import type { VehicleTourModelType } from 'models/flow/VehicleTour';
import { TourVehicle } from './TourVehicle';
import { TourSummary } from './TourSummary';
import { TourVisit } from './TourVisit';

class InternalVehicleTour extends VehicleTour {
    static fromJS(json: VehicleTourModelType): InternalVehicleTour {
        const state: Object = Object.assign({}, json);
        state.tags = Immutable.List(state.tags);
        state.vehicle = TourVehicle.fromJS(state.vehicle);
        state.summary = TourSummary.fromJS(state.summary);
        state.visits = Immutable.List(state.visits).map((item) =>
            TourVisit.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get vehicle(): TourVehicle {
        return this._state.get('vehicle');
    }

    get summary(): TourSummary {
        return this._state.get('summary');
    }

    get visits(): Immutable.List<TourVisit> {
        return this._state.get('visits');
    }
}

export { InternalVehicleTour as VehicleTour };
