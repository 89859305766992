// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { ResourceLimit } from './ResourceLimit';
import { VolumeLimit } from './VolumeLimit';

import type { ResourceLimitModelType } from './ResourceLimit';
import type { VolumeLimitModelType } from './VolumeLimit';

export type LimitsModelType = {
    resourceLimits: ?Array<ResourceLimitModelType>,
    volumeLimit: ?VolumeLimitModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Limits extends ImmutableModel {
    static fromJS(json: LimitsModelType): Limits {
        const state: Object = Object.assign({}, json);
        state.resourceLimits = state.resourceLimits
            ? Immutable.List(state.resourceLimits).map((item) =>
                  ResourceLimit.fromJS(item)
              )
            : state.resourceLimits;
        state.volumeLimit = state.volumeLimit
            ? VolumeLimit.fromJS(state.volumeLimit)
            : state.volumeLimit;
        return new this(Immutable.Map(state));
    }

    toJS(): LimitsModelType {
        return {
            resourceLimits: this.resourceLimits
                ? this.resourceLimits.toArray().map((item) => item.toJS())
                : this.resourceLimits,
            volumeLimit: this.volumeLimit
                ? this.volumeLimit.toJS()
                : this.volumeLimit,
        };
    }

    get resourceLimits(): ?Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }

    setResourceLimits(resourceLimits: ?Immutable.List<ResourceLimit>): this {
        return this.clone(this._state.set('resourceLimits', resourceLimits));
    }

    get volumeLimit(): ?VolumeLimit {
        return this._state.get('volumeLimit');
    }

    setVolumeLimit(volumeLimit: ?VolumeLimit): this {
        return this.clone(this._state.set('volumeLimit', volumeLimit));
    }
}
