// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

import type {
    PasswordPolicyEnumType,
    PasswordAuthProviderEnumType,
} from 'enums/AuthEnum';

export type MinimalPersonnelModelType = {
    clientId: string,
    personnelId: string,
    passwordAuthDetails: {
        provider: PasswordAuthProviderEnumType,
        minimumPasswordLength: number,
        passwordPolicy: PasswordPolicyEnumType,
    },
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class MinimalPersonnel extends ImmutableModel {
    static fromJS(json: MinimalPersonnelModelType): MinimalPersonnel {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): MinimalPersonnelModelType {
        return {
            clientId: this.clientId,
            personnelId: this.personnelId,
            passwordAuthDetails: this.passwordAuthDetails,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get personnelId(): string {
        return this._state.get('personnelId');
    }

    setPersonnelId(personnelId: string): this {
        return this.clone(this._state.set('personnelId', personnelId));
    }

    get passwordAuthDetails(): {
        provider: PasswordAuthProviderEnumType,
        minimumPasswordLength: number,
        passwordPolicy: PasswordPolicyEnumType,
    } {
        return this._state.get('passwordAuthDetails');
    }

    setPasswordAuthDetails(passwordAuthDetails: {
        provider: PasswordAuthProviderEnumType,
        minimumPasswordLength: number,
        passwordPolicy: PasswordPolicyEnumType,
    }): this {
        return this.clone(
            this._state.set('passwordAuthDetails', passwordAuthDetails)
        );
    }
}
