//@flow
import * as Immutable from 'immutable';

import { StructuredAddress } from 'models/flow/StructuredAddress';
import type { StructuredAddressModelType } from 'models/flow/StructuredAddress';

class InternalStructuredAddress extends StructuredAddress {
    static fromJS(json: StructuredAddressModelType): InternalStructuredAddress {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    getFullAddress(): string {
        return [
            this.placeName,
            this.formattedAddress,
            this.localityName,
            this.subLocalityName,
            this.city,
            this.state,
            this.pincode,
            this.countryCode,
        ]
            .filter((val) => val)
            .join(', ');
    }
}

export { InternalStructuredAddress as StructuredAddress };
