// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type UnitEnum = 'COUNT' | 'SECONDS' | 'METERS' | 'KG' | 'UNIT';

export type RiderPersonaResourceLimitModelType = {
    name: string,
    displayName?: string,
    unit: UnitEnum,
    lowerThresh: number,
    higherThresh: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class RiderPersonaResourceLimit extends ImmutableModel {
    static fromJS(
        json: RiderPersonaResourceLimitModelType
    ): RiderPersonaResourceLimit {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): RiderPersonaResourceLimitModelType {
        const js: RiderPersonaResourceLimitModelType = {
            name: this.name,
            unit: this.unit,
            lowerThresh: this.lowerThresh,
            higherThresh: this.higherThresh,
        };

        if (this.displayName != null) {
            js.displayName = this.displayName;
        }

        return js;
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get displayName(): ?string {
        return this._state.get('displayName');
    }

    setDisplayName(displayName: ?string): this {
        return this.clone(this._state.set('displayName', displayName));
    }

    get unit(): UnitEnum {
        return this._state.get('unit');
    }

    setUnit(unit: UnitEnum): this {
        return this.clone(this._state.set('unit', unit));
    }

    get lowerThresh(): number {
        return this._state.get('lowerThresh');
    }

    setLowerThresh(lowerThresh: number): this {
        return this.clone(this._state.set('lowerThresh', lowerThresh));
    }

    get higherThresh(): number {
        return this._state.get('higherThresh');
    }

    setHigherThresh(higherThresh: number): this {
        return this.clone(this._state.set('higherThresh', higherThresh));
    }
}
