// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';
import type { VehicleModelModelType } from './VehicleModel';
import type { ResourceLimitModelType } from './ResourceLimit';
import type { TourVehicleModelModelType } from './TourVehicleModel';

import { VehicleModel } from './VehicleModel';
import { ResourceLimit } from './ResourceLimit';
import { TourVehicleModel } from './TourVehicleModel';

type IdType = {
    clientId: string,
    version: ?number,
    id: string,
};

export type TourVehicleModelType = {
    id: IdType,
    vehicleModel: TourVehicleModelModelType,
    resourceLimits: Array<ResourceLimitModelType>,
    skills: {
        tags: Array<string>,
    },
    isCrossDockEnabled: boolean,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TourVehicle extends ImmutableModel {
    static fromJS(json: TourVehicleModelType): TourVehicle {
        const state: Object = Object.assign({}, json);
        state.vehicleModel = TourVehicleModel.fromJS(state.vehicleModel);
        state.resourceLimits = Immutable.List(state.resourceLimits).map(
            (item) => ResourceLimit.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): TourVehicleModelType {
        return {
            id: this.id,
            vehicleModel: this.vehicleModel.toJS(),
            resourceLimits: this.resourceLimits
                .toArray()
                .map((item) => item.toJS()),
            skills: this.skills,
            isCrossDockEnabled: this.isCrossDockEnabled,
        };
    }

    get id(): IdType {
        return this._state.get('id');
    }

    setId(id: IdType): this {
        return this.clone(this._state.set('id', id));
    }

    get vehicleModel(): TourVehicleModel {
        return this._state.get('vehicleModel');
    }

    setVehicleModel(vehicleModel: TourVehicleModel): this {
        return this.clone(this._state.set('vehicleModel', vehicleModel));
    }

    get resourceLimits(): Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }

    setResourceLimits(resourceLimits: Immutable.List<ResourceLimit>): this {
        return this.clone(this._state.set('resourceLimits', resourceLimits));
    }

    get skills(): { tags: Array<string> } {
        return this._state.get('skills');
    }

    setSkills(skills: { tags: Array<string> }): this {
        return this.clone(this._state.set('skills', skills));
    }

    get isCrossDockEnabled(): boolean {
        return this._state.get('isCrossDockEnabled');
    }

    setIsCrossDockEnabled(isCrossDockEnabled: boolean): this {
        return this.clone(
            this._state.set('isCrossDockEnabled', isCrossDockEnabled)
        );
    }
}
