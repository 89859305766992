// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

type ResourceDefinitionType = {
    name: string,
    unit: string,
};

export type VisitResourceModelType = {
    used: number,
    total: number,
    available: number,
    required: ?boolean,
    current: ?boolean,
    definition: ?ResourceDefinitionType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class VisitResource extends ImmutableModel {
    static fromJS(json: VisitResourceModelType): VisitResource {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): VisitResourceModelType {
        return {
            used: this.used,
            total: this.total,
            available: this.available,
            required: this.required,
            current: this.current,
            definition: this.definition,
        };
    }

    get used(): number {
        return this._state.get('used');
    }

    setUsed(used: number): this {
        return this.clone(this._state.set('used', used));
    }

    get total(): number {
        return this._state.get('total');
    }

    setTotal(total: number): this {
        return this.clone(this._state.set('total', total));
    }

    get available(): number {
        return this._state.get('available');
    }

    setAvailable(available: number): this {
        return this.clone(this._state.set('available', available));
    }

    get required(): ?boolean {
        return this._state.get('required');
    }

    setRequired(required: ?boolean): this {
        return this.clone(this._state.set('required', required));
    }

    get current(): ?boolean {
        return this._state.get('current');
    }

    setCurrent(current: ?boolean): this {
        return this.clone(this._state.set('current', current));
    }

    get definition(): ?ResourceDefinitionType {
        return this._state.get('definition');
    }

    setDefinition(definition: ?ResourceDefinitionType): this {
        return this.clone(this._state.set('definition', definition));
    }
}
