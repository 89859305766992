// @flow
import {
    type PasswordStrengthConfig,
    type PasswordStrengthResult,
} from './types';
import PasswordPolicy, { policies } from './PasswordPolicy';

export default function checkPasswordStrength(
    password: string,
    config: PasswordStrengthConfig
): PasswordStrengthResult {
    const policy = {
        ...policies[config.policy.toLowerCase()],
        length: {
            minLength: config.minimumLength,
        },
    };
    const checker = new PasswordPolicy(policy);
    return checker.missing(password);
}
