// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

import { VolumeLimit } from './VolumeLimit';
import { RiderPersonaResourceLimit } from './RiderPersonaResourceLimit';
import { ResourceLimit } from './ResourceLimit';

import type { VolumeLimitModelType } from './VolumeLimit';
import type { RiderPersonaResourceLimitModelType } from './RiderPersonaResourceLimit';
import type { ResourceLimitModelType } from './ResourceLimit';

export type RiderPersonaLimitsModelType = {
    transactionTimePerTask: ?number,
    volume: ?VolumeLimitModelType,
    distance: ?RiderPersonaResourceLimitModelType,
    weight: ?RiderPersonaResourceLimitModelType,
    travelTime: ?RiderPersonaResourceLimitModelType,
    totalTime: ?RiderPersonaResourceLimitModelType,
    location: ?RiderPersonaResourceLimitModelType,
    task: ?RiderPersonaResourceLimitModelType,
    tour: ?RiderPersonaResourceLimitModelType,
    resourceLimits: Array<ResourceLimitModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class RiderPersonaLimits extends ImmutableModel {
    static fromJS(json: RiderPersonaLimitsModelType): RiderPersonaLimits {
        const state: Object = Object.assign({}, json);
        state.volume = state.volume
            ? VolumeLimit.fromJS(state.volume)
            : state.volume;
        state.distance = state.distance
            ? RiderPersonaResourceLimit.fromJS(state.distance)
            : state.distance;
        state.weight = state.weight
            ? RiderPersonaResourceLimit.fromJS(state.weight)
            : state.weight;
        state.travelTime = state.travelTime
            ? RiderPersonaResourceLimit.fromJS(state.travelTime)
            : state.travelTime;
        state.totalTime = state.totalTime
            ? RiderPersonaResourceLimit.fromJS(state.totalTime)
            : state.totalTime;
        state.location = state.location
            ? RiderPersonaResourceLimit.fromJS(state.location)
            : state.location;
        state.task = state.task
            ? RiderPersonaResourceLimit.fromJS(state.task)
            : state.task;
        state.tour = state.tour
            ? RiderPersonaResourceLimit.fromJS(state.tour)
            : state.tour;
        state.resourceLimits = Immutable.List(state.resourceLimits).map(
            (item) => ResourceLimit.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): RiderPersonaLimitsModelType {
        return {
            transactionTimePerTask: this.transactionTimePerTask,
            volume: this.volume ? this.volume.toJS() : this.volume,
            distance: this.distance ? this.distance.toJS() : this.distance,
            weight: this.weight ? this.weight.toJS() : this.weight,
            travelTime: this.travelTime
                ? this.travelTime.toJS()
                : this.travelTime,
            totalTime: this.totalTime ? this.totalTime.toJS() : this.totalTime,
            location: this.location ? this.location.toJS() : this.location,
            task: this.task ? this.task.toJS() : this.task,
            tour: this.tour ? this.tour.toJS() : this.tour,
            resourceLimits: this.resourceLimits
                .toArray()
                .map((item) => item.toJS()),
        };
    }

    get transactionTimePerTask(): ?number {
        return this._state.get('transactionTimePerTask');
    }

    setTransactionTimePerTask(transactionTimePerTask: ?number): this {
        return this.clone(
            this._state.set('transactionTimePerTask', transactionTimePerTask)
        );
    }

    get volume(): ?VolumeLimit {
        return this._state.get('volume');
    }

    setVolume(volume: ?VolumeLimit): this {
        return this.clone(this._state.set('volume', volume));
    }

    get distance(): ?RiderPersonaResourceLimit {
        return this._state.get('distance');
    }

    setDistance(distance: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('distance', distance));
    }

    get weight(): ?RiderPersonaResourceLimit {
        return this._state.get('weight');
    }

    setWeight(weight: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('weight', weight));
    }

    get travelTime(): ?RiderPersonaResourceLimit {
        return this._state.get('travelTime');
    }

    setTravelTime(travelTime: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('travelTime', travelTime));
    }

    get totalTime(): ?RiderPersonaResourceLimit {
        return this._state.get('totalTime');
    }

    setTotalTime(totalTime: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('totalTime', totalTime));
    }

    get location(): ?RiderPersonaResourceLimit {
        return this._state.get('location');
    }

    setLocation(location: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('location', location));
    }

    get task(): ?RiderPersonaResourceLimit {
        return this._state.get('task');
    }

    setTask(task: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('task', task));
    }

    get tour(): ?RiderPersonaResourceLimit {
        return this._state.get('tour');
    }

    setTour(tour: ?RiderPersonaResourceLimit): this {
        return this.clone(this._state.set('tour', tour));
    }

    get resourceLimits(): Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }

    setResourceLimits(resourceLimits: Immutable.List<ResourceLimit>): this {
        return this.clone(this._state.set('resourceLimits', resourceLimits));
    }
}
