// @flow
import React from 'react';
import { Heading, Columns, Image, Box, Stack, Icon } from '@locus-taxy/neo-ui';

import Logo from 'img/logo.svg';

const PanelTabs = [
    {
        label: 'Tours',
        icon: 'dispatcher_truck_enroute',
    },
];

const DrawerContent = () => {
    return (
        <Box padding={4} width="300px">
            <Box mb={5}>
                <Columns space="small" alignY="center">
                    <Image src={Logo} />
                    <Heading level="2" color="grey.800">
                        Transporter
                    </Heading>
                </Columns>
            </Box>
            <Stack space="small">
                {PanelTabs.map(({ icon, label }) => {
                    return (
                        <Columns space="small" key={label} alignY="center">
                            <Icon icon={icon} size="xLarge" color="blue.500" />
                            <Heading level="3" color="blue.500">
                                Tours
                            </Heading>
                        </Columns>
                    );
                })}
            </Stack>
        </Box>
    );
};

export default DrawerContent;
