// @flow
import React, { type Node } from 'react';
import { Box, Flex, Text, Columns, Chip, Icon } from '@locus-taxy/neo-ui';
import type { OrderStatuses } from 'models/flow/OrderDetails';

type Props = {
    sequence: number,
    sourceId: string,
    exchangeType: 'GIVE' | 'COLLECT',
    orderStatus: OrderStatuses,
};

const EXCHANGE_LABELS = Object.freeze({
    GIVE: 'DROP',
    COLLECT: 'PICKUP',
});

const StatusPaletteMapping = Object.freeze({
    COMPLETED: 'success',
    EXECUTING: 'primary',
    PLANNED: 'neutral',
});

const CardTop = ({
    sequence,
    sourceId,
    exchangeType,
    orderStatus,
}: Props): Node => {
    const formattedSequence = sequence < 10 ? `0${sequence}` : sequence;

    return (
        <Flex justifyContent="space-between" alignItems="center">
            <Columns space="small" alignY="center">
                <Box borderRadius="2px" padding="1" backgroundColor="grey.500">
                    <Text
                        fontSize={1}
                        fontWeight="600"
                        letterSpacing={1.5}
                        color="white"
                    >
                        {formattedSequence}
                    </Text>
                </Box>
                <Text
                    textTransform="uppercase"
                    color="grey.800"
                    fontWeight="600"
                    fontSize={3}
                >
                    {sourceId}
                </Text>
            </Columns>
            <Columns space="small" alignY="center">
                {orderStatus ? (
                    <Chip
                        palette={StatusPaletteMapping[orderStatus] || 'default'}
                    >
                        {orderStatus}
                    </Chip>
                ) : null}
                <Text fontSize={2} color="grey.800">
                    {EXCHANGE_LABELS[exchangeType]}
                </Text>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%"
                    backgroundColor="grey.600"
                    p="2px"
                >
                    <Icon icon="arrow_drop_down" color="white" size="small" />
                </Flex>
            </Columns>
        </Flex>
    );
};

export default CardTop;
