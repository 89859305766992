// @flow
import React, { type Node, useState } from 'react';
import { Box, Flex, Text, Chip, Icon } from '@locus-taxy/neo-ui';
import TourCardBottomActions from './TourCardBottomActions';
import { TourStatuses } from 'constants';
import { OperationsTour } from 'models/internal/OperationsTour';
import TourDetailsCard, { SubText } from './TourDetailsCard';
import Info from 'components/common/Info';
import DriverVehicleDetails from './DriverVehicleDetails';
import ResponsiveBox from 'components/common/ResponsiveBox';

type Props = {
    tour: OperationsTour,
    minified?: boolean,
    refetchTours: () => void,
    hideToggle?: boolean,
};

const tourStatuses = {
    [TourStatuses.READY_TO_DISPATCH]: {
        palette: 'success',
        label: 'Ready to Dispatch',
        alertPalette: 'neutral',
        alertText: 'Tour is ready for dispatch',
    },
    [TourStatuses.IN_TRANSIT]: {
        palette: 'primary',
        label: 'In Transit',
        alertPalette: 'primary',
        alertText: 'Open tour to update order status and upload POD',
    },
    [TourStatuses.PENDING_CLOSURE]: {
        palette: 'info',
        label: 'Completed',
        alertPalette: 'warning',
        alertText: 'Open tour and Upload POD to close the tour',
    },
    [TourStatuses.CLOSED]: {
        palette: 'info',
        label: 'Closed',
        alertPalette: 'warning',
        alertText: 'Tour mark completed at 11:35pm',
    },
};

const TruncText = ({ children }: { children: Node }): Node => {
    return (
        <Text
            fontSize={4}
            color="grey.800"
            fontWeight="600"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        >
            {children}
        </Text>
    );
};

const TourCard = ({
    tour,
    minified = false,
    refetchTours,
    hideToggle,
}: Props): Node => {
    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const {
        id: tourId,
        transporterTourStatus: tourStatus,
        assignmentDetails,
        currentTour,
        tourName,
    } = tour;

    const { vehicleModelName } = assignmentDetails;

    const {
        palette,
        alertPalette,
        label: tourStatusLabel,
        alertText,
    } = tourStatuses[tourStatus];

    const isTourInTransit = tourStatus === TourStatuses.IN_TRANSIT;

    const handleToggle = () => {
        !minified &&
            !hideToggle &&
            setIsDetailsVisible((isVisible) => !isVisible);
    };

    const tourHeader = minified
        ? vehicleModelName
        : `${tourName || ''} | ${vehicleModelName || ''}`;

    return (
        <Flex flexDirection="column" width="100%">
            {/** Card Header */}
            <Flex justifyContent="space-between">
                <Flex
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleToggle}
                    width="100%"
                    overflow="hidden"
                >
                    {!minified && !hideToggle && (
                        <Icon
                            icon={
                                isDetailsVisible
                                    ? 'arrow_chevron_down'
                                    : 'arrow_chevron_up'
                            }
                            color="grey.600"
                            size="small"
                        />
                    )}
                    <SubText
                        ml={1}
                        overflow="hidden"
                        sx={{ textOverflow: 'ellipsis' }}
                        width="100%"
                        whiteSpace="nowrap"
                        mr={2}
                    >
                        ID: {tourId}
                    </SubText>
                </Flex>
                <Box>
                    <Chip palette={palette}>{tourStatusLabel}</Chip>
                </Box>
            </Flex>
            <Flex alignItems="center" mb={2}>
                <ResponsiveBox noMobile>
                    <Text fontSize={4} color="grey.800" fontWeight="600" mr={4}>
                        {tourHeader}
                    </Text>
                </ResponsiveBox>
                <ResponsiveBox noDesktop overflow="hidden">
                    {!minified && <TruncText>{tourName}</TruncText>}
                    <TruncText>{vehicleModelName}</TruncText>
                </ResponsiveBox>
                {isTourInTransit && <DriverVehicleDetails tour={currentTour} />}
            </Flex>

            {/** Details */}
            {isDetailsVisible && <TourDetailsCard tour={currentTour} />}

            {/** Bottom Actions */}
            {!minified && (
                <ResponsiveBox
                    isFlex
                    alignItems="center"
                    mt={4}
                    justifyContent="space-between"
                    mobileSx={{
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    <Box width="100%">
                        <Info palette={alertPalette} text={alertText} />
                    </Box>
                    <TourCardBottomActions
                        tour={tour}
                        refetchTours={refetchTours}
                    />
                </ResponsiveBox>
            )}
        </Flex>
    );
};

export default TourCard;
