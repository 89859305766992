// @flow
import { VisitResource } from 'models/internal/VisitResource';
import { type ResourcesType } from 'models/flow/TourSummary';

const knownResources = [
    {
        key: 'task',
        label: 'Task',
    },
    {
        key: 'weight',
        label: 'Weight',
    },
    {
        key: 'volume',
        label: 'Volume',
    },
];

export const getProcessedResources = (
    resources: ResourcesType
): Array<{ name: string, label: string, resource: VisitResource }> => {
    const allResources = [];

    knownResources.forEach(({ key, label }) => {
        const resourceDetails = resources[key];
        if (resourceDetails) {
            allResources.push({
                name: key,
                label,
                resource: VisitResource.fromJS(resourceDetails),
            });
        }
    });

    return allResources;
};
