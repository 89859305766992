// @flow
import React, { type Node } from 'react';
import { Stack, Flex, Text } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import { type List } from 'immutable';
import PhotoSelector from './PhotoSelector';
import BooleanSelector from './BooleanSelector';
import SingleChoiceSelector from './SingleChoiceSelector';
import InputSelector from './InputSelector';
import type { CheckListMap, ExtraProps, FileMap } from './helper';
import DateSelector from './DateSelector';
import TimeSelector from './TimeSelector';
import DateTimeSelector from './DateTimeSelector';
import RatingSelector from './RatingSelector';

type Props = {
    checklistItems: List<ChecklistItem>,
    filesMap: FileMap,
    setFilesMap: (map: FileMap) => void,
    checklistValues: CheckListMap,
    setChecklistValues: (map: CheckListMap) => void,
};

const checklistComponents = Object.freeze({
    SIGNATURE: PhotoSelector,
    PHOTO: PhotoSelector,
    BOOLEAN: BooleanSelector,
    SINGLE_CHOICE: SingleChoiceSelector,
    TEXT_FIELD: InputSelector,
    RATING: RatingSelector,
    PIN: InputSelector,
    URL: InputSelector,
    DATE: DateSelector,
    TIME: TimeSelector,
    DATETIME: DateTimeSelector,
    PHOTO_GALLERY: PhotoSelector,
});

const FILE_TYPE_CHEKLISTS = Object.keys(checklistComponents)
    .filter((key) => {
        const Selector = checklistComponents[key];
        return Selector === PhotoSelector;
    })
    .map((key) => key);

const getFileNameFromUrl = (url: string): string => {
    const [fileName] = url.split('/').reverse();
    return fileName;
};

const ChecklistItems = ({
    checklistItems,
    filesMap,
    setFilesMap,
    checklistValues,
    setChecklistValues,
}: Props): Node => {
    const onChange = (itemKey: string) => (item: File | string | boolean) => {
        let fileUrl;
        if (item instanceof File) {
            setFilesMap({
                ...filesMap,
                [itemKey]: item,
            });
            fileUrl = URL.createObjectURL(item);
        }

        setChecklistValues({
            ...checklistValues,
            [itemKey]: fileUrl || item,
        });
    };

    if (checklistItems.isEmpty()) {
        return (
            <Flex py={5}>
                <Text>No checklist has been configured for this order.</Text>
            </Flex>
        );
    }

    return (
        <Stack space="small">
            {checklistItems.map((checklist: ChecklistItem) => {
                const { format, key } = checklist;
                let extraProps: ExtraProps = {};

                // for files, pass fileName
                const file = filesMap[key];
                const value = checklistValues[key] || null;

                if (file || FILE_TYPE_CHEKLISTS.includes(format)) {
                    extraProps = {
                        ...extraProps,
                        fileName: file?.name || getFileNameFromUrl(value || ''),
                    };
                }

                const Component = checklistComponents[format];

                if (Component) {
                    return (
                        <Component
                            value={value}
                            key={key}
                            checklistConfig={checklist}
                            onChange={onChange(key)}
                            extraProps={extraProps}
                        />
                    );
                }

                return null;
            })}
        </Stack>
    );
};
export default ChecklistItems;
