// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type FilterOperationType =
    | 'EQUALS'
    | 'NOT_EQUALS'
    | 'STARTS_WITH'
    | 'CONTAINS'
    | 'CONTAINS_TOKEN'
    | 'GREATER_THAN'
    | 'LESSER_THAN'
    | 'GREATER_THAN_OR_EQUAL_TO'
    | 'LESSER_THAN_OR_EQUAL_TO';

export type LogicalOperationType = 'AND' | 'OR';

export type FilterModelType = {
    name: string,
    operation: FilterOperationType,
    value: string | Object,
    values: Array<string | Object>,
    allowEmptyOrNull: boolean,
    logicalOperation?: LogicalOperationType,
    expressionLogicalOperation?: LogicalOperationType,
    caseSensitive?: boolean,
};

export type ComplexFilterModelType = {
    filters: Array<FilterModelType>,
    expressionLogicalOperator?: LogicalOperationType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Filter extends ImmutableModel {
    static fromJS(json: FilterModelType): Filter {
        const state: Object = Object.assign({}, json);
        state.values = Immutable.List(state.values);
        return new this(Immutable.Map(state));
    }

    toJS(): FilterModelType {
        const js: FilterModelType = {
            name: this.name,
            operation: this.operation,
            value: this.value,
            values: this.values.toArray(),
            allowEmptyOrNull: this.allowEmptyOrNull,
        };

        if (this.logicalOperation != null) {
            js.logicalOperation = this.logicalOperation;
        }

        if (this.expressionLogicalOperation != null) {
            js.expressionLogicalOperation = this.expressionLogicalOperation;
        }

        if (this.caseSensitive != null) {
            js.caseSensitive = this.caseSensitive;
        }

        return js;
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get operation(): FilterOperationType {
        return this._state.get('operation');
    }

    setOperation(operation: FilterOperationType): this {
        return this.clone(this._state.set('operation', operation));
    }

    get value(): string | Object {
        return this._state.get('value');
    }

    setValue(value: string | Object): this {
        return this.clone(this._state.set('value', value));
    }

    get values(): Immutable.List<string | Object> {
        return this._state.get('values');
    }

    setValues(values: Immutable.List<string | Object>): this {
        return this.clone(this._state.set('values', values));
    }

    get allowEmptyOrNull(): boolean {
        return this._state.get('allowEmptyOrNull');
    }

    setAllowEmptyOrNull(allowEmptyOrNull: boolean): this {
        return this.clone(
            this._state.set('allowEmptyOrNull', allowEmptyOrNull)
        );
    }

    get logicalOperation(): ?LogicalOperationType {
        return this._state.get('logicalOperation');
    }

    setLogicalOperation(logicalOperation: ?LogicalOperationType): this {
        return this.clone(
            this._state.set('logicalOperation', logicalOperation)
        );
    }

    get expressionLogicalOperation(): ?LogicalOperationType {
        return this._state.get('expressionLogicalOperation');
    }

    setExpressionLogicalOperation(
        expressionLogicalOperation: ?LogicalOperationType
    ): this {
        return this.clone(
            this._state.set(
                'expressionLogicalOperation',
                expressionLogicalOperation
            )
        );
    }

    get caseSensitive(): ?boolean {
        return this._state.get('caseSensitive');
    }

    setCaseSensitive(caseSensitive: ?boolean): this {
        return this.clone(this._state.set('caseSensitive', caseSensitive));
    }
}

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class ComplexFilter extends ImmutableModel {
    static fromJS(json: ComplexFilterModelType): ComplexFilter {
        const state: Object = Object.assign({}, json);
        state.filters = Immutable.List(state.filters).map((item) =>
            Filter.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): ComplexFilterModelType {
        const js: ComplexFilterModelType = {
            filters: this.filters.toArray().map((item) => item.toJS()),
        };

        if (this.expressionLogicalOperator != null) {
            js.expressionLogicalOperator = this.expressionLogicalOperator;
        }

        return js;
    }

    get filters(): Immutable.List<Filter> {
        return this._state.get('filters');
    }

    setFilters(filters: Immutable.List<Filter>): this {
        return this.clone(this._state.set('filters', filters));
    }

    get expressionLogicalOperator(): ?LogicalOperationType {
        return this._state.get('expressionLogicalOperator');
    }

    setExpressionLogicalOperator(
        expressionLogicalOperator: ?LogicalOperationType
    ): this {
        return this.clone(
            this._state.set(
                'expressionLogicalOperator',
                expressionLogicalOperator
            )
        );
    }
}
