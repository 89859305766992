// @flow
import React, { type Node } from 'react';
import { Flex, Stack, Text, Columns } from '@locus-taxy/neo-ui';
import './styles.css';
import FileDetails from './FileDetails';

type Props = {
    onFileSelect: (file: File) => void,
    fileUrl: ?string,
    fileName: ?string,
};

const FileSelector = ({ onFileSelect, fileUrl, fileName }: Props): Node => {
    const handleFileSelect = (e: SyntheticEvent<HTMLInputElement>) => {
        const [file] = e.currentTarget.files;
        onFileSelect(file);
    };

    return (
        <Flex
            p={4}
            borderStyle="dashed"
            borderColor="grey.300"
            borderRadius="8px"
            justifyContent="center"
            borderWidth="1px"
            mr={4}
            width="100%"
        >
            {!fileUrl ? (
                <Stack align="center" space="xSmall">
                    <Text fontWeight="600" color="grey.900" fontSize={2}>
                        Upload file
                    </Text>
                    <Columns space="xxSmall" align="center">
                        <Text color="grey.600" fontSize={2}>
                            .PNG, .JPG, .JPEG
                        </Text>
                        <label id="upload-btn-label">
                            browse
                            <input
                                type="file"
                                hidden
                                onChange={handleFileSelect}
                                accept="image/png,image/jpg,image/jpeg"
                            />
                        </label>
                    </Columns>
                </Stack>
            ) : (
                <FileDetails
                    url={fileUrl}
                    name={fileName || ''}
                    onFileReupload={handleFileSelect}
                />
            )}
        </Flex>
    );
};

export default FileSelector;
