// @flow
import React, { type Node } from 'react';
import { Flex, Text, Icon } from '@locus-taxy/neo-ui';

type Props = {
    label: string,
    statusKey: string,
    onClick: (tourStatus: string) => void,
    isActive: boolean,
};

const styleVariants = {
    default: {
        borderColor: 'grey.200',
        bgColor: 'white',
        fontColor: 'blue.500',
        fontWeight: '400',
    },
    active: {
        borderColor: 'blue.500',
        bgColor: 'blue.500',
        fontColor: 'white',
        fontWeight: '600',
    },
};

const TourStatusFilterChip = ({
    onClick,
    label,
    isActive,
    statusKey,
}: Props): Node => {
    const { bgColor, borderColor, fontColor, fontWeight } =
        styleVariants[isActive ? 'active' : 'default'];

    return (
        <Flex
            height="32px"
            alignItems="center"
            borderRadius="78px"
            backgroundColor={bgColor}
            minWidth="50px"
            px={4}
            justifyContent="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => onClick(statusKey)}
            isActive={isActive}
            borderStyle="solid"
            borderWidth="1px"
            borderColor={borderColor}
        >
            {isActive && (
                <Flex mr={2} alignItems="center">
                    <Icon icon="check" color="white" size="large" />
                </Flex>
            )}
            <Text fontSize={2} fontWeight={fontWeight} color={fontColor}>
                {label}
            </Text>
        </Flex>
    );
};

export default TourStatusFilterChip;
