// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import { omsInstance } from 'middleware/api';

type Props = {
    clientId: string,
    orderId: string,
};

const useOrderCompletion = (): MutationResult<any> => {
    return useMutation(({ clientId, orderId }: Props) => {
        return omsInstance.put(
            `/client/${clientId}/order/${orderId}/order-status`,
            {
                orderStatus: 'COMPLETED',
            }
        );
    });
};

export default useOrderCompletion;
