// @flow
import { PasswordPolicy, charsets } from 'password-sheriff';
import auth0Policies from 'auth0-password-policies';
import { PasswordStrengthCheckEnum } from './types';

const alphabet = {
    explain: () => ({
        code: PasswordStrengthCheckEnum.alphabets,
    }),
    test: (password: string): boolean => /[a-zA-Z]/.test(password),
};

export const policies = {
    ...auth0Policies,
    locus: {
        length: {
            minLength: 8,
        },
        contains: {
            expressions: [
                alphabet,
                charsets.numbers,
                charsets.specialCharacters,
            ],
        },
    },
};

export default PasswordPolicy;
