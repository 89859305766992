//@flow
import * as Immutable from 'immutable';

import { MinimalPersonnel } from 'models/flow/MinimalPersonnel';
import type { MinimalPersonnelModelType } from 'models/flow/MinimalPersonnel';

import { PasswordAuthProviderEnum, PasswordPolicyEnum } from 'enums/AuthEnum';

import type { PasswordPolicyEnumType } from 'enums/AuthEnum';

class InternalMinimalPersonnel extends MinimalPersonnel {
    static fromJS(json: MinimalPersonnelModelType): InternalMinimalPersonnel {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    getPasswordPolicy(): PasswordPolicyEnumType {
        return this.passwordAuthDetails.provider ===
            PasswordAuthProviderEnum.iam
            ? PasswordPolicyEnum.Locus
            : this.passwordAuthDetails.passwordPolicy;
    }
}

export { InternalMinimalPersonnel as MinimalPersonnel };
