// @flow
import React, { type Node, useState } from 'react';
import { Flex, Button, Stack } from '@locus-taxy/neo-ui';
import Info from 'components/common/Info';
import UploadProofModal from 'components/dashboard/tours/UploadProofModal';
import ResponsiveBox from 'components/common/ResponsiveBox';
import UploadProofDrawer from 'components/dashboard/tours/UploadProofDrawer';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import { type List } from 'immutable';
import type { ChecklistValues, OrderStatuses } from 'models/flow/OrderDetails';

type Props = {
    orderId: string,
    checkList: List<ChecklistItem>,
    refetch: () => void,
    checklistValues: ChecklistValues,
    orderStatus: OrderStatuses,
    isTourClosed: boolean,
};

const OrderStatusMapping = Object.freeze({
    COMPLETED: {
        infoText: 'Order has been marked complete, POD uploaded',
        palette: 'neutral',
    },
    EXECUTING: {
        infoText: 'Upload POD to complete order',
        palette: 'primary',
    },
    ASSIGNED: {
        infoText: 'Order is assigned, waiting to be dispatched',
        palette: 'neutral',
    },
    PLANNED: {
        infoText: 'Order is planned, waiting to be dispatched',
        palette: 'neutral',
    },
});

const CardBottom = ({
    orderId,
    checkList,
    refetch,
    checklistValues,
    orderStatus,
    isTourClosed,
}: Props): Node => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    // different state for mobile to fix opening both
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const openUploadProofModal = () => setIsModalVisible(true);
    const closeUploadProofModal = () => setIsModalVisible(false);

    // mobile
    const openUploadProofDrawer = () => setIsDrawerVisible(true);
    const closeUploadProofDrawer = () => setIsDrawerVisible(false);

    const { infoText, palette } = OrderStatusMapping[orderStatus] || {};

    const canUploadProof = orderStatus === 'EXECUTING';
    const isOrderCompleted = orderStatus === 'COMPLETED';
    const canViewUploadedPOD = isOrderCompleted;

    const renderDesktopSkeleton = () => {
        return (
            <Flex justifyContent="space-between" alignItems="center">
                {infoText && <Info thin palette={palette} text={infoText} />}
                {(canViewUploadedPOD || canUploadProof) && (
                    <>
                        <Flex flexShrink="0" ml={4}>
                            <Button
                                appearance="solid"
                                palette="success"
                                onClick={openUploadProofModal}
                            >
                                {isOrderCompleted
                                    ? 'view Proof of delivery'
                                    : 'mark order as complete'}
                            </Button>
                        </Flex>
                        <UploadProofModal
                            disableActions={isTourClosed}
                            onClose={closeUploadProofModal}
                            isOpen={isModalVisible}
                            orderId={orderId}
                            checkList={checkList}
                            checklistValues={checklistValues}
                            onChecklistUpdate={() => {
                                refetch();
                                closeUploadProofModal();
                            }}
                            onOrderComplete={() => {
                                refetch();
                                closeUploadProofModal();
                            }}
                            isOrderCompleted={isOrderCompleted}
                        />
                    </>
                )}
            </Flex>
        );
    };

    const renderMobileSkeleton = () => {
        return (
            <Stack space="small">
                <Info thin palette={palette} text={infoText} />
                {(canViewUploadedPOD || canUploadProof) && (
                    <>
                        <Flex flexShrink="0">
                            <Button
                                appearance={
                                    isOrderCompleted ? 'outline' : 'solid'
                                }
                                palette={
                                    isOrderCompleted ? 'primary' : 'success'
                                }
                                onClick={openUploadProofDrawer}
                                width="100%"
                            >
                                {isOrderCompleted
                                    ? 'view Proof of delivery'
                                    : 'mark order as complete'}
                            </Button>
                        </Flex>
                        <UploadProofDrawer
                            disableActions={isTourClosed}
                            checklistValues={checklistValues}
                            onClose={closeUploadProofDrawer}
                            isOpen={isDrawerVisible}
                            orderId={orderId}
                            checkList={checkList}
                            onChecklistUpdate={() => {
                                refetch();
                                closeUploadProofDrawer();
                            }}
                            onOrderComplete={() => {
                                refetch();
                                closeUploadProofDrawer();
                            }}
                            isOrderCompleted={isOrderCompleted}
                        />
                    </>
                )}
            </Stack>
        );
    };

    return (
        <>
            <ResponsiveBox noMobile>{renderDesktopSkeleton()}</ResponsiveBox>
            <ResponsiveBox noDesktop>{renderMobileSkeleton()}</ResponsiveBox>
        </>
    );
};

export default CardBottom;
