import './polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const domNode = document.getElementById('app');
const root = createRoot(domNode);

const title = 'Locus | Transporter';

root.render(<App title={title} />);
