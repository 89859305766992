// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { TeamId } from './TeamId';
import { PhoneNumber } from './PhoneNumber';
import { RiderPersona } from './MasterRiderPersona';
import { RiderPersonaId } from './RiderPersonaId';
import { Limits } from './Limits';
import { SkillId } from './SkillId';

import type { TeamIdModelType } from './TeamId';
import type { PhoneNumberModelType } from './PhoneNumber';
import type { RiderPersonaModelType } from './MasterRiderPersona';
import type { RiderPersonaIdModelType } from './RiderPersonaId';
import type { LimitsModelType } from './Limits';
import type { SkillIdModelType } from './SkillId';

export type StatusEnum = 'ACTIVE' | 'INACTIVE';
export type RiderTypeEnum = 'RIDER' | 'CASHIER' | 'SORTER' | 'SALESMAN';

export type RiderModelType = {
    clientId: string,
    id: string,
    code: string,
    name: string,
    status: StatusEnum,
    version: number,
    customProperties: { [key: string]: string },
    phoneNumber: PhoneNumberModelType,
    teams: Array<TeamIdModelType>,
    type: RiderTypeEnum,
    riderPersona: ?RiderPersonaModelType,
    riderPersonaId: ?RiderPersonaIdModelType,
    skills: Array<SkillIdModelType>,
    limits: ?LimitsModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Rider extends ImmutableModel {
    static fromJS(json: RiderModelType): Rider {
        const state: Object = Object.assign({}, json);
        state.customProperties = Immutable.Map(state.customProperties);
        state.phoneNumber = PhoneNumber.fromJS(state.phoneNumber);
        state.teams = Immutable.List(state.teams).map((item) =>
            TeamId.fromJS(item)
        );
        state.riderPersona = state.riderPersona
            ? RiderPersona.fromJS(state.riderPersona)
            : state.riderPersona;
        state.riderPersonaId = state.riderPersonaId
            ? RiderPersonaId.fromJS(state.riderPersonaId)
            : state.riderPersonaId;
        state.skills = Immutable.List(state.skills).map((item) =>
            SkillId.fromJS(item)
        );
        state.limits = state.limits
            ? Limits.fromJS(state.limits)
            : state.limits;
        return new this(Immutable.Map(state));
    }

    toJS(): RiderModelType {
        return {
            clientId: this.clientId,
            id: this.id,
            code: this.code,
            name: this.name,
            status: this.status,
            version: this.version,
            customProperties: this.customProperties.toObject(),
            phoneNumber: this.phoneNumber.toJS(),
            teams: this.teams.toArray().map((item) => item.toJS()),
            type: this.type,
            riderPersona: this.riderPersona
                ? this.riderPersona.toJS()
                : this.riderPersona,
            riderPersonaId: this.riderPersonaId
                ? this.riderPersonaId.toJS()
                : this.riderPersonaId,
            skills: this.skills.toArray().map((item) => item.toJS()),
            limits: this.limits ? this.limits.toJS() : this.limits,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get code(): string {
        return this._state.get('code');
    }

    setCode(code: string): this {
        return this.clone(this._state.set('code', code));
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get status(): StatusEnum {
        return this._state.get('status');
    }

    setStatus(status: StatusEnum): this {
        return this.clone(this._state.set('status', status));
    }

    get version(): number {
        return this._state.get('version');
    }

    setVersion(version: number): this {
        return this.clone(this._state.set('version', version));
    }

    get customProperties(): Immutable.Map<string, string> {
        return this._state.get('customProperties');
    }

    setCustomProperties(customProperties: Immutable.Map<string, string>): this {
        return this.clone(
            this._state.set('customProperties', customProperties)
        );
    }

    get phoneNumber(): PhoneNumber {
        return this._state.get('phoneNumber');
    }

    setPhoneNumber(phoneNumber: PhoneNumber): this {
        return this.clone(this._state.set('phoneNumber', phoneNumber));
    }

    get teams(): Immutable.List<TeamId> {
        return this._state.get('teams');
    }

    setTeams(teams: Immutable.List<TeamId>): this {
        return this.clone(this._state.set('teams', teams));
    }

    get type(): RiderTypeEnum {
        return this._state.get('type');
    }

    setType(type: RiderTypeEnum): this {
        return this.clone(this._state.set('type', type));
    }

    get riderPersona(): ?RiderPersona {
        return this._state.get('riderPersona');
    }

    setRiderPersona(riderPersona: ?RiderPersona): this {
        return this.clone(this._state.set('riderPersona', riderPersona));
    }

    get riderPersonaId(): ?RiderPersonaId {
        return this._state.get('riderPersonaId');
    }

    setRiderPersonaId(riderPersonaId: ?RiderPersonaId): this {
        return this.clone(this._state.set('riderPersonaId', riderPersonaId));
    }

    get skills(): Immutable.List<SkillId> {
        return this._state.get('skills');
    }

    setSkills(skills: Immutable.List<SkillId>): this {
        return this.clone(this._state.set('skills', skills));
    }

    get limits(): ?Limits {
        return this._state.get('limits');
    }

    setLimits(limits: ?Limits): this {
        return this.clone(this._state.set('limits', limits));
    }
}
