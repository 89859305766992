//@flow
import * as Immutable from 'immutable';
import { TourSummary } from 'models/flow/TourSummary';
import type { TourSummaryModelType } from 'models/flow/TourSummary';
import { VisitResource } from './VisitResource';

class InternalTourSummary extends TourSummary {
    static fromJS(json: TourSummaryModelType): InternalTourSummary {
        const state: Object = Object.assign({}, json);
        state.volume = VisitResource.fromJS(state.volume);
        state.time = VisitResource.fromJS(state.time);
        state.violationTypes = state.violationTypes
            ? Immutable.List(state.violationTypes)
            : state.violationTypes;
        return new this(Immutable.Map(state));
    }

    get volume(): VisitResource {
        return this._state.get('volume');
    }

    get time(): VisitResource {
        return this._state.get('time');
    }
}

export { InternalTourSummary as TourSummary };
