//@flow
import * as Immutable from 'immutable';

import { OperationsTour } from 'models/flow/OperationsTour';
import type { OperationsTourModelType } from 'models/flow/OperationsTour';
import { VehicleTour } from './VehicleTour';
import { AssignmentDetails } from './AssignmentDetails';
import { OrderDetails } from './OrderDetails';

class InternalOperationsTour extends OperationsTour {
    static fromJS(json: OperationsTourModelType): InternalOperationsTour {
        const state: Object = Object.assign({}, json);
        state.currentTour = VehicleTour.fromJS(state.currentTour);
        state.executionTour = state.executionTour
            ? VehicleTour.fromJS(state.executionTour)
            : state.executionTour;
        state.dispatchedTour = state.dispatchedTour
            ? VehicleTour.fromJS(state.dispatchedTour)
            : state.dispatchedTour;
        state.assignmentDetails = AssignmentDetails.fromJS(
            state.assignmentDetails
        );
        state.orderDetails = Immutable.List(state.orderDetails).map((item) =>
            OrderDetails.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get executionTour(): ?VehicleTour {
        return this._state.get('executionTour');
    }

    get dispatchedTour(): ?VehicleTour {
        return this._state.get('dispatchedTour');
    }

    get assignmentDetails(): AssignmentDetails {
        return this._state.get('assignmentDetails');
    }

    get orderDetails(): Immutable.List<OrderDetails> {
        return this._state.get('orderDetails');
    }

    get currentTour(): VehicleTour {
        return this._state.get('currentTour');
    }
}

export { InternalOperationsTour as OperationsTour };
