// @flow
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    AuthProvider as UniversalAuthProvider,
    CrossTabLogoutSync,
} from '@locus-taxy/auth-react';

import AuthStateHandler from './AuthStateHandler';

import EnvConfig from 'config';

import { useClientId } from 'modules/clientId';

type Props = {
    children: React.Node,
};

const AuthProvider = ({ children }: Props): React.Node => {
    const redirectUri = window.location.origin;
    const clientId = useClientId();
    const navigate = useNavigate();

    const onRedirectCallback = (appState: $FixMe) => {
        navigate(appState?.returnTo || '/');
    };

    return (
        <UniversalAuthProvider
            domain={EnvConfig.auth.domain}
            clientId={EnvConfig.auth.client_id}
            audience={EnvConfig.auth.audience}
            apiEndPoint={EnvConfig.platformEndPoint}
            loginHint={`?${clientId}`}
            redirectUri={redirectUri}
            onRedirectCallback={onRedirectCallback}
        >
            <CrossTabLogoutSync />
            <AuthStateHandler>{children}</AuthStateHandler>
        </UniversalAuthProvider>
    );
};

export default AuthProvider;
