// @flow

import React, { type Node } from 'react';
import { Flex, Button } from '@locus-taxy/neo-ui';
import { useParams } from 'react-router-dom';
import { useTourSearch } from 'hooks/api';
import NoTourFound from './NoTourFound';
import TourCard from './TourCard';
import OrderList from './OrderList';
import useClientId from 'modules/clientId/useClientId';
import PageLoader from 'components/common/PageLoader';
import ResponsiveBox from 'components/common/ResponsiveBox';
import TourDetailsHeader from './TourDetailsHeader.jsx';
import { TourStatuses } from 'constants/TourStatusEnums';
import useTourCompletion from 'hooks/api/useTourCompletion';
import { useError } from 'hooks';

export const TourDetails = (): Node => {
    const { tourId } = useParams();
    const clientId = useClientId();
    const { mutateAsync } = useTourCompletion();
    const { onError } = useError();

    const {
        data: tour,
        isFetching,
        refetch,
        isError,
    } = useTourSearch({ clientId, tourId, onError });

    const handleTourComplete = async () => {
        await mutateAsync(
            { clientId, tourId },
            {
                onError,
                onSuccess: () => {
                    refetch();
                },
            }
        );
    };
    const { tourName, dispatchedTour, orderDetails, transporterTourStatus } =
        tour || {};

    const showMarkCompleteButton = [TourStatuses.IN_TRANSIT].includes(
        transporterTourStatus
    );

    const isTourClosed = transporterTourStatus === TourStatuses.CLOSED;

    return (
        <Flex flexDirection="column" width="100%" overflow="hidden">
            <TourDetailsHeader
                tourName={tourName}
                onTourComplete={handleTourComplete}
                showMarkCompleteButton={showMarkCompleteButton}
            />
            {isFetching ? <PageLoader /> : null}
            {!tour && !isFetching ? (
                <NoTourFound single isError={isError} />
            ) : null}
            {tour && !isFetching ? (
                <Flex
                    flexDirection="column"
                    width="100%"
                    p={3}
                    pb={0}
                    overflow="hidden"
                    height="100%"
                >
                    <TourCard tour={tour} minified refetchTours={refetch} />
                    {showMarkCompleteButton && (
                        <ResponsiveBox noDesktop mt={4}>
                            <Button
                                palette="success"
                                appearance="solid"
                                width="100%"
                                onClick={handleTourComplete}
                            >
                                Mark tour as complete
                            </Button>
                        </ResponsiveBox>
                    )}
                    <ResponsiveBox
                        noDesktop
                        isFlex
                        height="20px"
                        backgroundColor="grey.100"
                        ml={-4}
                        mr={-4}
                        mt={5}
                        borderBottomColor="grey.200"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                    />
                    <ResponsiveBox
                        isFlex
                        mt={4}
                        overflow="hidden"
                        maxWidth="1200px"
                        height="100%"
                        mobileSx={{
                            marginTop: 0,
                        }}
                    >
                        <OrderList
                            visits={dispatchedTour.visits}
                            orderDetails={orderDetails}
                            refetch={refetch}
                            isTourClosed={isTourClosed}
                        />
                    </ResponsiveBox>
                </Flex>
            ) : null}
        </Flex>
    );
};

export default TourDetails;
