// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

export type PaginationInfoModelType = {
    currentPage: number,
    numberOfPages: number,
    total: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class PaginationInfo extends ImmutableModel {
    static fromJS(json: PaginationInfoModelType): PaginationInfo {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): PaginationInfoModelType {
        return {
            currentPage: this.currentPage,
            numberOfPages: this.numberOfPages,
            total: this.total,
        };
    }

    get currentPage(): number {
        return this._state.get('currentPage');
    }

    setCurrentPage(currentPage: number): this {
        return this.clone(this._state.set('currentPage', currentPage));
    }

    get numberOfPages(): number {
        return this._state.get('numberOfPages');
    }

    setNumberOfPages(numberOfPages: number): this {
        return this.clone(this._state.set('numberOfPages', numberOfPages));
    }

    get total(): number {
        return this._state.get('total');
    }

    setTotal(total: number): this {
        return this.clone(this._state.set('total', total));
    }
}
