// @flow

import React, { type Node } from 'react';
import { Button, Text, Flex } from '@locus-taxy/neo-ui';
import { Link } from 'react-router-dom';
import AppHeader from 'components/common/AppHeader';
import ResponsiveBox from 'components/common/ResponsiveBox';

type Props = {
    tourName: string,
    onTourComplete: () => void,
    showMarkCompleteButton: boolean,
};

export const TourDetailsHeader = ({
    tourName,
    onTourComplete,
    showMarkCompleteButton,
}: Props): Node => {
    return (
        <AppHeader>
            <ResponsiveBox
                isFlex
                alignItems="center"
                width="100%"
                justifyContent="space-between"
                mobileSx={{ justifyContent: 'flex-start' }}
                overflow="hidden"
            >
                <Flex alignItems="center" overflow="hidden">
                    <Link to="/tours">
                        <Button
                            icon="arrow_left"
                            palette="primary"
                            appearance="solid"
                            shape="round"
                            mr={4}
                        />
                    </Link>
                    <ResponsiveBox noMobile>
                        <Text fontSize={3} color="grey.800" fontWeight="600">
                            {tourName}
                        </Text>
                    </ResponsiveBox>
                </Flex>
                {showMarkCompleteButton && (
                    <ResponsiveBox noMobile>
                        <Flex flexShrink={0}>
                            <Button
                                appearance="solid"
                                palette="success"
                                onClick={onTourComplete}
                            >
                                Mark as Complete
                            </Button>
                        </Flex>
                    </ResponsiveBox>
                )}
                <ResponsiveBox noDesktop width="100%" overflow="hidden">
                    <Text
                        fontSize={4}
                        color="grey.800"
                        fontWeight="600"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        color="white"
                    >
                        {tourName}
                    </Text>
                </ResponsiveBox>
            </ResponsiveBox>
        </AppHeader>
    );
};

export default TourDetailsHeader;
