// @flow
import React, { type Node } from 'react';
import { Stack, Input, Box } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import ChecklistHeader from './ChecklistHeader';
import type { ExtraProps } from './helper';

type Props = {
    checklistConfig: ChecklistItem,
    onChange: (value: string) => void,
    value: boolean,
    extraProps?: ExtraProps,
};

const InputSelector = ({ checklistConfig, onChange, value }: Props): Node => {
    const { item, optional } = checklistConfig;

    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    return (
        <Stack space="small">
            <ChecklistHeader optional={optional}>{item} </ChecklistHeader>
            <Box>
                <Input
                    onChange={handleChange}
                    value={value || ''}
                    placeholder=""
                />
            </Box>
        </Stack>
    );
};

export default InputSelector;
