//@flow
import * as Immutable from 'immutable';

import { AssignmentDetails } from 'models/flow/AssignmentDetails';
import type { AssignmentDetailsModelType } from 'models/flow/AssignmentDetails';

class InternalAssignmentDetails extends AssignmentDetails {
    static fromJS(json: AssignmentDetailsModelType): InternalAssignmentDetails {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalAssignmentDetails as AssignmentDetails };
