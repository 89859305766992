// @flow
import * as React from 'react';

import { useStore } from './store';
import ClientIdInputContainer from './ClientIdInputContainer';

import useClientIdFromUrl from './useClientIdFromUrl';

type Props = {
    children: React.Node,
};

const ClientIdProvider = ({ children }: Props): React.Node => {
    const clientIdFromStore = useStore((state) => state.clientId);
    const saveClientId = useStore((state) => state.setClientId);
    const clientIdFromUrl = useClientIdFromUrl();

    const clientId = clientIdFromStore || clientIdFromUrl;

    // use store as a single source of truth for reading clientId
    React.useEffect(() => {
        saveClientId(clientId);
    }, [clientId, saveClientId]);

    if (!clientId) {
        return <ClientIdInputContainer />;
    }

    if (!clientIdFromStore) {
        // wait for clientId to be saved to store
        return null;
    }

    return children;
};

export default ClientIdProvider;
