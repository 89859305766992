// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo
import type { SortingInfoModelType } from './SortingInfo';
import type { FilterModelType } from './Filter';

import { SortingInfo } from './SortingInfo';
import { Filter } from './Filter';

export type BulkGetRequestModelType = {
    filters?: Array<FilterModelType>,
    sortingInfo?: Array<SortingInfoModelType>,
    page?: number,
    size?: number,
    fetchMinimal?: boolean,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class BulkGetRequest extends ImmutableModel {
    static fromJS(json: BulkGetRequestModelType): BulkGetRequest {
        const state: Object = Object.assign({}, json);
        state.filters = state.filters
            ? Immutable.List(state.filters).map((item) => Filter.fromJS(item))
            : state.filters;
        state.sortingInfo = state.sortingInfo
            ? Immutable.List(state.sortingInfo).map((item) =>
                  SortingInfo.fromJS(item)
              )
            : state.sortingInfo;
        return new this(Immutable.Map(state));
    }

    toJS(): BulkGetRequestModelType {
        const js: BulkGetRequestModelType = {};

        if (this.filters != null) {
            js.filters = this.filters
                ? this.filters.toArray().map((item) => item.toJS())
                : this.filters;
        }

        if (this.sortingInfo != null) {
            js.sortingInfo = this.sortingInfo
                ? this.sortingInfo.toArray().map((item) => item.toJS())
                : this.sortingInfo;
        }

        if (this.page != null) {
            js.page = this.page;
        }

        if (this.size != null) {
            js.size = this.size;
        }

        if (this.fetchMinimal != null) {
            js.fetchMinimal = this.fetchMinimal;
        }

        return js;
    }

    get filters(): ?Immutable.List<Filter> {
        return this._state.get('filters');
    }

    setFilters(filters: ?Immutable.List<Filter>): this {
        return this.clone(this._state.set('filters', filters));
    }

    get sortingInfo(): ?Immutable.List<SortingInfo> {
        return this._state.get('sortingInfo');
    }

    setSortingInfo(sortingInfo: ?Immutable.List<SortingInfo>): this {
        return this.clone(this._state.set('sortingInfo', sortingInfo));
    }

    get page(): ?number {
        return this._state.get('page');
    }

    setPage(page: ?number): this {
        return this.clone(this._state.set('page', page));
    }

    get size(): ?number {
        return this._state.get('size');
    }

    setSize(size: ?number): this {
        return this.clone(this._state.set('size', size));
    }

    get fetchMinimal(): ?boolean {
        return this._state.get('fetchMinimal');
    }

    setFetchMinimal(fetchMinimal: ?boolean): this {
        return this.clone(this._state.set('fetchMinimal', fetchMinimal));
    }
}
