// @flow
import React, { type Node } from 'react';
import { Stack } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import FileSelector from 'components/common/FileSelector/FileSelector';
import ChecklistHeader from './ChecklistHeader';
import type { ExtraProps } from './helper';

type Props = {
    checklistConfig: ChecklistItem,
    onChange: (file: File) => void,
    value: string,
    extraProps?: ExtraProps,
};

const PhotoSelector = ({
    checklistConfig,
    onChange,
    value: imageUrl,
    extraProps,
}: Props): Node => {
    const { item, optional } = checklistConfig;
    const { fileName } = extraProps || {};

    return (
        <Stack space="small">
            <ChecklistHeader optional={optional}>{item}</ChecklistHeader>
            <FileSelector
                onFileSelect={onChange}
                fileUrl={imageUrl}
                fileName={fileName}
            />
        </Stack>
    );
};

export default PhotoSelector;
