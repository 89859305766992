// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

type OrderEnum = 'ASC' | 'DESC';
export type SortingInfoModelType = {
    name: string,
    order: OrderEnum,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class SortingInfo extends ImmutableModel {
    static fromJS(json: SortingInfoModelType): SortingInfo {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): SortingInfoModelType {
        return {
            name: this.name,
            order: this.order,
        };
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get order(): OrderEnum {
        return this._state.get('order');
    }

    setOrder(order: OrderEnum): this {
        return this.clone(this._state.set('order', order));
    }
}
