// @flow
import React, { type Node, useState, useEffect } from 'react';
import { Flex } from '@locus-taxy/neo-ui';
import TourStatusFilterContainer from 'components/dashboard/tours/TourStatusFilterContainer';
import TourList, {
    PageOffsetNumber,
} from 'components/dashboard/tours/TourList';
import { useGetTours } from 'hooks';
import { useClientId } from 'modules/clientId';
import { getBulkGetRequest } from './helper';
import { TourStatuses } from 'constants';
import NoTourFound from 'components/dashboard/tours/NoTourFound';
import useSelectedDate from 'hooks/useSelectedDate';
import { OperationsTour } from 'models/internal/OperationsTour';
import { VALID_TOUR_STATUSES } from 'constants/TourStatusEnums';
import ResponsiveBox from 'components/common/ResponsiveBox';
import ToursHeader from './ToursHeader';

const TourListContainer = (): Node => {
    const [selectedTourStatus, setSelectedTourStatus] = useState('all');
    const [allTours, setAllTours] = useState<Array<OperationsTour>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { selectedDate } = useSelectedDate();

    const clientId = useClientId();
    const { data, isLoading, refetch } = useGetTours({
        clientId,
        requestBody: getBulkGetRequest({
            date: selectedDate,
            status:
                selectedTourStatus === 'all'
                    ? VALID_TOUR_STATUSES
                    : selectedTourStatus,
            currentPage,
            pageOffset: PageOffsetNumber,
        }),
    });

    const handleRefetch = () => {
        setAllTours([]);
        refetch();
    };

    const handleStatusFilterChange = (
        status: $Values<typeof TourStatuses> | 'all'
    ) => {
        setAllTours([]);
        setSelectedTourStatus(status);
    };

    const { paginationInfo } = data || {};
    const hasEmptyTours = !isLoading && allTours && !allTours.length;

    useEffect(() => {
        // set paginated data
        if (data) {
            const { tours } = data;

            setAllTours([...allTours, ...tours]);
        }
    }, [data]);

    // clear tours whenever date or filter changes
    useEffect(() => {
        setAllTours([]);
    }, [selectedDate, selectedTourStatus]);

    return (
        <ResponsiveBox
            isFlex
            flexDirection="column"
            width="100%"
            mobileSx={{ background: '#efefef' }}
        >
            <ToursHeader />
            <ResponsiveBox
                mt={4}
                px={4}
                isFlex
                mobileSx={{
                    overflowX: 'auto',
                    height: '40px',
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                <TourStatusFilterContainer
                    selectedTourStatus={selectedTourStatus}
                    onStatusFilterChange={handleStatusFilterChange}
                />
            </ResponsiveBox>
            {hasEmptyTours ? (
                <NoTourFound />
            ) : (
                <ResponsiveBox
                    isFlex
                    p={4}
                    pb={0}
                    flexDirection="column"
                    overflow="hidden"
                    width="100%"
                    height="100%"
                    mobileSx={{
                        padding: 0,
                    }}
                >
                    <Flex mt={5} overflow="hidden" width="100%" height="100%">
                        <TourList
                            tours={allTours}
                            isLoading={isLoading}
                            refetchTours={handleRefetch}
                            paginationInfo={paginationInfo}
                            onPageChange={setCurrentPage}
                        />
                    </Flex>
                </ResponsiveBox>
            )}
        </ResponsiveBox>
    );
};

export default TourListContainer;
