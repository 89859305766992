// @flow
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import {
    ThemeProvider,
    GlobalStyle,
    Box,
    ToastProvider,
} from '@locus-taxy/neo-ui';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom';
import { AuthProvider, AuthBoundary } from 'modules/auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppLoginContainer } from 'components/login';
import { AppChangePasswordContainer } from 'components/change-password';

import Dashboard from 'components/dashboard';
import TourListContainer from 'components/dashboard/tours/TourListContainer';
import TourDetailsContainer from 'components/dashboard/tours/TourDetailsContainer';
import { ClientIdProvider } from 'modules/clientId';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import i18n from './i18n';

const queryClient = new QueryClient();

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/login" element={<AppLoginContainer />} />
            <Route
                exact
                path="/change-password"
                element={<AppChangePasswordContainer />}
            />
            <Route
                path="/"
                element={
                    <AuthBoundary>
                        <Dashboard />
                    </AuthBoundary>
                }
                errorElement={<div>Not Found!</div>}
            >
                <Route path="tours" element={<TourListContainer />} />
                <Route
                    path="tours/tour-details/:tourId"
                    element={<TourDetailsContainer />}
                />
                <Route path="/" element={<Navigate to="/tours" replace />} />
            </Route>
        </Routes>
    );
};

function App(): React.Node {
    return (
        <ThemeProvider>
            <I18nextProvider i18n={i18n}>
                <ToastProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <GlobalStyle />
                        <Router>
                            <ClientIdProvider>
                                <AuthProvider>
                                    <QueryClientProvider client={queryClient}>
                                        <Box width="100%" height="100%">
                                            <AppRoutes />
                                        </Box>
                                    </QueryClientProvider>
                                </AuthProvider>
                            </ClientIdProvider>
                        </Router>
                    </LocalizationProvider>
                </ToastProvider>
            </I18nextProvider>
        </ThemeProvider>
    );
}

export default App;
