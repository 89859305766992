// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import strings from './i18n-strings/en-US.json';

i18n.use(initReactI18next) // bind react-i18next to the instance
    .init({
        fallbackLng: 'en-US',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        resources: {
            'en-US': strings,
        },
    });

export default i18n;
