// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

export type PhoneNumberModelType = {
    phoneNumber: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class PhoneNumber extends ImmutableModel {
    static fromJS(json: PhoneNumberModelType): PhoneNumber {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): PhoneNumberModelType {
        return {
            phoneNumber: this.phoneNumber,
        };
    }

    get phoneNumber(): string {
        return this._state.get('phoneNumber');
    }

    setPhoneNumber(phoneNumber: string): this {
        return this.clone(this._state.set('phoneNumber', phoneNumber));
    }
}
