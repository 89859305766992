// @flow
import React, { type Node } from 'react';
import { Heading, Button, Box, Flex } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import { type List } from 'immutable';
import ChecklistItems from './ChecklistItems';
import Drawer from '@mui/material/Drawer';
import Position from '@locus-taxy/neo-ui/dist/es/core/position/Position';
import usePodUpload from './usePodUpload';
import type { ChecklistValues } from 'models/flow/OrderDetails';

type Props = {
    onClose: () => void,
    isOpen: boolean,
    orderId: string,
    checkList: List<ChecklistItem>,
    onChecklistUpdate: () => void,
    onOrderComplete: () => void,
    checklistValues: ChecklistValues,
    disableActions: boolean,
    isOrderCompleted: boolean,
};

const UploadProofDrawer = ({
    onClose,
    isOpen,
    orderId,
    checkList,
    onChecklistUpdate,
    onOrderComplete,
    checklistValues: checklistValuesFromResponse,
    disableActions,
    isOrderCompleted,
}: Props): Node => {
    const {
        filesMap,
        setFilesMap,
        checklistValues,
        setChecklistValues,
        isSaving,
        handleSaveDetails,
        resetValues,
        canCloseOrder,
        completeOrder,
        isCompletingOrder,
    } = usePodUpload({
        orderId,
        onChecklistUpdate,
        checklistValuesFromResponse,
        checklistItems: checkList,
        onOrderComplete,
        isOpen,
    });

    const isEmptyCheckList = checkList.isEmpty();

    return (
        <Drawer onClose={onClose} open={isOpen} anchor="bottom">
            <Box maxHeight={isEmptyCheckList ? '300px' : '90vh'} pt={0}>
                <Position
                    position="sticky"
                    top="0"
                    zIndex={1000}
                    backgroundColor="white"
                    borderBottomStyle="solid"
                    borderBottomColor="grey.200"
                    borderBottomWidth="2px"
                >
                    <Flex
                        p={4}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Heading>
                            Proof of Delivery Upload for - {orderId}
                        </Heading>
                        <Button shape="round" onClick={onClose} icon="cross" />
                    </Flex>
                </Position>
                <Box backgroundColor="grey.100" p={4}>
                    <ChecklistItems
                        checklistItems={checkList}
                        filesMap={filesMap}
                        setFilesMap={setFilesMap}
                        checklistValues={checklistValues}
                        setChecklistValues={setChecklistValues}
                    />
                </Box>
                <Position
                    position="sticky"
                    bottom="0"
                    borderTopStyle="solid"
                    borderTopColor="grey.200"
                    borderTopWidth="2px"
                >
                    <Flex
                        justifyContent="flex-end"
                        p={4}
                        backgroundColor="white"
                    >
                        <Button
                            appearance="outline"
                            palette="primary"
                            onClick={resetValues}
                            disabled={isEmptyCheckList || disableActions}
                        >
                            reset
                        </Button>
                        {!isOrderCompleted && (
                            <Button
                                appearance="solid"
                                palette="success"
                                ml={4}
                                onClick={completeOrder}
                                isLoading={isCompletingOrder}
                                disabled={
                                    isEmptyCheckList ||
                                    canCloseOrder ||
                                    isCompletingOrder ||
                                    disableActions
                                }
                            >
                                mark complete
                            </Button>
                        )}
                        {isOrderCompleted && (
                            <Button
                                ml={4}
                                appearance="solid"
                                palette="primary"
                                onClick={handleSaveDetails}
                                isLoading={isSaving}
                                disabled={
                                    isEmptyCheckList ||
                                    isSaving ||
                                    disableActions
                                }
                            >
                                save details
                            </Button>
                        )}
                    </Flex>
                </Position>
            </Box>
        </Drawer>
    );
};

export default UploadProofDrawer;
