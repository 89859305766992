// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

import type { ChecklistItemModelType } from './ChecklistItem';
import type { ChecklistModelType } from './Checklist';

import { ChecklistItem } from './ChecklistItem';
import { Checklist } from './Checklist';

export type ChecklistValues = {
    [key: string]: string | boolean,
};

export type OrderStatuses =
    | 'OPEN'
    | 'CONFIRMED'
    | 'PLANNING'
    | 'PLANNED'
    | 'ASSIGNED'
    | 'EXECUTING'
    | 'COMPLETED'
    | 'CANCELLED'
    | 'PARKED'
    | 'RECEIVED';

export type OrderDetailsModelType = {
    orderId: string,
    alternateId: null,
    visitSequence: number,
    scanId: null,
    locationId: null,
    customerName: null,
    customerContact: null,
    orderStatus: OrderStatuses,
    orderSubStatus: null,
    checklistValues: ChecklistValues,
    checklist: ChecklistModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class OrderDetails extends ImmutableModel {
    static fromJS(json: OrderDetailsModelType): OrderDetails {
        const state: Object = Object.assign({}, json);
        state.checklist = Checklist.fromJS(state.checklist);
        return new this(Immutable.Map(state));
    }

    toJS(): OrderDetailsModelType {
        return {
            orderId: this.orderId,
            alternateId: this.alternateId,
            visitSequence: this.visitSequence,
            scanId: this.scanId,
            locationId: this.locationId,
            customerName: this.customerName,
            customerContact: this.customerContact,
            orderStatus: this.orderStatus,
            orderSubStatus: this.orderSubStatus,
            checklistValues: this.checklistValues,
            checklist: this.checklist.toJS(),
        };
    }

    get orderId(): string {
        return this._state.get('orderId');
    }

    setOrderId(orderId: string): this {
        return this.clone(this._state.set('orderId', orderId));
    }

    get alternateId(): null {
        return this._state.get('alternateId');
    }

    setAlternateId(alternateId: null): this {
        return this.clone(this._state.set('alternateId', alternateId));
    }

    get visitSequence(): number {
        return this._state.get('visitSequence');
    }

    setVisitSequence(visitSequence: number): this {
        return this.clone(this._state.set('visitSequence', visitSequence));
    }

    get scanId(): null {
        return this._state.get('scanId');
    }

    setScanId(scanId: null): this {
        return this.clone(this._state.set('scanId', scanId));
    }

    get locationId(): null {
        return this._state.get('locationId');
    }

    setLocationId(locationId: null): this {
        return this.clone(this._state.set('locationId', locationId));
    }

    get customerName(): null {
        return this._state.get('customerName');
    }

    setCustomerName(customerName: null): this {
        return this.clone(this._state.set('customerName', customerName));
    }

    get customerContact(): null {
        return this._state.get('customerContact');
    }

    setCustomerContact(customerContact: null): this {
        return this.clone(this._state.set('customerContact', customerContact));
    }

    get orderStatus(): OrderStatuses {
        return this._state.get('orderStatus');
    }

    setOrderStatus(orderStatus: OrderStatuses): this {
        return this.clone(this._state.set('orderStatus', orderStatus));
    }

    get orderSubStatus(): null {
        return this._state.get('orderSubStatus');
    }

    setOrderSubStatus(orderSubStatus: null): this {
        return this.clone(this._state.set('orderSubStatus', orderSubStatus));
    }

    get checklistValues(): ChecklistValues {
        return this._state.get('checklistValues');
    }

    setChecklistValues(checklistValues: ChecklistValues): this {
        return this.clone(this._state.set('checklistValues', checklistValues));
    }

    get checklist(): Checklist {
        return this._state.get('checklist');
    }

    setChecklist(checklist: Checklist): this {
        return this.clone(this._state.set('checklist', checklist));
    }
}
