//@flow
import * as Immutable from 'immutable';

import { VolumeLimit } from 'models/flow/VolumeLimit';
import type { VolumeLimitModelType } from 'models/flow/VolumeLimit';

class InternalVolumeLimit extends VolumeLimit {
    static fromJS(json: VolumeLimitModelType): InternalVolumeLimit {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalVolumeLimit as VolumeLimit };
