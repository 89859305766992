// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

import { VehicleModelId } from './VehicleModelId';
import { VehicleModel } from './VehicleModel';
import { ResourceLimit } from './ResourceLimit';
import { Skill } from './Skill';

import type { SkillModelType } from './Skill';
import type { VehicleModelIdModelType } from './VehicleModelId';
import type { ResourceLimitModelType } from './ResourceLimit';
import type { VehicleModelModelType } from './VehicleModel';

export type StatusType = 'ACTIVE' | 'INACTIVE';

export type VehicleModelType = {
    clientId: string,
    id: string,
    name: ?string,
    code: string,
    status: StatusType,
    version: number,
    vehicleModelId: VehicleModelIdModelType,
    vehicleModel: VehicleModelModelType,
    resourceLimits: ?Array<ResourceLimitModelType>,
    skills: ?{ tags: Array<SkillModelType> },
    additionalresourceLimits: Array<ResourceLimitModelType>,
    additionalSkills: ?Array<SkillModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Vehicle extends ImmutableModel {
    static fromJS(json: VehicleModelType): Vehicle {
        const state: Object = Object.assign({}, json);
        state.vehicleModelId = VehicleModelId.fromJS(state.vehicleModelId);
        state.vehicleModel = VehicleModel.fromJS(state.vehicleModel);
        state.resourceLimits = state.resourceLimits
            ? Immutable.List(state.resourceLimits).map((item) =>
                  ResourceLimit.fromJS(item)
              )
            : state.resourceLimits;
        state.additionalresourceLimits = Immutable.List(
            state.additionalresourceLimits
        ).map((item) => ResourceLimit.fromJS(item));
        state.additionalSkills = state.additionalSkills
            ? Immutable.List(state.additionalSkills).map((item) =>
                  Skill.fromJS(item)
              )
            : state.additionalSkills;
        return new this(Immutable.Map(state));
    }

    toJS(): VehicleModelType {
        return {
            clientId: this.clientId,
            id: this.id,
            name: this.name,
            code: this.code,
            status: this.status,
            version: this.version,
            vehicleModelId: this.vehicleModelId.toJS(),
            vehicleModel: this.vehicleModel.toJS(),
            resourceLimits: this.resourceLimits
                ? this.resourceLimits.toArray().map((item) => item.toJS())
                : this.resourceLimits,
            skills: this.skills,
            additionalresourceLimits: this.additionalresourceLimits
                .toArray()
                .map((item) => item.toJS()),
            additionalSkills: this.additionalSkills
                ? this.additionalSkills.toArray().map((item) => item.toJS())
                : this.additionalSkills,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get name(): ?string {
        return this._state.get('name');
    }

    setName(name: ?string): this {
        return this.clone(this._state.set('name', name));
    }

    get code(): string {
        return this._state.get('code');
    }

    setCode(code: string): this {
        return this.clone(this._state.set('code', code));
    }

    get status(): StatusType {
        return this._state.get('status');
    }

    setStatus(status: StatusType): this {
        return this.clone(this._state.set('status', status));
    }

    get version(): number {
        return this._state.get('version');
    }

    setVersion(version: number): this {
        return this.clone(this._state.set('version', version));
    }

    get vehicleModelId(): VehicleModelId {
        return this._state.get('vehicleModelId');
    }

    setVehicleModelId(vehicleModelId: VehicleModelId): this {
        return this.clone(this._state.set('vehicleModelId', vehicleModelId));
    }

    get vehicleModel(): VehicleModel {
        return this._state.get('vehicleModel');
    }

    setVehicleModel(vehicleModel: VehicleModel): this {
        return this.clone(this._state.set('vehicleModel', vehicleModel));
    }

    get resourceLimits(): ?Immutable.List<ResourceLimit> {
        return this._state.get('resourceLimits');
    }

    setResourceLimits(resourceLimits: ?Immutable.List<ResourceLimit>): this {
        return this.clone(this._state.set('resourceLimits', resourceLimits));
    }

    get skills(): ?{ tags: Array<SkillModelType> } {
        return this._state.get('skills');
    }

    setSkills(skills: ?{ tags: Array<SkillModelType> }): this {
        return this.clone(this._state.set('skills', skills));
    }

    get additionalresourceLimits(): Immutable.List<ResourceLimit> {
        return this._state.get('additionalresourceLimits');
    }

    setAdditionalresourceLimits(
        additionalresourceLimits: Immutable.List<ResourceLimit>
    ): this {
        return this.clone(
            this._state.set(
                'additionalresourceLimits',
                additionalresourceLimits
            )
        );
    }

    get additionalSkills(): ?Immutable.List<Skill> {
        return this._state.get('additionalSkills');
    }

    setAdditionalSkills(additionalSkills: ?Immutable.List<Skill>): this {
        return this.clone(
            this._state.set('additionalSkills', additionalSkills)
        );
    }
}
