// @flow
import * as React from 'react';
import {
    Columns,
    Column,
    Stack,
    Box,
    Position,
    Text,
    Icon,
} from '@locus-taxy/neo-ui';
import { useTranslation } from 'react-i18next';
import { PasswordStrengthCheckEnum } from './types';

import usePasswordStrength from './usePasswordStrength';

import { MinimalPersonnel } from 'models/internal/MinimalPersonnel';

type Props = {
    password: string,
    minimalPersonnel: MinimalPersonnel,
};

const PasswordStrength = ({
    password,
    minimalPersonnel,
}: Props): React.Node => {
    const result = usePasswordStrength({ password, minimalPersonnel });
    const { t } = useTranslation('common');

    const renderMessage = (rule) => {
        switch (rule.code) {
            case PasswordStrengthCheckEnum.containsAtLeast: {
                return t('passwordStrength.containsAtLeast', {
                    limit: rule.format[0],
                    typeCount: rule.format[1],
                });
            }
            case PasswordStrengthCheckEnum.identicalChars: {
                return t('passwordStrength.identicalChars', {
                    limit: rule.format[0],
                    example: rule.format[1],
                });
            }
            case PasswordStrengthCheckEnum.nonEmpty: {
                return t('passwordStrength.nonEmpty');
            }
            case PasswordStrengthCheckEnum.numbers: {
                return t('passwordStrength.numbers');
            }
            case PasswordStrengthCheckEnum.alphabets: {
                return t('passwordStrength.alphabets');
            }
            case PasswordStrengthCheckEnum.lengthAtLeast: {
                return t('passwordStrength.lengthAtLeast', {
                    limit: rule.format[0],
                });
            }
            case PasswordStrengthCheckEnum.lowerCase: {
                return t('passwordStrength.lowerCase');
            }
            case PasswordStrengthCheckEnum.shouldContain: {
                return t('passwordStrength.shouldContain');
            }
            case PasswordStrengthCheckEnum.specialCharacters: {
                return t('passwordStrength.specialCharacters');
            }
            case PasswordStrengthCheckEnum.upperCase: {
                return t('passwordStrength.upperCase');
            }
            default: {
                // warning(false, 'Unexpected code value `${rule.code}`');
                return null;
            }
        }
    };

    const renderItem = (rule) => {
        return (
            <Columns space="xxSmall">
                {rule.verified ? (
                    <Icon size="medium" icon="check_circle" color="green.500" />
                ) : (
                    <Icon size="medium" icon="cross_circle" color="red.500" />
                )}
                <Text fontSize={1}>{renderMessage(rule)}</Text>
            </Columns>
        );
    };

    const renderRule = (rule) => {
        return (
            <Stack space="xSmall">
                {renderItem(rule)}
                {rule.items && rule.items.length > 0 ? (
                    <Columns>
                        <Column width={'content'}>
                            <Box width="15px" />
                        </Column>
                        <Stack space="xSmall">
                            {rule.items.map(renderItem)}
                        </Stack>
                    </Columns>
                ) : null}
            </Stack>
        );
    };

    return (
        <Position
            position="relative"
            sx={{ borderRadius: '2px' }}
            backgroundColor="grey.100"
            padding={4}
        >
            <Stack space="small">{result.rules.map(renderRule)}</Stack>
        </Position>
    );
};

export default PasswordStrength;
