// @flow
import React, { type Node } from 'react';
import { Stack, RadioButtons, Box } from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import ChecklistHeader from './ChecklistHeader';
import type { ExtraProps } from './helper';

type Props = {
    checklistConfig: ChecklistItem,
    onChange: (value: string) => void,
    value: boolean,
    extraProps?: ExtraProps,
};

const SingleChoiceSelector = ({
    checklistConfig,
    onChange,
    value,
}: Props): Node => {
    const { item, possibleValues, optional } = checklistConfig;

    const handleChange = ({ key }: { key: string, value: string }) => {
        onChange(key);
    };

    const options = possibleValues.map((value) => ({
        value,
        key: value,
    }));

    return (
        <Stack space="small">
            <ChecklistHeader optional={optional}>{item} </ChecklistHeader>
            <Box p={4} pt={0}>
                <RadioButtons
                    value={value}
                    label=""
                    options={options}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    );
};

export default SingleChoiceSelector;
