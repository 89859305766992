// @flow
import { useMutation, type MutationResult } from '@tanstack/react-query';
import { omsInstance } from 'middleware/api';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    clientId: string,
    orderId: string,
    file: File,
};

const useFileUpload = (): MutationResult<{ url: string }> => {
    return useMutation(({ clientId, orderId, file }: Props) => {
        const formData = new FormData();
        formData.append('file', file);

        return omsInstance.post(
            `/client/${clientId}/order/${orderId}/file/${uuidv4()}`,
            formData
        );
    });
};

export default useFileUpload;
