//@flow
import * as Immutable from 'immutable';

import { Checklist } from 'models/flow/Checklist';
import type { ChecklistModelType } from 'models/flow/Checklist';
import { ChecklistItem } from './ChecklistItem';

class InternalChecklist extends Checklist {
    static fromJS(json: ChecklistModelType): InternalChecklist {
        const state: Object = Object.assign({}, json);
        state.customerCompleted = Immutable.List(state.customerCompleted).map(
            (item) => ChecklistItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get customerCompleted(): Immutable.List<ChecklistItem> {
        return this._state.get('customerCompleted');
    }
}

export { InternalChecklist as Checklist };
