// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

export type StatusTypeEnum = 'ENABLED' | 'DISABLED';

export type TeamIdModelType = {
    description: string,
    clientId: string,
    teamId: string,
    status: StatusTypeEnum,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TeamId extends ImmutableModel {
    static fromJS(json: TeamIdModelType): TeamId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): TeamIdModelType {
        return {
            description: this.description,
            clientId: this.clientId,
            teamId: this.teamId,
            status: this.status,
        };
    }

    get description(): string {
        return this._state.get('description');
    }

    setDescription(description: string): this {
        return this.clone(this._state.set('description', description));
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get teamId(): string {
        return this._state.get('teamId');
    }

    setTeamId(teamId: string): this {
        return this.clone(this._state.set('teamId', teamId));
    }

    get status(): StatusTypeEnum {
        return this._state.get('status');
    }

    setStatus(status: StatusTypeEnum): this {
        return this.clone(this._state.set('status', status));
    }
}
