// @flow
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useStore = create(
    persist(
        (set) => ({
            clientId: null,
            setClientId: (clientId) => set({ clientId }),
            clear: () => set({ clientId: null }),
        }),
        {
            name: 'clientId',
        }
    )
);

export const clearClientId = () => {
    useStore.persist.clearStorage();
};
