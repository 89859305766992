// @flow
import invariant from 'invariant';

import { useStore } from './store';

const useClientId = (): string => {
    const clientId = useStore((state) => state.clientId);
    invariant(
        clientId,
        'clientId not set. Note: useClientId must be called within a ClientIdProvider'
    );
    return clientId;
};

export default useClientId;
