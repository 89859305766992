//@flow
import * as React from 'react';

import EnvConfig from 'config';

import some from 'lodash/some';

const useClientIdFromUrl = (): ?string => {
    /**
     * gets the client Id from the URL
     * matches urls like https://{clientId}.hostname
     */
    const clientId = React.useMemo(() => {
        var hostnameFromUrl: string = window.location.host;
        var { dashboardHostNames } = EnvConfig;

        if (dashboardHostNames.indexOf(hostnameFromUrl) > -1) {
            // Direct match, so no alias.
            return;
        }
        const isHostRecognized = some(dashboardHostNames, (hostName) => {
            return hostnameFromUrl.endsWith(hostName);
        });
        if (isHostRecognized) {
            const parts = hostnameFromUrl.split('.');
            return parts[0];
        }
    }, []);

    return clientId;
};

export default useClientIdFromUrl;
