// @flow
import * as Immutable from 'immutable';
import ImmutableModel from './ImmutableModel';

import { VehicleTour } from './VehicleTour';
import { AssignmentDetails } from './AssignmentDetails';
import { OrderDetails } from './OrderDetails';

import type { VehicleTourModelType } from './VehicleTour';
import type { AssignmentDetailsModelType } from './AssignmentDetails';
import type { OrderDetailsModelType } from './OrderDetails';

type TourStatusTypes =
    | 'READY_TO_DISPATCH'
    | 'IN_TRANSIT'
    | 'PENDING_CLOSURE'
    | 'CLOSED';

export type OperationsTourModelType = {
    clientId: string,
    id: string,
    tourName: ?string,
    teamId: string,
    tourDate: string,
    status: TourStatusTypes,
    transporterTourStatus: TourStatusTypes,
    currentTour: VehicleTourModelType,
    executionTour: ?VehicleTourModelType,
    dispatchedTour: ?VehicleTourModelType,
    assignmentDetails: AssignmentDetailsModelType,
    orderDetails: Array<OrderDetailsModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class OperationsTour extends ImmutableModel {
    static fromJS(json: OperationsTourModelType): OperationsTour {
        const state: Object = Object.assign({}, json);
        state.currentTour = VehicleTour.fromJS(state.currentTour);
        state.executionTour = state.executionTour
            ? VehicleTour.fromJS(state.executionTour)
            : state.executionTour;
        state.dispatchedTour = state.dispatchedTour
            ? VehicleTour.fromJS(state.dispatchedTour)
            : state.dispatchedTour;
        state.assignmentDetails = AssignmentDetails.fromJS(
            state.assignmentDetails
        );
        state.orderDetails = Immutable.List(state.orderDetails).map((item) =>
            OrderDetails.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): OperationsTourModelType {
        return {
            clientId: this.clientId,
            id: this.id,
            tourName: this.tourName,
            teamId: this.teamId,
            tourDate: this.tourDate,
            status: this.status,
            transporterTourStatus: this.transporterTourStatus,
            currentTour: this.currentTour.toJS(),
            executionTour: this.executionTour
                ? this.executionTour.toJS()
                : this.executionTour,
            dispatchedTour: this.dispatchedTour
                ? this.dispatchedTour.toJS()
                : this.dispatchedTour,
            assignmentDetails: this.assignmentDetails.toJS(),
            orderDetails: this.orderDetails
                .toArray()
                .map((item) => item.toJS()),
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get tourName(): ?string {
        return this._state.get('tourName');
    }

    setTourName(tourName: ?string): this {
        return this.clone(this._state.set('tourName', tourName));
    }

    get teamId(): string {
        return this._state.get('teamId');
    }

    setTeamId(teamId: string): this {
        return this.clone(this._state.set('teamId', teamId));
    }

    get tourDate(): string {
        return this._state.get('tourDate');
    }

    setTourDate(tourDate: string): this {
        return this.clone(this._state.set('tourDate', tourDate));
    }

    get status(): TourStatusTypes {
        return this._state.get('status');
    }

    setStatus(status: TourStatusTypes): this {
        return this.clone(this._state.set('status', status));
    }

    get transporterTourStatus(): TourStatusTypes {
        return this._state.get('transporterTourStatus');
    }

    setTransporterTourStatus(transporterTourStatus: TourStatusTypes): this {
        return this.clone(
            this._state.set('transporterTourStatus', transporterTourStatus)
        );
    }

    get currentTour(): VehicleTour {
        return this._state.get('currentTour');
    }

    setCurrentTour(currentTour: VehicleTour): this {
        return this.clone(this._state.set('currentTour', currentTour));
    }

    get executionTour(): ?VehicleTour {
        return this._state.get('executionTour');
    }

    setExecutionTour(executionTour: ?VehicleTour): this {
        return this.clone(this._state.set('executionTour', executionTour));
    }

    get dispatchedTour(): ?VehicleTour {
        return this._state.get('dispatchedTour');
    }

    setDispatchedTour(dispatchedTour: ?VehicleTour): this {
        return this.clone(this._state.set('dispatchedTour', dispatchedTour));
    }

    get assignmentDetails(): AssignmentDetails {
        return this._state.get('assignmentDetails');
    }

    setAssignmentDetails(assignmentDetails: AssignmentDetails): this {
        return this.clone(
            this._state.set('assignmentDetails', assignmentDetails)
        );
    }

    get orderDetails(): Immutable.List<OrderDetails> {
        return this._state.get('orderDetails');
    }

    setOrderDetails(orderDetails: Immutable.List<OrderDetails>): this {
        return this.clone(this._state.set('orderDetails', orderDetails));
    }
}
