//@flow
import * as Immutable from 'immutable';

import { MinimalVisitLocation } from 'models/flow/MinimalVisitLocation';
import type { MinimalVisitLocationModelType } from 'models/flow/MinimalVisitLocation';
import { LatLng } from './LatLng';
import { StructuredAddress } from './StructuredAddress';

class InternalMinimalVisitLocation extends MinimalVisitLocation {
    static fromJS(
        json: MinimalVisitLocationModelType
    ): InternalMinimalVisitLocation {
        const state: Object = Object.assign({}, json);
        state.latLng = LatLng.fromJS(state.latLng);
        state.locationAddress = StructuredAddress.fromJS(state.locationAddress);
        return new this(Immutable.Map(state));
    }

    get latLng(): LatLng {
        return this._state.get('latLng');
    }

    get locationAddress(): StructuredAddress {
        return this._state.get('locationAddress');
    }
}

export { InternalMinimalVisitLocation as MinimalVisitLocation };
