// @flow
import { getAPIDate } from 'utils/timeUtils';

import { create } from 'zustand';

export type Store = {
    selectedDate: string,
    setSelectedDate: (date: string) => void,
};

export const useDashStore = create((set) => ({
    selectedDate: getAPIDate(new Date()),
    setSelectedDate: (selectedDate: string) => set({ selectedDate }),
}));
