// @flow
import { type FilterModelType } from 'models/flow/Filter';
import {
    FilterBuilder,
    Filter,
    FilterOperation,
    LogicalOperation,
} from 'models/internal/Filter';
import { BulkGetRequest } from 'models/internal/BulkGetRequest';

export const createFilter = ({
    name,
    operation,
    value,
    values,
    expressionLogicalOperation,
    allowEmptyOrNull = false,
    logicalOperation,
}: FilterModelType): Filter => {
    let filter = new FilterBuilder()
        .withName(name)
        .withOperation(operation)
        .withValues(values)
        .withValue(value)
        .withValues(values)
        .withAllowEmptyOrNull(allowEmptyOrNull);

    if (logicalOperation) {
        filter = filter.withLogicalOperation(logicalOperation);
    }

    if (expressionLogicalOperation) {
        filter = filter.withExpressionLogicalOperation(
            expressionLogicalOperation
        );
    }

    return filter.build();
};

export const getActiveStatusFilter = (): Filter => {
    return createFilter({
        name: 'status',
        operation: FilterOperation.Equals,
        value: 'ACTIVE',
        values: [],
        allowEmptyOrNull: false,
    });
};

export const getIsActiveFilter = (): Filter => {
    return createFilter({
        name: 'isActive',
        operation: FilterOperation.Equals,
        value: 'true',
        values: [],
        allowEmptyOrNull: false,
    });
};

type PayloadTypes = {
    queryString: string,
    vehicleModelId?: string,
    includeActiveFilter?: boolean,
};

export const getBulkGetRequest = ({
    queryString,
    vehicleModelId,
    includeActiveFilter = false,
}: PayloadTypes): BulkGetRequest => {
    const statusFilter = getActiveStatusFilter();
    const filters = [statusFilter.toJS()];

    if (includeActiveFilter) {
        const isActiveFilter = getIsActiveFilter();
        filters.push(isActiveFilter.toJS());
    }

    if (queryString) {
        filters.push(
            createFilter({
                name: 'name',
                operation: FilterOperation.ContainsToken,
                values: [queryString],
                value: null,
                allowEmptyOrNull: false,
                expressionLogicalOperation: LogicalOperation.OR,
            }).toJS()
        );
        filters.push(
            createFilter({
                name: 'id',
                operation: FilterOperation.ContainsToken,
                values: [queryString],
                value: null,
                allowEmptyOrNull: false,
                expressionLogicalOperation: LogicalOperation.OR,
            }).toJS()
        );
    }

    if (vehicleModelId) {
        filters.push(
            createFilter({
                name: 'vehicleModelId.modelId',
                operation: FilterOperation.Equals,
                values: [vehicleModelId],
                value: null,
                allowEmptyOrNull: false,
            }).toJS()
        );
    }

    return BulkGetRequest.fromJS({
        page: 1,
        size: 100,
        filters,
        sortingInfo: [],
    });
};
