//@flow
import * as Immutable from 'immutable';

import { OrderDetails } from 'models/flow/OrderDetails';
import type { OrderDetailsModelType } from 'models/flow/OrderDetails';
import { ChecklistItem } from './ChecklistItem';

import { Checklist } from './Checklist';

class InternalOrderDetails extends OrderDetails {
    static fromJS(json: OrderDetailsModelType): InternalOrderDetails {
        const state: Object = Object.assign({}, json);
        state.checklist = Checklist.fromJS(state.checklist);
        return new this(Immutable.Map(state));
    }

    get checklist(): Checklist {
        return this._state.get('checklist');
    }
}

export { InternalOrderDetails as OrderDetails };
