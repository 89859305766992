// @flow
import { useCallback } from 'react';
import { useToast } from '@locus-taxy/neo-ui';

const useError = () => {
    const { showToast } = useToast();

    const onError = useCallback(
        (error: Error) => {
            showToast({
                title: error.message,
                palette: 'danger',
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            });
        },
        [showToast]
    );

    return { onError };
};

export default useError;
