//@flow
import * as Immutable from 'immutable';

import { VisitResource } from 'models/flow/VisitResource';
import type { VisitResourceModelType } from 'models/flow/VisitResource';

class InternalVisitResource extends VisitResource {
    static fromJS(json: VisitResourceModelType): InternalVisitResource {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalVisitResource as VisitResource };
