// @flow
import React, { type Node } from 'react';
import { Text, Flex } from '@locus-taxy/neo-ui';

type Props = {
    optional?: boolean,
    children: Node,
};
const Header = ({ children, optional = true }: Props): Node => {
    return (
        <Flex alignItems="center">
            <Text
                fontSize={1}
                color="grey.700"
                fontWeight="600"
                textTransform="uppercase"
                letterSpacing={1.5}
            >
                {children}
            </Text>
            {!optional && (
                <Text fontSize={3} color="red.500">
                    *
                </Text>
            )}
        </Flex>
    );
};

export default Header;
