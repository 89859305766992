// @flow
import React, { type Node } from 'react';
import {
    Dialog,
    DialogHeader,
    DialogContent,
    DialogFooter,
    Heading,
    Button,
} from '@locus-taxy/neo-ui';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import { type List } from 'immutable';
import ChecklistItems from './ChecklistItems';
import usePodUpload from './usePodUpload';
import type { ChecklistValues } from 'models/flow/OrderDetails';

type Props = {
    onClose: () => void,
    isOpen: boolean,
    orderId: string,
    checkList: List<ChecklistItem>,
    onChecklistUpdate: () => void,
    onOrderComplete: () => void,
    checklistValues: ChecklistValues,
    disableActions: boolean,
    isOrderCompleted: boolean,
};

const UploadProofModal = ({
    onClose,
    isOpen,
    orderId,
    checkList,
    onChecklistUpdate,
    onOrderComplete,
    checklistValues: checklistValuesFromResponse,
    disableActions,
    isOrderCompleted,
}: Props): Node => {
    const {
        filesMap,
        setFilesMap,
        checklistValues,
        setChecklistValues,
        isSaving,
        handleSaveDetails,
        resetValues,
        canCloseOrder,
        completeOrder,
        isCompletingOrder,
    } = usePodUpload({
        orderId,
        onChecklistUpdate,
        checklistValuesFromResponse,
        checklistItems: checkList,
        onOrderComplete,
        isOpen,
    });

    const isChecklistEmpty = checkList.isEmpty();

    return isOpen ? (
        <Dialog isOpen onDismiss={onClose} dismissOnBackdrop={false} sticky>
            <DialogHeader>
                <Heading>Proof of Delivery Upload for - {orderId}</Heading>
            </DialogHeader>
            <DialogContent background="grey">
                <ChecklistItems
                    checklistItems={checkList}
                    filesMap={filesMap}
                    setFilesMap={setFilesMap}
                    checklistValues={checklistValues}
                    setChecklistValues={setChecklistValues}
                />
            </DialogContent>
            <DialogFooter>
                <Button
                    appearance="ghost"
                    palette="primary"
                    onClick={resetValues}
                    disabled={isChecklistEmpty || disableActions}
                >
                    reset
                </Button>
                {!isOrderCompleted && (
                    <Button
                        appearance="solid"
                        palette="success"
                        ml={4}
                        onClick={completeOrder}
                        isLoading={isCompletingOrder}
                        disabled={
                            isChecklistEmpty ||
                            canCloseOrder ||
                            isCompletingOrder ||
                            disableActions
                        }
                    >
                        mark complete
                    </Button>
                )}
                {isOrderCompleted && (
                    <Button
                        ml={4}
                        appearance="solid"
                        palette="primary"
                        onClick={handleSaveDetails}
                        isLoading={isSaving}
                        disabled={
                            isChecklistEmpty || isSaving || disableActions
                        }
                    >
                        save details
                    </Button>
                )}
            </DialogFooter>
        </Dialog>
    ) : null;
};

export default UploadProofModal;
