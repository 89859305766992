//@flow
import * as Immutable from 'immutable';

import { VehicleModelId } from 'models/flow/VehicleModelId';
import type { VehicleModelIdModelType } from 'models/flow/VehicleModelId';

class InternalVehicleModelId extends VehicleModelId {
    static fromJS(json: VehicleModelIdModelType): InternalVehicleModelId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalVehicleModelId as VehicleModelId };
