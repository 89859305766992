//@flow
import * as Immutable from 'immutable';

import { VehicleModel } from 'models/flow/VehicleModel';
import type { VehicleModelModelType } from 'models/flow/VehicleModel';
import { Limits } from './Limits';
import { VolumeLimit } from './VolumeLimit';
import { Skill } from './Skill';

class InternalVehicleModel extends VehicleModel {
    static fromJS(json: VehicleModelModelType): InternalVehicleModel {
        const state: Object = Object.assign({}, json);
        state.limits = Limits.fromJS(state.limits);
        state.volume = VolumeLimit.fromJS(state.volume);
        return new this(Immutable.Map(state));
    }

    get limits(): Limits {
        return this._state.get('limits');
    }

    get volume(): VolumeLimit {
        return this._state.get('volume');
    }
}

export { InternalVehicleModel as VehicleModel };
