// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type LatLngModelType = {
    lat: number,
    lng: number,
    accuracy: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class LatLng extends ImmutableModel {
    static fromJS(json: LatLngModelType): LatLng {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): LatLngModelType {
        return {
            lat: this.lat,
            lng: this.lng,
            accuracy: this.accuracy,
        };
    }

    get lat(): number {
        return this._state.get('lat');
    }

    setLat(lat: number): this {
        return this.clone(this._state.set('lat', lat));
    }

    get lng(): number {
        return this._state.get('lng');
    }

    setLng(lng: number): this {
        return this.clone(this._state.set('lng', lng));
    }

    get accuracy(): number {
        return this._state.get('accuracy');
    }

    setAccuracy(accuracy: number): this {
        return this.clone(this._state.set('accuracy', accuracy));
    }
}
