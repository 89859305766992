// @flow
import React, { type Node } from 'react';
import { Stack, Box } from '@locus-taxy/neo-ui';
import CardTop from './CardTop';
import CardBody from './CardBody';
import CardBottom from './CardBottom';
import { TourVisit } from 'models/internal/TourVisit';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import type { ChecklistValues, OrderStatuses } from 'models/flow/OrderDetails';
import { type List } from 'immutable';

type Props = {
    visit: TourVisit,
    checkList: List<ChecklistItem>,
    checklistValues: ChecklistValues,
    refetch: () => () => void,
    orderStatus: OrderStatuses,
    isTourClosed: boolean,
};

const OrderCard = ({
    visit,
    checkList,
    checklistValues,
    refetch,
    orderStatus,
    isTourClosed,
}: Props): Node => {
    const { customerVisitSequence, sourceId, exchangeType } = visit;

    return (
        <Stack space="xSmall">
            <CardTop
                sequence={customerVisitSequence || 0}
                sourceId={sourceId}
                exchangeType={exchangeType}
                orderStatus={orderStatus}
            />
            <Box
                ml={3}
                borderLeftStyle="solid"
                borderLeftWidth="2px"
                borderLeftColor="grey.200"
                px={4}
                pr={0}
                paddingBottom={4}
            >
                <Stack space="small">
                    <CardBody visit={visit} />
                    <CardBottom
                        orderStatus={orderStatus}
                        checklistValues={checklistValues}
                        orderId={sourceId}
                        checkList={checkList}
                        refetch={refetch}
                        isTourClosed={isTourClosed}
                    />
                </Stack>
            </Box>
        </Stack>
    );
};

export default OrderCard;
