// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel';

import { LatLng } from './LatLng';
import { StructuredAddress } from './StructuredAddress';

import type { LatLngModelType } from './LatLng';
import type { StructuredAddressModelType } from './StructuredAddress'; // Make sure you copied this file into your repo

export type MinimalVisitLocationModelType = {
    id: string,
    latLng: LatLngModelType,
    locationAddress: StructuredAddressModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class MinimalVisitLocation extends ImmutableModel {
    static fromJS(json: MinimalVisitLocationModelType): MinimalVisitLocation {
        const state: Object = Object.assign({}, json);
        state.latLng = LatLng.fromJS(state.latLng);
        state.locationAddress = StructuredAddress.fromJS(state.locationAddress);
        return new this(Immutable.Map(state));
    }

    toJS(): MinimalVisitLocationModelType {
        return {
            id: this.id,
            latLng: this.latLng.toJS(),
            locationAddress: this.locationAddress.toJS(),
        };
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get latLng(): LatLng {
        return this._state.get('latLng');
    }

    setLatLng(latLng: LatLng): this {
        return this.clone(this._state.set('latLng', latLng));
    }

    get locationAddress(): StructuredAddress {
        return this._state.get('locationAddress');
    }

    setLocationAddress(locationAddress: StructuredAddress): this {
        return this.clone(this._state.set('locationAddress', locationAddress));
    }
}
