// @flow
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@locus-taxy/neo-ui';
import { useAuth } from '@locus-taxy/auth-react';
import config from 'config';
import { useClientId } from 'modules/clientId';

const LoginButton = (): React.Node => {
    const auth = useAuth();
    const clientId = useClientId();
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchParams] = useSearchParams();

    const handleLogin = () => {
        setIsLoading(true);
        auth.login({
            appState: { returnTo: searchParams.get('returnTo') || '/' },
            clientId,
            devo: config.env === 'devo' ? config.devo : undefined,
            redirectMethod: 'assign',
        });
    };

    return (
        <Button
            disabled={false}
            appearance="solid"
            palette="primary"
            size="large"
            onClick={handleLogin}
            isLoading={isLoading}
            width="100%"
        >
            Login
        </Button>
    );
};

export default LoginButton;
