//@flow
import * as Immutable from 'immutable';

import { RiderPersona } from 'models/flow/MasterRiderPersona';
import type { RiderPersonaModelType } from 'models/flow/MasterRiderPersona';
import { TransporterId } from './TransporterId';
import { SkillId } from './SkillId';
import { RiderPersonaLimits } from './RiderPersonaLimits';

class InternalRiderPersona extends RiderPersona {
    static fromJS(json: RiderPersonaModelType): InternalRiderPersona {
        const state: Object = Object.assign({}, json);
        state.transporterId = state.transporterId
            ? TransporterId.fromJS(state.transporterId)
            : state.transporterId;
        state.skills = Immutable.List(state.skills).map((item) =>
            SkillId.fromJS(item)
        );
        state.limits = state.limits
            ? RiderPersonaLimits.fromJS(state.limits)
            : state.limits;
        state.customProperties = Immutable.Map(state.customProperties);
        return new this(Immutable.Map(state));
    }

    get transporterId(): ?TransporterId {
        return this._state.get('transporterId');
    }

    get skills(): Immutable.List<SkillId> {
        return this._state.get('skills');
    }

    get limits(): ?RiderPersonaLimits {
        return this._state.get('limits');
    }
}

export { InternalRiderPersona as RiderPersona };
