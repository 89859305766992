// @flow
import { useQuery, type QueryResult } from '@tanstack/react-query';
import { dashInstance } from 'middleware/api';
import { BulkGetRequest } from 'models/internal/BulkGetRequest';
import { Vehicle } from 'models/internal/Vehicle';

type Props = {
    clientId: string,
    requestBody: BulkGetRequest,
};

const useGetVehicles = ({
    clientId,
    requestBody,
}: Props): QueryResult<Array<Vehicle>, Error> => {
    return useQuery({
        queryKey: ['vehicle-search', clientId, requestBody],
        queryFn: async () => {
            const { data } = await dashInstance.post(
                `/client/${clientId}/vehicle-search?include=VEHICLE_MODEL`,
                {
                    ...requestBody.toJS(),
                }
            );
            return data.vehicles.map((vehicle) => Vehicle.fromJS(vehicle));
        },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        staleTime: 30 * 1000,
    });
};

export default useGetVehicles;
