// @flow
import {
    PasswordPolicyEnum,
    type PasswordPolicyEnumType,
} from 'enums/AuthEnum';

export { PasswordPolicyEnum };
export type { PasswordPolicyEnumType };

export const PasswordStrengthCheckEnum = Object.freeze({
    containsAtLeast: 'containsAtLeast',
    identicalChars: 'identicalChars',
    nonEmpty: 'nonEmpty',
    numbers: 'numbers',
    alphabets: 'alphabets',
    lengthAtLeast: 'lengthAtLeast',
    lowerCase: 'lowerCase',
    shouldContain: 'shouldContain',
    specialCharacters: 'specialCharacters',
    upperCase: 'upperCase',
});
export type PasswordStrengthCheckEnumType = $Values<
    typeof PasswordStrengthCheckEnum
>;

export type PasswordStrengthConfig = {
    policy: PasswordPolicyEnumType,
    minimumLength: number,
};

export type PasswordStrengthResult = {
    verified: boolean,
    rules: Array<{
        verified: boolean,
        code: PasswordStrengthCheckEnumType,
        format?: Array<string>,
        items?: Array<{
            code: PasswordStrengthCheckEnumType,
            format?: Array<string>,
            verified: boolean,
        }>,
    }>,
};
