// @flow
import { useQuery, type QueryResult } from '@tanstack/react-query';
import { dashInstance } from 'middleware/api';
import { BulkGetRequest } from 'models/internal/BulkGetRequest';
import { Rider } from 'models/internal/Rider';
type Props = {
    clientId: string,
    requestBody: BulkGetRequest,
};

const useGetRiders = ({
    clientId,
    requestBody,
}: Props): QueryResult<Array<Rider>, Error> => {
    return useQuery({
        queryKey: ['rider-search', clientId, requestBody],
        queryFn: async () => {
            const { data } = await dashInstance.post(
                `/client/${clientId}/rider-search`,
                {
                    ...requestBody.toJS(),
                }
            );
            return data.riders.map((rider) => Rider.fromJS(rider));
        },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        staleTime: 30 * 1000,
    });
};

export default useGetRiders;
