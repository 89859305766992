// @flow
import * as React from 'react';
import { Input, Button, Card, Stack, Box, Flex } from '@locus-taxy/neo-ui';
import { useStore } from './store';
import Background from 'components/common/Background';

const ClientIdContainer = (): React.Node => {
    const [value, setValue] = React.useState('');
    const saveClientId = useStore((state) => state.setClientId);

    const onDone = () => {
        saveClientId(value);
    };

    return (
        <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
        >
            <Background />
            <form onSubmit={onDone} style={{ height: '100%' }}>
                <Flex alignItems="center" justifyContent="center" height="100%">
                    <Box width="300px">
                        <Card>
                            <Stack space="medium">
                                <Input
                                    value={value}
                                    label="Client Id"
                                    placeholder="Enter Client Id"
                                    onChange={(e) => setValue(e.target.value)}
                                    fullWidth
                                    required
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    appearance="solid"
                                    palette="primary"
                                    disabled={!value}
                                >
                                    Continue
                                </Button>
                            </Stack>
                        </Card>
                    </Box>
                </Flex>
            </form>
        </Flex>
    );
};

export default ClientIdContainer;
