// @flow
import React, { type Node } from 'react';
import { VehicleTour } from 'models/internal/VehicleTour';
import { Columns } from '@locus-taxy/neo-ui';
import { SubText } from './TourDetailsCard';

type Props = {
    tour: VehicleTour,
};

const DriverVehicleDetails = ({ tour }: Props): Node => {
    const { assignedUser, vehicle } = tour || {};
    if (!assignedUser && !vehicle) {
        return null;
    }

    const { userId } = assignedUser || {};
    const { id: vehicleId } = vehicle || {};

    return (
        <Columns alignY="center" space="small">
            {userId && (
                <Columns space="xxSmall" alignY="center">
                    <SubText>Driver:</SubText>
                    <SubText bold>{userId}</SubText>
                </Columns>
            )}
            {vehicleId?.id && vehicleId?.version && (
                <Columns space="xxSmall" alignY="center">
                    <SubText>Vehicle:</SubText>
                    <SubText bold>{vehicleId.id}</SubText>
                </Columns>
            )}
        </Columns>
    );
};

export default DriverVehicleDetails;
