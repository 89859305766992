// @flow
import React, { type Node } from 'react';
import { Flex, Heading } from '@locus-taxy/neo-ui';

import AppHeader from 'components/common/AppHeader';
import DateMenu from 'components/common/DateMenu';
import Drawer from '@mui/material/Drawer';
import DrawerContent from './MobileDrawerContent';
import Hamburger from 'components/common/Hamburger';
import ResponsiveBox from 'components/common/ResponsiveBox';

type PropTypes = {
    openDrawer: () => void,
    closeDrawer: () => void,
    isDrawerOpen: boolean,
};

const MobileHeader = ({
    openDrawer,
    closeDrawer,
    isDrawerOpen,
}: PropTypes): Node => {
    return (
        <AppHeader>
            <Flex justifyContent="space-between" flexGrow={1}>
                <Flex alignItems="center">
                    <Hamburger onClick={openDrawer} />
                    <ResponsiveBox noMobile>
                        <Heading level="3" ml={4}>
                            Tours
                        </Heading>
                    </ResponsiveBox>
                    <ResponsiveBox noDesktop>
                        <Heading level="3" ml={4} color="white">
                            Tours
                        </Heading>
                    </ResponsiveBox>
                </Flex>
                <DateMenu />
            </Flex>
            <Drawer onClose={closeDrawer} open={isDrawerOpen}>
                <DrawerContent />
            </Drawer>
        </AppHeader>
    );
};

export default MobileHeader;
