//@flow
import * as Immutable from 'immutable';

import { PaginationInfo } from 'models/flow/PaginationInfo';
import type { PaginationInfoModelType } from 'models/flow/PaginationInfo';

class InternalPaginationInfo extends PaginationInfo {
    static fromJS(json: PaginationInfoModelType): InternalPaginationInfo {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalPaginationInfo as PaginationInfo };
