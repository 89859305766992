// @flow
import React, { type Node, useState } from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    Box,
    Button,
    Dialog,
} from '@locus-taxy/neo-ui';
import { getShortDate, getAPIDate, getDayJsObject } from 'utils/timeUtils';
import { DateCalendar } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useSelectedDate } from 'hooks';
import ResponsiveBox from 'components/common/ResponsiveBox';
import './styles.css';

const DateMenu = (): Node => {
    const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);
    const closeMobileDialog = () => setIsMobileDialogOpen(false);
    const openMobileDialog = () => setIsMobileDialogOpen(true);

    const { selectedDate, setSelectedDate } = useSelectedDate();
    const handleDateChange = (date: Date) => {
        setSelectedDate(getAPIDate(date));
    };

    const renderMenuList = () => {
        return (
            <MenuList maxHeight="350px">
                <Box backgroundColor="white">
                    <DateCalendar
                        onChange={handleDateChange}
                        value={getDayJsObject(selectedDate)}
                    />
                </Box>
            </MenuList>
        );
    };

    const renderDesktopComponent = () => {
        return (
            // key - hack to re-render and close the menu on date change
            // as Menu component is not a controlled component
            <Menu palette="light" key={selectedDate}>
                <MenuButton
                    appearance="outline"
                    palette="primary"
                    rightIcon="arrow_drop_down"
                >
                    {getShortDate(selectedDate)}
                </MenuButton>

                {renderMenuList()}
            </Menu>
        );
    };

    const renderMobileComponent = () => {
        return (
            <>
                <Button
                    appearance="solid"
                    palette="primary"
                    rightIcon="arrow_drop_down"
                    color="white"
                    onClick={openMobileDialog}
                >
                    {getShortDate(selectedDate)}
                </Button>
                <Dialog
                    isOpen={isMobileDialogOpen}
                    onDismiss={closeMobileDialog}
                >
                    <StaticDatePicker
                        value={getDayJsObject(selectedDate)}
                        onAccept={(date: Date) => {
                            handleDateChange(date);
                            closeMobileDialog();
                        }}
                        onClose={closeMobileDialog}
                    />
                </Dialog>
            </>
        );
    };

    return (
        <>
            <ResponsiveBox noMobile>{renderDesktopComponent()}</ResponsiveBox>
            <ResponsiveBox noDesktop>{renderMobileComponent()}</ResponsiveBox>
        </>
    );
};

export default DateMenu;
