// @flow
import * as React from 'react';

import { Card, Flex, Box } from '@locus-taxy/neo-ui';

import LoginButton from './LoginButton';
import Background from 'components/common/Background';

const AppLoginContainer = (): React.Node => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%">
            <Background />
            <Box width="300px">
                <Card>
                    <LoginButton />
                </Card>
            </Box>
        </Flex>
    );
};

export default AppLoginContainer;
