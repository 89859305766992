// @flow

export const TourStatuses = Object.freeze({
    READY_TO_DISPATCH: 'READY_TO_DISPATCH',
    IN_TRANSIT: 'IN_TRANSIT',
    PENDING_CLOSURE: 'PENDING_CLOSURE',
    CLOSED: 'CLOSED',
});

export const VALID_TOUR_STATUSES = [
    TourStatuses.READY_TO_DISPATCH,
    TourStatuses.IN_TRANSIT,
    TourStatuses.PENDING_CLOSURE,
    TourStatuses.CLOSED,
];

export const TourStatusDetails = [
    {
        label: 'All Tours',
        statusKey: 'all',
    },
    {
        label: 'Ready To Dispatch',
        statusKey: TourStatuses.READY_TO_DISPATCH,
    },
    {
        label: 'In-transit',
        statusKey: TourStatuses.IN_TRANSIT,
    },
    {
        label: 'Completed',
        statusKey: TourStatuses.PENDING_CLOSURE,
    },
    {
        label: 'Closed',
        statusKey: TourStatuses.CLOSED,
    },
];
