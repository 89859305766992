// @flow
import dayjs from 'dayjs';

export const getLongDate = (timestamp: number): string => {
    return dayjs(timestamp).format("DD MMM [']YY hh:mm:ss A");
};

export const getShortDateTime = (utcString: string): string => {
    return dayjs(utcString).format('ddd, h:mm A');
};

export const getShortDate = (date: string): string => {
    return dayjs(date).format('DD MMM YY');
};

export const getAPIDate = (date: Date): string => {
    return dayjs(date).format('YYYY-MM-DD');
};

export const getAPITime = (date: Date): string => {
    return dayjs(date).format('HH:mm');
};

export const getAPIDateTime = (date: Date): string => {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
};

export const getDayJsObject = (date: string): Date => {
    return dayjs(date);
};

export const isValidAPIDate = (date: string): boolean => {
    return dayjs(date, 'YYYY-MM-DD').isValid();
};

export const convertSeconds = (secs: number, hideSeconds?: boolean): string => {
    let hours;
    let day;
    let minutes = Math.trunc(secs / 60);
    const seconds = secs % 60;

    if (minutes >= 60) {
        hours = Math.trunc(minutes / 60);
        minutes = minutes % 60;
    }

    if (hours && hours >= 24) {
        day = Math.trunc(hours / 24);
    }

    return `${day ? `${day} day` : ''}${hours ? ` ${hours} hrs` : ''}${
        minutes ? ` ${minutes} mins` : ''
    }${seconds && !hideSeconds ? ` ${seconds} sec` : ''}`.trim();
};

export const convertMeters = (meters: number): string => {
    return `${(meters / 1000).toFixed(1)} km`;
};
