//@flow
import * as Immutable from 'immutable';
import { isEmpty } from 'lodash';

import { Filter, ComplexFilter } from 'models/flow/Filter';
import type {
    FilterModelType,
    FilterOperationType,
    LogicalOperationType,
    ComplexFilterModelType,
} from 'models/flow/Filter';

class InternalComplexFilter extends ComplexFilter {
    static fromJS(json: ComplexFilterModelType): InternalComplexFilter {
        const state: Object = Object.assign({}, json);
        state.filters = Immutable.List(state.filters).map((item) =>
            InternalFilter.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get filters(): Immutable.List<Filter> {
        return this._state.get('filters');
    }

    get expressionLogicalOperator(): ?LogicalOperationType {
        return this._state.get('expressionLogicalOperator');
    }
}

export { InternalComplexFilter as ComplexFilter };

export const FilterOperation = Object.freeze({
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    StartsWith: 'STARTS_WITH',
    Contains: 'CONTAINS',
    ContainsToken: 'CONTAINS_TOKEN',
    NotContainsToken: 'NOT_CONTAINS_TOKEN',
    GreaterThan: 'GREATER_THAN',
    LesserThan: 'LESSER_THAN',
    GreaterThanOrEqualTo: 'GREATER_THAN_OR_EQUAL_TO',
    LesserThanOrEqualTo: 'LESSER_THAN_OR_EQUAL_TO',
    IsNull: 'IS_NULL',
    IsNotNull: 'IS_NOT_NULL',
});

export type { FilterOperationType, LogicalOperationType };

export const LogicalOperation = Object.freeze({
    AND: 'AND',
    OR: 'OR',
});

class InternalFilter extends Filter {
    static fromJS(json: FilterModelType): InternalFilter {
        const state: Object = Object.assign({}, json);
        state.values = Immutable.List(state.values);
        return new this(Immutable.Map(state));
    }

    static with(
        name: string,
        operation: $Values<typeof FilterOperation>,
        value: string | Object | Array<string | Object>,
        logicalOperation?: $Values<typeof LogicalOperation>,
        allowEmptyOrNull?: boolean,
        expressionLogicalOperation?: $Values<typeof LogicalOperation>,
        caseSensitive?: boolean
    ): InternalFilter {
        let valuesObject = { value };
        if (Array.isArray(value)) {
            valuesObject = { value: null, values: value };
        }
        return InternalFilter.fromJS({
            name,
            operation,
            ...valuesObject,
            logicalOperation,
            allowEmptyOrNull,
            expressionLogicalOperation,
            caseSensitive,
        });
    }

    get operation(): FilterOperationType {
        return this._state.get('operation');
    }

    get logicalOperation(): ?LogicalOperationType {
        return this._state.get('logicalOperation');
    }

    get expressionLogicalOperation(): ?LogicalOperationType {
        return this._state.get('expressionLogicalOperation');
    }
}

export class FilterBuilder {
    constructor() {
        this.name = null;
        this.operation = null;
        this.value = null;
        this.values = null;
        this.logicalOperation = null;
        this.expressionLogicalOperation = null;
        this.allowEmptyOrNull = false;
        this.expressionLogicalOperation = null;
        this.caseSensitive = false;
    }

    static getUniqueKey(
        name: string,
        operation: $Values<typeof FilterOperation>
    ): string {
        return `${name}__${operation}`;
    }

    withName(name: string): this {
        this.name = name;
        return this;
    }

    withOperation(operation: $Values<typeof FilterOperation>): this {
        this.operation = operation;
        return this;
    }

    withValue(value: string | Object): this {
        this.value = value;
        return this;
    }

    withValues(values: Array<string | Object>): this {
        this.values = values;
        return this;
    }
    getUniqueKey(): string {
        if (!this.name || !this.operation) {
            throw new Error('Invalid filter');
        }
        return FilterBuilder.getUniqueKey(this.name, this.operation);
    }
    /**
     * Creates Logical operation between values in single filter
     */
    withLogicalOperation(operation: $Values<typeof LogicalOperation>): this {
        this.logicalOperation = operation;
        return this;
    }
    /**
     * Creates Logical operation between multiple filters
     */
    withExpressionLogicalOperation(
        operation: $Values<typeof LogicalOperation>
    ): this {
        this.expressionLogicalOperation = operation;
        return this;
    }

    withAllowEmptyOrNull(value: ?boolean): this {
        this.allowEmptyOrNull = value;
        return this;
    }

    withCaseSensitive(value: ?boolean): this {
        this.caseSensitive = value;
        return this;
    }

    build(): InternalFilter {
        if (
            (isEmpty(this.value) &&
                isEmpty(this.values) &&
                !this.allowEmptyOrNull) ||
            isEmpty(this.operation) ||
            isEmpty(this.name)
        ) {
            throw new Error('name, value and operation are mandatory');
        }
        return InternalFilter.with(
            this.name,
            this.operation,
            this.value || this.values,
            this.logicalOperation,
            this.allowEmptyOrNull,
            this.expressionLogicalOperation,
            this.caseSensitive
        );
    }
}

export { InternalFilter as Filter };
