//@flow
import * as Immutable from 'immutable';

import { SortingInfo } from 'models/flow/SortingInfo';
import type { SortingInfoModelType } from 'models/flow/SortingInfo';

class InternalSortingInfo extends SortingInfo {
    static fromJS(json: SortingInfoModelType): InternalSortingInfo {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalSortingInfo as SortingInfo };
