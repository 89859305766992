//@flow
import * as Immutable from 'immutable';

import { LatLng } from 'models/flow/LatLng';
import type { LatLngModelType } from 'models/flow/LatLng';

class InternalLatLng extends LatLng {
    static fromJS(json: LatLngModelType): InternalLatLng {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalLatLng as LatLng };
