//@flow
import * as Immutable from 'immutable';

import { RiderPersonaId } from 'models/flow/RiderPersonaId';
import type { RiderPersonaIdModelType } from 'models/flow/RiderPersonaId';

class InternalRiderPersonaId extends RiderPersonaId {
    static fromJS(json: RiderPersonaIdModelType): InternalRiderPersonaId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalRiderPersonaId as RiderPersonaId };
