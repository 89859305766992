// @flow
import React, { type Node, type AbstractComponent } from 'react';
import { Flex } from '@locus-taxy/neo-ui';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

type Props = {
    itemData: Object,
    itemCount: number,
    itemSize: number,
    children: AbstractComponent<any>,
};

const VirtualList = ({
    itemData,
    itemCount,
    itemSize,
    children,
}: Props): Node => {
    return (
        <Flex height="100%" width="100%">
            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <List
                            itemData={itemData}
                            height={height}
                            width={width}
                            itemCount={itemCount}
                            itemSize={itemSize}
                        >
                            {children}
                        </List>
                    );
                }}
            </AutoSizer>
        </Flex>
    );
};

export default VirtualList;
