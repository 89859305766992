// @flow
export const ChecklistItemTypes = Object.freeze({
    SIGNATURE: 'SIGNATURE',
    PHOTO: 'PHOTO',
    BOOLEAN: 'BOOLEAN',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    TEXT_FIELD: 'TEXT_FIELD',
    RATING: 'RATING',
    PIN: 'PIN',
    URL: 'URL',
    DATE: 'DATE',
    TIME: 'TIME',
    DATETIME: 'DATETIME',
    PHOTO_GALLERY: 'PHOTO_GALLERY',
});
