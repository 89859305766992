// @flow
import React, { type Node } from 'react';
import { Flex } from '@locus-taxy/neo-ui';
import LogoutButton from './LogoutButton';
import ResponsiveBox from 'components/common/ResponsiveBox';

type Props = {
    children?: Node,
};

const AppHeader = ({ children }: Props): Node => {
    return (
        <ResponsiveBox
            isFlex
            padding={4}
            backgroundColor="white"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="grey.200"
            justifyContent="space-between"
            alignItems="center"
            mobileSx={{
                backgroundColor: 'blue.500',
            }}
        >
            <Flex flexGrow={1} mr={4} width="100%" overflow="hidden">
                {children}
            </Flex>
            <LogoutButton />
        </ResponsiveBox>
    );
};

export default AppHeader;
