// @flow
import * as React from 'react';
import { Loader } from '@locus-taxy/neo-ui';

import { useAuth } from '@locus-taxy/auth-react';
import AuthError from './AuthError';

type Props = {
    children: React.Node,
};
const AuthStateHandler = ({ children }: Props): React.Node => {
    const auth = useAuth();

    if (auth.isLoading) {
        return <Loader width="100%" height="100%" color="blue.500" />;
    }

    if (auth.error) {
        return <AuthError error={auth.error} />;
    }

    return children;
};

export default AuthStateHandler;
