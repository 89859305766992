// @flow
import { useState, useMemo, useEffect } from 'react';
import type { CheckListMap, FileMap } from './ChecklistItems/helper';
import useMultiFileUpload, { type UrlMap } from 'hooks/api/useMultiFileUpload';
import useClientId from 'modules/clientId/useClientId';
import useUpdateChecklist, {
    type RequestBody,
} from 'hooks/api/useUpdateChecklist';
import { merge as _merge } from 'lodash';
import { useError } from 'hooks';
import type { ChecklistValues } from 'models/flow/OrderDetails';
import { ChecklistItem } from 'models/internal/ChecklistItem';
import { type List } from 'immutable';
import useOrderCompletion from 'hooks/api/useOrderCompletion';

export const getUpdatedChecklistValues = (
    prevChecklistMap: CheckListMap,
    newChecklistMap: UrlMap
): CheckListMap => {
    return _merge(prevChecklistMap, newChecklistMap);
};

type ReturnTypes = {
    filesMap: FileMap,
    setFilesMap: (fileMap: FileMap) => void,
    checklistValues: CheckListMap,
    setChecklistValues: (checklistValues: CheckListMap) => void,
    isSaving: boolean,
    isCompletingOrder: boolean,
    handleSaveDetails: () => void,
    resetValues?: () => void,
    canCloseOrder: boolean,
    completeOrder: () => void,
};

const getRequestBody = (checklistValues: CheckListMap): RequestBody => {
    const checklists = Object.keys(checklistValues).map((key: string) => ({
        key,
        value: checklistValues[key],
    }));

    return {
        requests: checklists,
    };
};

const usePodUpload = ({
    orderId,
    onChecklistUpdate,
    checklistValuesFromResponse,
    checklistItems,
    onOrderComplete,
    isOpen,
}: {
    orderId: string,
    onChecklistUpdate: () => void,
    checklistValuesFromResponse: ChecklistValues,
    checklistItems: List<ChecklistItem>,
    onOrderComplete: () => void,
    isOpen: boolean,
}): ReturnTypes => {
    const [filesMap, setFilesMap] = useState<FileMap>({});
    const [checklistValues, setChecklistValues] = useState<CheckListMap>(
        checklistValuesFromResponse
    );
    const [isSaving, setIsSaving] = useState(false);
    const [isCompletingOrder, setIsCompletingOrder] = useState(false);
    const { onError } = useError();

    const clientId = useClientId();

    const { mutateAsync } = useMultiFileUpload();
    const { mutateAsync: mutateChecklist } = useUpdateChecklist();
    const { mutateAsync: mutateOrderCompletion } = useOrderCompletion();

    useEffect(() => {
        // on opening set checklist values from response
        if (isOpen) {
            setChecklistValues(checklistValuesFromResponse);
        }
    }, [isOpen, checklistValuesFromResponse]);

    const handleSaveDetails = async () => {
        setIsSaving(true);
        const uploadedFilesMap = await mutateAsync(
            {
                clientId,
                orderId,
                filesMap,
            },
            { onError }
        );
        const updatedChecklistValues = getUpdatedChecklistValues(
            checklistValues,
            uploadedFilesMap
        );

        const body = getRequestBody(updatedChecklistValues);
        await mutateChecklist({ clientId, orderId, body }, { onError });
        onChecklistUpdate();
        setIsSaving(false);
    };

    const resetValues = () => {
        setFilesMap({});
        setChecklistValues({});
    };

    const canCloseOrder = useMemo(() => {
        const mandatoryFields =
            checklistItems
                ?.toArray()
                .filter(({ optional }) => !optional)
                ?.map(({ key }) => key) || [];

        return mandatoryFields.some((key) => !checklistValues[key]);
    }, [checklistValues, checklistItems]);

    const completeOrder = async () => {
        await handleSaveDetails();
        setIsCompletingOrder(true);
        await mutateOrderCompletion({ clientId, orderId }, { onError });
        setIsCompletingOrder(false);
        onOrderComplete();
    };

    return {
        filesMap,
        setFilesMap,
        checklistValues,
        setChecklistValues,
        isSaving,
        isCompletingOrder,
        handleSaveDetails,
        resetValues,
        canCloseOrder,
        completeOrder,
    };
};

export default usePodUpload;
